<div class="learn-category-details">
    <div class="learn-category-details--left col-3">
        <div class="learn-category-details--left--top">
            <span class="learn-category-details--left--top--title">FAQ Category Name:</span>
            <span class="learn-category-details--left--top--title">{{title}}</span>
            <span class="learn-category-details--left--top--des">{{description}}</span>
            <span class="learn-category-details--left--top--total-ques">List of Questions ({{totalQuestions}})</span>
        </div>
        <div class="learn-category-details--left--bottom">
            <span class="learn-category-details--left--bottom--questions" *ngFor="let data of learnCatgoriesDetails[0]">
                <span (click)="showQuestionAndAnswer(data.question, data.answer)">{{data.question}}</span>
            </span>
        </div>
    </div>
    <div class="learn-category-details--right col-9">
        <span class="learn-category-details--right--question">{{showQuestion}}</span>
        <span class="learn-category-details--right--answer">{{showAnswer}}</span>
    </div>
</div>