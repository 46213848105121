import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-editors-choice-comp',
  templateUrl: './editors-choice-comp.component.html',
  styleUrls: ['./editors-choice-comp.component.scss'],
})
export class EditorsChoiceCompComponent {
  editorsChoiceList: any = [];
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.getEditorsChoice();
  }

  getEditorsChoice() {
    this.authService.getEditorsChoice(-1, false, true, 1).subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          this.editorsChoiceList[index] = element;
        });
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
