import { Component } from '@angular/core';

@Component({
  selector: 'app-index-methodology',
  templateUrl: './index-methodology.component.html',
  styleUrls: ['./index-methodology.component.scss']
})
export class IndexMethodologyComponent {
  isReadMore: boolean = false;
}
