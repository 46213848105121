import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-hot-stories-comp',
  templateUrl: './hot-stories-comp.component.html',
  styleUrls: ['./hot-stories-comp.component.scss'],
})
export class HotStoriesCompComponent {
  hotStoriesList: any = [];
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.getHotStories();
  }

  getHotStories() {
    this.authService.getHotStories(-1, false, true, 1).subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          this.hotStoriesList[index] = element;
        });
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
