import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-homepage-hot-stories',
  templateUrl: './homepage-hot-stories.component.html',
  styleUrls: ['./homepage-hot-stories.component.scss'],
})
export class HomepageHotStoriesComponent {
  hotStoriesList: any = [];
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.getHotStories();
  }

  getHotStories() {
    this.authService.getHotStories(-1, false, true, 1).subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          this.hotStoriesList[index] = element;
        });
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
