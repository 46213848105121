import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { YoutubeLinksService } from '../../shared/services/youtube-links.service';

interface structure{ 
  youtubeVideoId: number |string, 
  title: string | undefined,
  createdDateTime: Date | string, 
  youtubeLink: string | undefined,
 }
@Component({
  selector: 'app-yotube-video-links',
  templateUrl: './yotube-video-links.component.html',
  styleUrls: ['./yotube-video-links.component.scss']
})
export class YotubeVideoLinksComponent {

  private unsubscribe$ = new Subject<void>();
  youtubeLinkForm: FormGroup
  id:any='';

  filterBy = [
    { label: 'Draft', value: 'Draft' },
    { label: 'Approval Pending', value: 'Approval Pending' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Pending', value: 'Pending' },
    { label: 'All', value: 'All' }
];

  youtubeVideoLinks: structure[] =[];
  loggedInUser:any;
  totalRecords=100000
  constructor(private youtubeLinkService: YoutubeLinksService,
    private fb: FormBuilder,
    private ns: NotificationService,
    private authService: AuthService){}


  ngOnInit(){
    this.loggedInUser=this.authService.getUser();
    this._initYoutubeLinkForm()
    // this._getYoutubeLinkData()
  }

  _initYoutubeLinkForm(){
    this.youtubeLinkForm = this.fb.group({
      title:['', Validators.required],
      youtubeLink:['', Validators.required],
      
    })
  }

  save(){
    if(this.youtubeLinkForm.invalid){
      return;
    }
    this.authService.hasPageLoaded.next(false);
    if(this.id){
      this.youtubeLinkService.updateYoutubeVideoLink(this.id,this.youtubeLinkForm.value)
      .subscribe({
        next:res=>{
          this.ns.showSuccess('Youtube Link Updated Successfully')
          // this._getYoutubeLinkData()
          setTimeout(() => {
            location.reload();
          }, 1000);
          this.id=''
          this.youtubeLinkForm.reset();
          this.authService.hasPageLoaded.next(true);
        },
        error:err =>{
          this.authService.hasPageLoaded.next(true);
          this.ns.showError('Something went wrong!')
          console.log(err)
        }
      });
      return;
    }
    console.log(this.youtubeLinkForm.value)
    this.youtubeLinkService.createYoutubeVideoLink(this.youtubeLinkForm.value)
    .subscribe({
      next:res => {
      if(res.youtubeVideoId){
        this.authService.hasPageLoaded.next(true);
        this.youtubeVideoLinks.push(res)
        this.ns.showSuccess('Post Created Successfully!')
        this.youtubeLinkForm.reset();
      }
      console.log(res)
    },
    error:err => {
      this.authService.hasPageLoaded.next(true);
      this.ns.showError('Something went wrong!')
      console.log(err)
    }
  })
  }

  _getYoutubeLinkData(event:any){
    let params = new HttpParams()
    .set('pageNo',(event.first/10)+1)
    .set('limit',event.rows);
    if(event.filters.global && event.filters.global.value){
      params=params.set('searchTitle',event.filters.global?.value)
    }

    this.youtubeLinkService.getYoutubeVideoLinks(this.loggedInUser.userId,params)
    .subscribe({
      next:res => {
      this.youtubeVideoLinks=res;
      this.authService.hasPageLoaded.next(true);
    },
    error:err => {
      console.log(err);
      this.authService.hasPageLoaded.next(true)
    }
  })
  }

  deleteLink(id:any){
    this.authService.hasPageLoaded.next(false)
    
    this.youtubeLinkService.deleteYoutubeVideoLink(id).pipe(takeUntil(this.unsubscribe$)).subscribe(
      {
        next:res => {
      console.log(res)
      this.authService.hasPageLoaded.next(true)
      this.youtubeVideoLinks=this.youtubeVideoLinks.filter(link => link.youtubeVideoId != id)
      this.ns.showSuccess('Post Deleted Successfully!')
    },
    error:err=> {
      console.log(err)
      this.authService.hasPageLoaded.next(true)
      this.ns.showError('Something went wrong!')
    }
  }
    )
  }



  editRecord(id:any){
    console.log(id)
    const record =this.youtubeVideoLinks.find(link => link.youtubeVideoId == id);
    console.log(record)
    if(record){
      this.id=id;
      this.youtubeLinkForm.patchValue(record)
    }
      
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  

}
