import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-learn-category-details',
  templateUrl: './learn-category-details.component.html',
  styleUrls: ['./learn-category-details.component.scss']
})
export class LearnCategoryDetailsComponent {
  title: string = "";
  description: string = "";
  totalQuestions: number;
  learnCatgoriesDetails: any[] = [];
  showQuestion: string = "";
  showAnswer: string = "";

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.title = history.state.title;
    this.description = history.state.description;
    console.log('title: ', this.title);
    this.getFAQByCategoryId(history.state.id);
  }

  showQuestionAndAnswer(question: string, answer: string) {
    this.showQuestion = question;
    this.showAnswer = answer;
  }

  getFAQByCategoryId(id: number) {
    this.learnCatgoriesDetails = [];
    this.authService.getFAQByCategoryId(id).subscribe(
      (response: any) => {
        this.totalQuestions = response.length;
        this.showQuestion = response[0].question;
        this.showAnswer = response[0].answer;
        [response].forEach((element: any, index: number) => {
          this.learnCatgoriesDetails[index] = element;
        });
        console.log('this.learnCatgoriesDetails: ', this.learnCatgoriesDetails);
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
