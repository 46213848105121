import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  private unsubscribe$ = new Subject<void>();
  updatePasswordForm: FormGroup;
  loggedInUser:any;
  changePassword:boolean=true;
  resetPassword:boolean=false;
  userid:any;

  constructor(private activeRoute : ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private authService: AuthService,
    private router: Router){}

  ngOnInit(){
    this._initupdatePasswordForm();
    this.loggedInUser=this.authService.getUser();
    console.log(this.loggedInUser);
    
    this._checkActiveRoute();
  }

  _checkActiveRoute(){
    this.activeRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe(param => {
      console.log(param)
      if(param['mode']=='reset' && param['userId']){
        this.resetPassword=true;
        this.changePassword=false;
        this.userid=param['userId'];
      }else if(param['mode']=='change' && param['userId']){
        this.changePassword=true;
        this.resetPassword=false;
        this.userid=param['userId'];
      }else{
        this.router.navigate([''])
        return;
      }
    });
  }


  _initupdatePasswordForm(){
    this.updatePasswordForm=this.fb.group({
      currentPassword:[''],
      newPassword:['', Validators.required],
      confirmPassword:['', Validators.required]
    })
  }

  updatePassword(){
    console.log(this.updatePasswordForm.value)
    if(this.resetPassword){
      if(this.updatePasswordForm.get('newPassword')?.value !=
      this.updatePasswordForm.get('confirmPassword')?.value){
        this.notificationService.showError('New password and confirm password do not match');
        return;
      }
      this.authService.passwordReset(this.userid,this.updatePasswordForm.get('newPassword')?.value)
      .pipe(takeUntil(this.unsubscribe$)).subscribe({
        next:res=>{
          this.notificationService.showSuccess(res.message);
          this.updatePasswordForm.reset();
        },
        error: err=> {
          this.notificationService.showError(err.error.detail);
        }
    });
  }else if(this.changePassword){
    if(this.updatePasswordForm.get('newPassword')?.value !=
    this.updatePasswordForm.get('confirmPassword')?.value){
      this.notificationService.showError('New password and confirm password do not match');
      return;
    }

    const pass={
     oldPassword:this.updatePasswordForm.get('currentPassword')?.value,
     newPassword:this.updatePasswordForm.get('newPassword')?.value
    }

    this.authService.changePassword(this.userid,pass)
      .pipe(takeUntil(this.unsubscribe$)).subscribe({
        next:res=>{
          this.notificationService.showSuccess(res.message);
          this.updatePasswordForm.reset()
          this.router.navigate(['/login']);
        },
        error: err=> {
          this.notificationService.showError(err.error.detail);
        }
    });

  }
}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
