import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  user:any;
  navigationItems:{label:string,route:string}[] = [];

  userNavRoutes=[
    { label: 'Create Post', route: 'create-post' },
    { label: 'Create Video',  route: 'create-video' },
    // { label: 'Create Interview',  route: 'create-interview' },
    { label: 'Youtube Videos Links', route: 'youtube-videos' },
    { label: 'Post List', route: 'post-list' },
    // { label: 'Interview List', route: 'interview-list' },
    { label: 'Video List', route: 'video-list' },

    { label: 'Create Press Release', route: 'press-release' },
    { label: 'Press Release List', route: 'press-release-list' },
    // { label: 'Create FAQ', route: 'faq' },
    // { label: 'FAQ List', route: 'faq-list'},
    { label: 'My Profile', route: 'user-profile' },
    { label: 'Events List', route: 'events-list' },
    { label: 'People List', route: 'people-list' },
  ];

  superAdminRoutes=[
    { label: 'Post List', route: 'post-list' },
    { label: 'Video List', route: 'video-list' },
    { label: 'Press Release List', route: 'press-release-list' },
    { label: 'Recommended News', route: 'recommended-news' },
    { label: 'Youtube Videos Links', route: 'youtube-videos' },
    // { label: 'FAQ List', route: 'faq-list'},
    { label: 'People List', route: 'people-list' },
    { label: 'User List', route: 'user-list' },
    { label: 'Events List', route: 'events-list' },
    //  { label: 'Video Ask List', route: 'video-ask-list' },
    { label: 'Interview List', route: 'interview-list' },

  ]

  analystRoutes =[
    { label: 'Post List', route: 'post-list' },
    { label: 'Create Post', route: 'create-post' },
    { label: 'Dashboard', route: '/dashboard' },
    { label: 'My Profile', route: 'user-profile' },
  ]

  constructor(private authService:AuthService,
    private router: Router){}

  ngOnInit(){
    this.authService.loggedInUser.subscribe(res => {
      this.user=res.user;
    })


    switch (this.user.roleName) {
      case 'SuperAdmin':
        this.navigationItems=this.superAdminRoutes
        break;
      case 'CBWWriter':
        this.navigationItems=this.userNavRoutes
        break;
      case 'ExternalAnalyst':
        this.navigationItems=this.analystRoutes
        break;
      case 'ContentApprover':
        this.navigationItems = this.superAdminRoutes.filter( routelink => routelink.route != 'user-list');
        break;

      default:
        break;
    }

    this.authService.hasPageLoaded.next(true)
  }

  logOut(){
    this.authService.logout();
    this.router.navigate(['/login'])
  }
}
