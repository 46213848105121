<p-card class="post-card">
    <h3>Recommended News</h3>
    <div class="row"[formGroup]="newsForm">

        <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">News Url</label>
                <input pInputText class="p-inputtext-sm" formControlName="postURL" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">News Title</label>
                <input pInputText class="p-inputtext-sm" formControlName="postTitle" aria-describedby="username-help" />
            </div>
        </div>
        
        <div class="col-12 p-fluid mb-3">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Post Heading</label>
                <input pInputText class="p-inputtext-sm" formControlName="postHeading" id="username" aria-describedby="username-help" />
            </div>
        </div>
        
        <div class="col-6 col-sm-12 col-lg-12 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Short Description</label>
                <textarea id="float-input" rows="3" formControlName="shortDescription"  pInputTextarea></textarea>
            </div>
        </div>
        
        <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Feed Date</label>
                <p-calendar class="p-inputtext-sm" formControlName="interviewDate"  dateFormat="dd-mm-yy"></p-calendar>
            </div>
        </div>

        <div class="row mt-3 mb-3">
            <div class="col-4">
                <div class="flex align-items-center gap-1">
                    <p-checkbox name="group1" formControlName="isHotStory" [binary]="true"  inputId="hot"></p-checkbox>
                    <label class="mb-0 ml-3" for="hot">Is hot story</label>
                </div>
            </div>
            <div class="col-4">
                <div class="flex align-items-center gap-1">
                    <p-checkbox name="group1" formControlName="isShownAsBanner" [binary]="true"  inputId="banner"></p-checkbox>
                    <label class="mb-0 ml-3" for="banner">Show as banner</label>
                </div>
            </div>
        </div>
        
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <!-- <label htmlFor="username">Event Website</label>
                <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" /> -->
            </div>
        </div>
        
    </div>

    <div class="row">
        <div class="col-12 d-flex justify-content-between align-items-end gap-3">
            <div class="active">
            </div>
            <p-button styleClass="p-button-sm" (onClick)="save()" label="Save"></p-button>
        </div>
    </div>

    <!-- list view starts here -->

        <hr>
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    
                    <p-table #dt [value]="recommendedNewsList" responsiveLayout="stack" [breakpoint]="'960px'" [rows]="10"
                        [paginator]="true" [rowsPerPageOptions]="[10, 20, 50, 100, 200, 300, 500]"
                        [globalFilterFields]="['postTitle','shortDescription','postURL']"
                        styleClass=" p-datatable-gridlines p-datatable-striped p-datatable-sm" selectionMode="single"
                        [rowHover]="true">
                        <ng-template pTemplate="caption">
                            <div class="flex p-fluid justify-content-between align-items-center">
                                <div class="col-2">
                                    <div class="flex flex-column md:flex-row md:justify-content-between">
                                        <p-dropdown [options]="filterBy" placeholder="" (onChange)="onSelectionChange($event)"
                                            styleClass="p-inputtext-sm mb-2 md:mb-0 "></p-dropdown>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <h4 class="m-0">Recommended News List</h4>
                                </div>
                                <div class="col-2">
                                    <span class="p-input-icon-left ml-auto">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" class="p-inputtext-sm" #textInput
                                            (input)="dt.filterGlobal(textInput.value, 'contains')"
                                            placeholder="Search keyword" />
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th></th>
                                <th [pSortableColumn]="'postURL'">Link
                                    <p-sortIcon [field]="'postURL'"></p-sortIcon>
                                </th>
                                <th [pSortableColumn]="'postTitle'">Title
                                </th>
                                <th [pSortableColumn]="'shortDescription'">Short Description
                                </th>
                                <th *ngIf="loggedInUser.roleName=='SuperAdmin'">Created By</th>
                                <th [pSortableColumn]="'interviewDate'">Feed Date
                                </th>
                                <th [pSortableColumn]="'createdate'">Create Date
                                </th>
                                <!-- <th>Action</th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                            <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" (dblclick)="editRecord(rowData.postId)">
                                <td class="text-center">
                                    <a (click)="deleteRecord(rowData.postId)" pTooltip="Delete">
                                        <i class="pi pi-trash"></i>
                                    </a>
                                </td>
                                <!-- <td class="text-center">
                                    <a click="check(rowData.id)" pTooltip="check">
                                        <p-checkbox name="group1" value="check" inputId="ny"></p-checkbox>
                                    </a>
                                </td> -->
                                <td><span class="p-column-postURL"></span>{{rowData?.postURL}}</td>
                                <td><span class="p-column-postTitle"></span>{{rowData?.postTitle}}</td>
                                <td><span class="p-column-shortDescription"></span>{{rowData?.shortDescription}}</td>
                                <td *ngIf="loggedInUser.roleName=='SuperAdmin'"><span class="p-column-createdBy"></span>{{rowData?.firstName}} {{rowData?.lastName}}</td>
                                <td><span class="p-column-interviewDate"></span>{{rowData?.interviewDate?? (rowData?.interviewDate | date: 'dd/MM/yyyy')}}</td>
                                <td><span class="p-column-createdate"></span>{{rowData?.createdDateTime | date: 'dd/MM/yyyy'}}</td>
                                
                                
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="5">No Records found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    <!-- list view ends here -->
</p-card>


