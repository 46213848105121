<div class="inspirations">
  <img alt="cbw-img" class="inspirations--img" src="./assets/images/inspirations-banner.png" />
  <span class="gap-bottom"></span>
  <div class="inspirations--boxes">
    <div *ngFor="let data of allPeopleList; let index = index">
      <div *ngIf="index<=5" class="inspirations--boxes--box">
        <div class="inspirations--boxes--box--left">
          <img alt="cbw-img" class="inspirations--boxes--box--left--img" src="{{data.profileImage}}" />
        </div>
        <div class="inspirations--boxes--box--right">
          <span class="inspirations--boxes--box--right--name">{{data.peopleName}}</span>
          <span class="inspirations--boxes--box--right--position">{{data.peopleRole}}</span>
          <span class="inspirations--boxes--box--right--about">{{data.shortDescription}}</span>
          <a class="inspirations--boxes--box--right--more-details-btn"
            routerLink="/crypto-business-world-people/{{data.peopleId}}/{{data.peopleName}}"
            [state]="{id: data.peopleId}">More Details
            ></a>
        </div>
      </div>
    </div>
  </div>
  <span class="inspirations--load-more" *ngIf="!loadMore" (click)="loadMore = true">
    <img alt="cbw-img" src="./assets/images/load-more-people.svg" />
    <span>LODE MORE</span>
  </span>
  <div class="inspirations--boxes">
    <div *ngFor="let data of allPeopleList; let index=index">
      <div *ngIf="loadMore && index>=6" class="inspirations--boxes--box">
        <div class="inspirations--boxes--box--left">
          <img alt="cbw-img" class="inspirations--boxes--box--left--img" src="{{data.profileImage}}" />
        </div>
        <div class="inspirations--boxes--box--right">
          <span class="inspirations--boxes--box--right--name">{{data.peopleName}}</span>
          <span class="inspirations--boxes--box--right--position">{{data.peopleRole}}</span>
          <span class="inspirations--boxes--box--right--about">{{data.shortDescription}}</span>
          <span class="inspirations--boxes--box--right--more-details-btn"
            routerLink="/crypto-business-world-people/{{data.peopleId}}/{{data.peopleName}}"
            [state]="{id: data.peopleId}">More Details ></span>
        </div>
      </div>
    </div>
  </div>
  <span class="gap-bottom"></span>
</div>
