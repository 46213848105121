import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterviewListService {

  api=environment.apiURL
  constructor(private http: HttpClient) { }

  getInterviewList(params:any){ //bycatgoryid
    return this.http.get(`${this.api}/api/post/getAllPosts`,{params})
  }
}
