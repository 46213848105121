import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  loggedInUser: any;
  api= environment.apiURL;
  headerTags: any = [];
  currencySymbols = ["BCH", "BTC", "EOS", "ETH", "LTC", "XRP"];

  constructor(private authService: AuthService, public router: Router, private http: HttpClient) {
    this.loggedInUser = this.authService.getUser();
  }

  ngOnInit() {
    this.getHeaderCryptoExchangeData().subscribe(
      (response) => {
        if(response.data) {
          //console.log(response.data)
          for (const value of Object.values(response.data)) {
            this.headerTags.push(value);
          }
        }
      },
      (error) => {
        console.error('Error while getHeaderCryptoExchangeData from server:', error);
      }
    );
  }

  getHeaderCryptoExchangeData(): Observable<any> {
    return this.http.get(`${this.api}/api/latestCryptocurrencyQuotes/`);
  }

  logout() {
    this.authService.logout();
    this.loggedInUser = null;
    this.router.navigate(['/']);
  }
}
