<div class="hot-stories">
  <span class="hot-stories--head">Hot Stories</span>
  <div class="orange-line"></div>
  <div class="hot-stories--body">
    <div *ngFor="let data of hotStoriesList; let index = index">
      <div *ngIf="index < 3" class="hot-stories--body--boxes">
        <span class="hot-stories--body--boxes">
          <img class="hot-stories--body--boxes--img" src="{{ data.postImageURL }}" />
        </span>
        <a routerLink="/articles-preview/{{
          data.postTitle  | urlPrettier
        }}" class="hot-stories--body--boxes--description">{{
          data.postTitle
          }}</a>
        <span class="hot-stories--body--boxes--date">{{
          data.createdDateTime
          }}</span>
      </div>
    </div>
  </div>
  <div class="hot-stories--bottom">
    <span routerLink="/crypto-business-hot-story" class="hot-stories--bottom--btn">MORE FROM HOT STORIES</span>
  </div>
</div>
