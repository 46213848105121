import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-homepage-trending-videos',
  templateUrl: './homepage-trending-videos.component.html',
  styleUrls: ['./homepage-trending-videos.component.scss'],
})
export class HomepageTrendingVideosComponent {
  trendingVideosList: any = [];
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.searchCategoryByName();
  }

  searchCategoryByName() {
    this.authService.searchCategoryByName('Videos').subscribe(
      (response: any) => {
        this.getPostsByCategoryId(response.categoryId);
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }

  getPostsByCategoryId(id: number) {
    this.authService.getPostsByCategoryId(-1, id, 1).subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          this.trendingVideosList[index] = element;
        });
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
