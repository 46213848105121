<div class="editors-choice">
  <span class="editors-choice--head">Editor's Choice</span>
  <div class="orange-line"></div>
  <div class="editors-choice--body">
    <div *ngFor="let data of editorsChoiceList; let index = index">
      <div class="editors-choice--body--boxes" *ngIf="index < 4">
        <span class="editors-choice--body--boxes">
          <img class="editors-choice--body--boxes--img" src="{{ data.postImageURL }}" />
        </span>
        <a routerLink="/articles-preview/{{
          data.postTitle  | urlPrettier
        }}" class="editors-choice--body--boxes--description">{{
          data.postTitle
          }}</a>
        <span class="editors-choice--body--boxes--date">{{
          data.createdDateTime | date : "dd-MM-yyyy"
          }}</span>
      </div>
    </div>
  </div>
  <div class="editors-choice--bottom">
    <span routerLink="/crypto-business-editors-choice" class="editors-choice--bottom--btn">MORE FROM EDITOR
      CHOICE</span>
  </div>
</div>
