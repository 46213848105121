<div
  class="dialog-content bg-light flex flex-column min-h-screen mt-4 justify-content-center align-items-center change-password">
  <h4 class="mb-3">{{changePassword? 'Change Your Password' : 'Reset Your Password' }}</h4>
  <form *ngIf="changePassword" [formGroup]="updatePasswordForm" class="p-fluid form-body" (ngSubmit)="updatePassword()">
    <div class="p-inputgroup mb-3">
      <span class="p-inputgroup-addon">
        <i class="pi pi-lock"></i>
      </span>
      <input type="password" formControlName="currentPassword" placeholder="Current Password"
        class="p-inputtext p-inputtext-sm">
    </div>

    <div class="p-inputgroup mb-3">
      <span class="p-inputgroup-addon">
        <i class="pi pi-lock"></i>
      </span>
      <input type="password" formControlName="newPassword" placeholder="New Password"
        class="p-inputtext p-inputtext-sm">
    </div>

    <div class="p-inputgroup mb-3">
      <span class="p-inputgroup-addon">
        <i class="pi pi-lock"></i>
      </span>
      <input type="password" formControlName="confirmPassword" placeholder="Confirm Password"
        class="p-inputtext p-inputtext-sm">
    </div>

    <button pButton type="submit" label="Update Password" class="p-button mt-3"
      [disabled]="!updatePasswordForm.valid"></button>
  </form>


  <form *ngIf="resetPassword" [formGroup]="updatePasswordForm" class="p-fluid" (ngSubmit)="updatePassword()">

    <div class="p-inputgroup mb-3">
      <span class="p-inputgroup-addon">
        <i class="pi pi-lock"></i>
      </span>
      <input type="password" formControlName="newPassword" placeholder="New Password"
        class="p-inputtext p-inputtext-sm">
    </div>

    <div class="p-inputgroup mb-3">
      <span class="p-inputgroup-addon">
        <i class="pi pi-lock"></i>
      </span>
      <input type="password" formControlName="confirmPassword" placeholder="Confirm Password"
        class="p-inputtext p-inputtext-sm">
    </div>

    <button pButton type="submit" label="Update Password" class="p-button mt-3"
      [disabled]="!updatePasswordForm.valid"></button>
  </form>
</div>