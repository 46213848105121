import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogModule } from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BreadcrumbModule } from 'primeng/breadcrumb';

export const  UIMODULES = [
    CardModule,
    InputTextModule,
    InputTextareaModule,
    EditorModule,
    FileUploadModule,
    CheckboxModule,
    DropdownModule,
    TableModule,
    FormsModule,
    MultiSelectModule,
    TagModule,
    CalendarModule,
    TabViewModule,
    RadioButtonModule,
    DialogModule,
    ToastModule,
    OverlayPanelModule,
    BlockUIModule,
    ProgressSpinnerModule,
    BreadcrumbModule
  ]
