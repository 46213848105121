<div class="approve-reject flex gap-3">
    <ng-container [ngSwitch]="buttonVisible">
        <ng-template ngSwitchCase="both">
            <p-button label="Approve" icon="pi pi-check" (onClick)="approve()" styleClass="p-button-sm"></p-button>
            <p-button label="Reject" icon="pi pi-times" (onClick)="reject()" styleClass="p-button-sm p-button-danger"></p-button>
        </ng-template>
        <ng-template ngSwitchCase="approve">
            <p-button label="Approve" icon="pi pi-check" (onClick)="approve()" styleClass="p-button-sm"></p-button>
        </ng-template>
        <ng-template ngSwitchCase="reject">
            <p-button label="Reject" icon="pi pi-times" (onClick)="reject()" styleClass="p-button-sm p-button-danger"></p-button>
        </ng-template>
    </ng-container>
</div>
