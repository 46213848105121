<div class="index-methodology">
  <img class="index-methodology--img" src="./assets/images/index-methodology.png" />
  <div class="index-methodology--body">
    <h2>What is Index Methodology?</h2>
    <h4>Introduction</h4>
    <p>
      At Crypto Asset Rating (CAR), we offer multiple indices that make it
      easier for investors to decide which tokens they should add to their
      portfolio. We offer indices based on the types of tokens which includes-
    </p>
    <ul>
      <li class="mt-2">1. Composite Index</li>
      <li class="mt-2">2. Utility Index</li>
      <li class="mt-2">3. Security Index</li>
      <li class="mt-2">4. Commodity Index</li>
    </ul>
  </div>
  <p>
    Indices allow investors to gain an insight into the performance of an asset
    class or a segment of that asset class. Indices are used as the underlying
    for various financial instruments and benchmark the performance of
    portfolios designed to replicate a given asset class's implementation.
  </p>
  <h4>Constructing the Indexes</h4>
  <p>The Indexes are constructed and maintained at an individual market level.</p>
  <p>Below is the day-wise illustration as an example</p>
  <span class="day1">Day 1</span>
  <table class="table line-anywhere">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Number of Outstanding Tokens</th>
        <th scope="col">Price per Token (t - 1)</th>
        <th scope="col">Price per Token (t)</th>
        <th scope="col">Market Cap (t - 1)</th>
        <th scope="col">Market Cap (t)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Token A</td>
        <td>62,000,000</td>
        <td>75</td>
        <td>77</td>
        <td>4,650,000,000</td>
        <td>4,774,000,000</td>
      </tr>
      <tr>
        <td>Token B</td>
        <td>280,000,000</td>
        <td>0.9</td>
        <td>0.8</td>
        <td>252,000,000</td>
        <td>224,000,000</td>
      </tr>
      <tr>
        <td>Token C</td>
        <td>15,000,000</td>
        <td>15</td>
        <td>52</td>
        <td>825,000,000</td>
        <td>780,000,000</td>
      </tr>
      <tr>
        <td>Token D </td>
        <td>540,000,000</td>
        <td>3</td>
        <td>3.25</td>
        <td>1,620,000,000</td>
        <td>1,755,000,000</td>
      </tr>
    </tbody>
  </table>
  <br><br>
  <span>Note : Applicable to all Tables</span>
  <hr>
  <div class="day1-table-sum">
    <span>
      Token Market Cap (t - 1)</span>
    <span>7,347,000,000</span>
  </div>
  <hr>
  <div class="day1-table-sum">
    <span>
      Token Market Cap (t)</span>
    <span>7,533,000,000</span>
  </div>
  <hr>
  <div class="day1-table-sum">
    <span>
      Index Value (t - 1)</span>
    <span>10,000</span>
  </div>
  <hr>
  <div class="day1-table-sum">
    <span>
      Index Value (t)</span>
    <span>10,253.1646</span>
  </div>
  <br><br><br>
  <span class="day1">Day 2</span>
  <table class="table line-anywhere">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Number of Outstanding Tokens</th>
        <th scope="col">Price per Token (t - 1)</th>
        <th scope="col">Price per Token (t)</th>
        <th scope="col">Market Cap (t - 1)</th>
        <th scope="col">Market Cap (t)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Token A</td>
        <td>62,000,000</td>
        <td>77</td>
        <td>76</td>
        <td>4,774,000,000</td>
        <td>4,712,000,000</td>
      </tr>
      <tr>
        <td>Token B</td>
        <td>280,000,000</td>
        <td>0.8</td>
        <td>0.95</td>
        <td>224,000,000</td>
        <td>266,000,000</td>
      </tr>
      <tr>
        <td>Token C</td>
        <td>15,000,000</td>
        <td>52</td>
        <td>52.5</td>
        <td>780,000,000</td>
        <td>787,500,000</td>
      </tr>
      <tr>
        <td>Token D </td>
        <td>540,000,000</td>
        <td>3.25</td>
        <td>2.95</td>
        <td>1,755,000,000</td>
        <td>1,593,000,000</td>
      </tr>
    </tbody>
  </table>
  <br><br>
  <div class="day1-table-sum">
    <span>
      Token Market Cap (t - 1)</span>
    <span>7,533,000,000</span>
  </div>
  <div class="day1-table-sum">
    <span>
      Token Market Cap (t)</span>
    <span>73,585,000,000</span>
  </div>
  <div class="day1-table-sum">
    <span>
      Index Value (t - 1)</span>
    <span>10,253.1646</span>
  </div>
  <div class="day1-table-sum">
    <span>
      Index Value (t)</span>
    <span>10,015.6526</span>
  </div>
  <p>Constructing the CAR's Indexes involves the following steps:</p>
  <p>Each of these steps is described in detail below.</p>
  <ul>
    <li>• Defining the Token Universe</li>
    <li>• Identifying Eligible Tokens / Listing Criteria</li>
    <li>• Security Index</li>
    <li>• Classifying Tokens Utility/Security/Commodity</li>
  </ul>
  <span *ngIf="!isReadMore" (click)="isReadMore = true" class="read-more-less-center ">READ MORE</span>
  <h4 *ngIf="isReadMore">Defining the Token Universe</h4>
  <p *ngIf="isReadMore">The Token Universe is represented by:</p>
  <ul *ngIf="isReadMore">
    <li class="mt-2">1. Identifying eligible Tokens, and</li>
    <li class="mt-2">
      2. Classifying these suitable Tokens into the appropriate
      country/segments/Market capitalization.
    </li>
  </ul>
  <h5 *ngIf="isReadMore" class="mt-3">
    Identifying Eligible Equity Securities / Listing Criteria
  </h5>
  <p *ngIf="isReadMore">
    Supporting early-stage and mature tokens, CAR Listing criteria set out
    requirements to list with CAR Index. These requirements ensure the quality
    of the market that CAR operates. To record with CAR's Index, a company must
    satisfy the minimum admission criteria, including (i) Country of origin,
    (ii) Minimum Market Capital (iii) Minimum Trading length.
  </p>
  <p *ngIf="isReadMore">
    Each company and its token is classified in one and only one country, which
    allows for a distinctive sorting of each company by its respective country.
    Token Universe, a Token must have at least one eligible listing that meets
    the Minimum trading Requirement defined below.
  </p>
  <ul *ngIf="isReadMore">
    <li class="mt-2">•Three months to 12 months Frequency of Trading.</li>
  </ul>
  <p *ngIf="isReadMore">
    The 3-month to 12-month Frequency of Trading aims to select tokens with a
    sound long and short-term liquidity. The 3-month Frequency of Trading is
    determined by dividing the number of days a token traded during a 3-12 month
    period by the maximum possible number of trading days within this period.
  </p>
  <p *ngIf="isReadMore">
    Defining Market Capitalization requirement - CAR will take the token, which
    has a minimum market capitalization of 1 million or more.
  </p>
  <h5 *ngIf="isReadMore" class="mt-3">Classifying Token's Utility/Security/Commodity?</h5>
  <h6 *ngIf="isReadMore">Identifying the Token as Security</h6>
  <p *ngIf="isReadMore">
    CAR defines a token as security/Utility/Commodity based on the framework
    provided by U.S. Securities and Exchange Commission/CFTC.
  </p>
  <p *ngIf="isReadMore">
    A digital asset/Token should be analyzed to determine whether it has any
    product characteristics that meet the definition of "security" under the
    federal securities laws.
  </p>
  <p *ngIf="isReadMore">
    The transaction will be called an investment if it satisfies the following
    criteria:
  </p>
  <ul *ngIf="isReadMore">
    <li>• It is an investment of money.</li>
    <li>• The acquisition is in a common enterprise.</li>
    <li>
      • Profit expectation from the work of the promoters or the third party.
    </li>
    <li>• Profits are expected from the investment.</li>
  </ul>
  <p *ngIf="isReadMore">
    If a transaction satisfies the above condition, it will be categorized as a
    "Security Token."
  </p>
  <h5 *ngIf="isReadMore">Identifying the Token as Utility</h5>
  <p *ngIf="isReadMore">
    These tokens provide Investors with a product and service. The investor will
    be entitled to redeem them for the product or service when it becomes
    available later. Think of them as gateway tokens.
  </p>
  <p *ngIf="isReadMore">
    Since there is an upper cap on the maximum token availability, the tokens'
    value may go up because of the supply-demand equation. The idea is that the
    more the demand for an asset, the lesser its supply will be, which will
    shoot up its price. Unlike security tokens, these assets can be easier to
    sell on a secondary market, provided they have been listed on an exchange.
    Generally, those investing in these types of tokens are motivated by the
    prospect of their value rising substantially in the future.
  </p>
  <p *ngIf="isReadMore">Utility tokens can:</p>
  <ul *ngIf="isReadMore">
    <li>• Give holders a right to use the network.</li>
    <li>• Give holders a right to take advantage of the network by voting.</li>
  </ul>
  <p *ngIf="isReadMore">Tokens need to fulfill one of the conditions:</p>
  <ul *ngIf="isReadMore">
    <li>• They help in building an internal economy within the system.</li>
    <li>
      • They can give the holders to take advantage of a network by voting in
      it.
    </li>
  </ul>
  <h5 *ngIf="isReadMore">Identifying the Token as Commodity</h5>
  <p *ngIf="isReadMore">
    To uniquely identify this type of tokens, we need to know three properties:
  </p>
  <ul *ngIf="isReadMore">
    <li>• <b>Not Governed</b> - Commodity token is usually described as a virtual, decentralized, and (at first glance)
      anonymous token that <b>is not government-backed</b> or backed by any other legal entity and cannot be exchanged
      for gold
      or any other commodity.</li>
    <li>• <b>No Intervention</b> - The fact that the creation and the increase of such commodity tokens are automated
      and limited by the system implies no need for the <b>intervention of a central entity</b> /authority to issue
      tokens.</li>
    <li>• <b>Limited Tokens</b> - The <b>limited number of tokens</b> , together with the fact that conversion rates for
      tokens are determined by supply and demand, without a government body being able to intervene (e.g., by printing
      additional money).</li>
  </ul>
  <h6 *ngIf="isReadMore">Identifying the Token as Currency/StableCoin</h6>
  <p *ngIf="isReadMore">Payment mechanisms all over the world have inefficiencies, and these inefficiencies have given
    birth to currency
    tokens. The most significant example of a currency token is StableCoin. Stablecoins are cryptocurrencies designed to
    minimize the volatility of the price of the stablecoin relative to some "stable" asset or basket of assets. A
    stablecoin can be pegged to a cryptocurrency, fiat money, or exchange-traded commodities</p>
  <p *ngIf="isReadMore">Currency tokens are used as an alternative means of payment for goods and services.</p>
  <p *ngIf="isReadMore">Tokens are classified as currency tokens based on the following conditions.</p>
  <p *ngIf="isReadMore">1. <b>Payment mechanism:</b> means of payment for goods and services external to the platform
    running the token</p>
  <p *ngIf="isReadMore">2. Store value</p>
  <h4 *ngIf="isReadMore">Index maintenance:</h4>
  <p *ngIf="isReadMore">The CAR Indexes are maintained to reflect the evolution of the underlying Crypto markets on a
    timely basis while
    seeking to achieve:</p>
  <ul *ngIf="isReadMore">
    <li>• Index continuity</li>
    <li>• Continuous constituents of the indexes, and</li>
    <li>• Index stability</li>
  </ul>
  <p *ngIf="isReadMore">An Index Committee maintains indices. Most committees are comprised of professional members of
    CAR. Members include
    the Financial Analyst team; they are primarily responsible for maintaining/calculating the index. The Steering
    committee's secondary responsibility, the committee members, consists of the professionals from the Corporate
    Advisors Team and the Financial Analyst Team. At each meeting, the Index Committee review pending actions that may
    affect index constituents, statistics comparing the composition of the indices to the market, companies that are
    being considered candidates for addition to an index, and any significant market events. Also, the Index Committee
    may revise index policy covering rules for selecting a token, treatment, or other matters.</p>
  <p *ngIf="isReadMore">The Internal Committee's objective is to systematically reassess the various dimensions of the
    Token Universe for
    all the indexes on a fixed quarterly timetable. A committee involves in a comprehensive review of the Indexes.</p>
  <p *ngIf="isReadMore">During each quarter, the token Universe is updated; the size Range is recalculated. Then, the
    following index
    maintenance activities are undertaken for each index:</p>
  <ul *ngIf="isReadMore">
    <li>• Updating the Token Universe.</li>
    <li>• Recalculating the Market Cap for indexes.</li>
    <li>• Reassessing the Number of Companies.</li>
    <li>• Assessing conformity with token criteria Requirements listed above in constructing the index.</li>
    <li>• Updating the Token Universe</li>
  </ul>
  <p *ngIf="isReadMore">The token Universe is updated by identifying a new token that was not part of the Index Universe
    at the previous
    Quarterly Index Review.</p>
  <ul *ngIf="isReadMore">
    <li>• Evaluating each token that is not a constituent of the Indexes.</li>
  </ul>
  <p *ngIf="isReadMore">New tokens are evaluated relative to this updated threshold, whereas all existing constituents
    will not be
    evaluated relative to this requirement.</p>
  <br *ngIf="isReadMore"><br>
  <h4 *ngIf="isReadMore">Ongoing Event-Related Changes</h4>
  <p *ngIf="isReadMore">This methodology document describes CAR guidelines for the treatment of corporate events within
    the CAR Indexes. It
    also guides the treatment of corporate events within CAR Capped Non-Market Capitalization Weighted indexes.</p>
  <p *ngIf="isReadMore">Overall, the methodology of the corporate event can be described in three broad categories:</p>
  <ul *ngIf="isReadMore">
    <li>• Mergers and Acquisitions (M&As) and Spin-offs</li>
    <li>• Other Events Resulting in Changes in Number of tokens</li>
    <li>• Suspensions, Delisting and Bankruptcies</li>
  </ul>
  <p *ngIf="isReadMore">For instance, in the case of Merger and Acquisition;</p>
  <ul *ngIf="isReadMore">
    <li>• If the acquirer and acquiree are both present in the index, both will be considered one from there onwards,
      and the new token will be replaced with old ones.</li>
    <li>• If the acquired is present in the index, but the acquiree is not, only the acquirer's market capitalization
      will change without any new token being added/removed.</li>
    <li>• Suppose the acquirer is not present in the index and the acquiree is present in the index. In that case, the
      acquiree will get eliminated from the index calculation, and a new token/company will get included in the index.
    </li>
  </ul>
  <h4 *ngIf="isReadMore">Announcement Policy</h4>
  <p *ngIf="isReadMore">CAR communicates and consults with clients through various channels using index announcements,
    emails, and data
    distribution. Also, CAR has a designated client support team available to respond to inquiries. When a material
    change to an index methodology is considered, CAR will publish that on its website. A material change alters the
    index objective or changes the methods that affect the likelihood that the index will achieve its purpose.</p>
  <p *ngIf="isReadMore">All index constituents are evaluated daily for data needed to calculate index levels and
    returns. All events
    affecting the daily index calculation are typically announced via email to clients.</p>
  <h4 *ngIf="isReadMore">Disclaimer</h4>
  <p *ngIf="isReadMore">This document has been prepared solely for informational purposes based upon information
    generally available to the
    public and from sources believed to be reliable. The Content shall not be used for any unlawful or unauthorized
    purposes. CAR does not guarantee the accuracy, completeness, timeliness, or availability of the Content. CAR
    Parties/Members are not responsible for any errors or omissions, regardless of the cause, for the results obtained
    from the use of the Content.</p>
  <span *ngIf="isReadMore" (click)="isReadMore = false" class="read-more-less-center ">READ LESS</span>
  <br><br><br>
</div>