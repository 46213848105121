<div class="container-fluid mt-3">
    <div class="row justify-content-center" style="min-height: 100vh;">
        <div class="col-12 col-md-7 col-sm-12 article-main">
            <app-article></app-article>
        </div>
        <div class="col-12 col-md-3 col-sm-12 article-side">
            <app-editors-choice-comp></app-editors-choice-comp>
        </div>
    </div>
</div>
