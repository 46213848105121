<div class="card m-3">
  <span class="articles--head card-header">
    <span [ngClass]="
        isTrendingArticles
          ? 'articles--head--items--active'
          : 'articles--head--items'
      " (click)="isTrendingArticles = !isTrendingArticles">Trending Articles</span>
    <span [ngClass]="
        !isTrendingArticles
          ? 'articles--head--items--active'
          : 'articles--head--items'
      " (click)="isTrendingArticles = !isTrendingArticles">Analysis</span>
  </span>

  <!-- Trending Articles -->
  <div *ngIf="isTrendingArticles">
    <ul class="list-group list-group-flush" *ngFor="let data of trendingArticlesList">
      <li class="list-group-item articles--body--items">
        <a routerLink="/articles-preview/{{ data.postTitle | urlPrettier }}" class="articles--body--items--text">{{
          data.postTitle }}</a>
        <span class="articles--body--items--date">{{
          data.createdDateTime | date: 'yyyy-MM-dd HH:mm:ss'
          }}</span>
      </li>
    </ul>
    <div class="articles--bottom">
      <span routerLink="/cbw-Article/Trending" class="articles--bottom--text-link">MORE FROM TRENDING ARTICLES ></span>
    </div>
  </div>

  <!-- Analysis -->
  <div *ngIf="!isTrendingArticles">
    <ul class="list-group list-group-flush" *ngFor="let data of analysisList">
      <li class="list-group-item articles--body--items">
        <a routerLink="/articles-preview/{{ data.postTitle | urlPrettier }}" class="articles--body--items--text">{{
          data.postTitle }}</a>
        <span class="articles--body--items--date">{{
          data.createdDateTime | date: 'yyyy-MM-dd HH:mm:ss'
          }}</span>
      </li>
    </ul>
    <div class="articles--bottom">
      <span routerLink="/cbw-Article/Analysis" class="articles--bottom--text-link">MORE FROM ANALYSIS ></span>
    </div>
  </div>
</div>