import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-homepage-press-releases',
  templateUrl: './homepage-press-releases.component.html',
  styleUrls: ['./homepage-press-releases.component.scss'],
})
export class HomepagePressReleasesComponent {
  pressReleasesList: any = [];

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.searchCategoryByName();
  }

  searchCategoryByName() {
    this.authService.searchCategoryByName('Press').subscribe(
      (response: any) => {
        this.getPostsByCategoryId(response.categoryId);
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }

  getPostsByCategoryId(id: number) {
    this.authService.getPostsByCategoryId(-1, id, 1).subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          if(!element.postImageURL) element.postImageURL = element.postThumbnailURL;
          this.pressReleasesList[index] = element;
        });
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
