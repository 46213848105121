<div class="external-analyst">
  <div class="external-analyst--main">
    <img
      class="external-analyst--main--img"
      src="assets/images/become-EA.png"
    />
    <div class="external-analyst--main--body">
      <span class="external-analyst--main--body--title"
        >What is an External Analyst (EA)</span
      >
      <span class="external-analyst--main--body--description"
        >CAR has its own team of expert rating analysts who are on CAR's
        payroll. However, CAR believes that there are a many experts available
        externally, in different parts of the world, who are extremely
        enthusiastic about the crypto world and always stay abreast about
        related developments. We want to tap into that expertise, in addition to
        that of our own set of internal analysts, to contribute to our platform
        (both CARP and CBW).</span
      >
      <span class="external-analyst--main--body--description">
        Therefore, CAR encourages all crypto enthusiasts to register on our
        platform as External Analysts (EAs or Community Analysts). An EA can
        provide scores for rating parameters(subject to various controls and
        requirements imposed by CAR), write articles, post videos and podcasts,
        and eventually be rewarded for doing so.
      </span>
      <span routerLink="/register" class="external-analyst--main--body--enroll-btn">ENROLL</span>
      <div class="external-analyst--main--body--boxes">
        <div class="external-analyst--main--body--boxes--box-left">
          <img
            class="external-analyst--main--body--boxes--box-left--img"
            src="assets/images/business-external-analyst.svg"
          />
          <span class="external-analyst--main--body--boxes--box-left--title"
            >Business External Analysts</span
          >
          <span
            class="external-analyst--main--body--boxes--box-left--description"
            >A Business External Analyst (BA) is responsible for assessing a
            crypto asset issuer's business risk. Generally, a BA is an SME from
            the same industry where a particular crypto asset issuer is
            operating (eg. Fintech or Healthtech etc). A BA primarily provides
            scores and commentary for the business risk parameters, along with
            other rating parameters. Business risk may address the product
            development stage, team structure & management, macro economic
            factors, competitive analysis of the product, addressable market
            size and the product development roadmap.</span
          >
        </div>
        <div class="external-analyst--main--body--boxes--box-right">
          <img
            class="external-analyst--main--body--boxes--box-right--img"
            src="assets/images/legal-external-analyst-icon.svg"
          />
          <span class="external-analyst--main--body--boxes--box-right--title"
            >Legal External Analysts</span
          >
          <span
            class="external-analyst--main--body--boxes--box-right--description"
            >A Legal External Analyst (LA) is responsible for assessing a crypto
            asset and its issuer's legal risk. Generally, a LA is a lawyer or is
            an expert in crypto related regulations and knowledgeable about the
            regulatory dynamics in the crypto market. An LA primarily provides
            scores and commentary for the legal risk parameters, along with
            other rating parameters. Legal risk focuses on the legal aspect of
            the crypto asset as well as the crypto asset issuer. This may
            include among other things, understanding the legitimacy of the
            business model or product being offered, vetting of company related
            documents, company capitalisation structure, intellectual property,
            the nature and legal validity of the crypto asset, and the
            regulatory framework under which the crypto asset is being offered
            etc.</span
          >
        </div>
        <div class="external-analyst--main--body--boxes--box-left">
          <img
            class="external-analyst--main--body--boxes--box-left--img"
            src="assets/images/technology-external-analyst.svg"
          />
          <span class="external-analyst--main--body--boxes--box-left--title"
            >Technology External Analysts</span
          >
          <span
            class="external-analyst--main--body--boxes--box-left--description"
            >A Technology External Analyst (TA) is responsible for assessing the
            technology risk of the product being offered by the crypto asset
            issuing company. A typical TA is a blockchain developer having
            understanding of blockchain solution implementation. A TA provides
            scores and commentary for the technology risk parameters, along with
            other rating parameters. Technology risk typically involves
            assessing the technology of the product or solution being offered,
            usage of blockchain, if blockchain is adding significant advantage,
            maturity of the technology being used and competitive technology
            available in the market etc. It may also include an audit of the
            smart contract.</span
          >
        </div>
        <div class="external-analyst--main--body--boxes--box-right">
          <img
            class="external-analyst--main--body--boxes--box-right--img"
            src="assets/images/financial-external-analysys-icon.svg"
          />
          <span class="external-analyst--main--body--boxes--box-right--title"
            >Financial External Analysts</span
          >
          <span
            class="external-analyst--main--body--boxes--box-right--description"
            >A Financial External Analyst (FA) is responsible for assessing a
            crypto asset and its issuer's financial risk. In general, a FA is
            from the BFSI industry having extensive knowledge of financial due
            diligence and modelling. A FA primarily provides scores and
            commentary for the financial risk parameters, along with other
            rating parameters. Financial risk assessment may include analysing
            the financial strength of the company, historical financial
            analysis, cash flow analysis, capital structure, ratio analysis,
            financial projection, usage of funds, and value of the token
            etc.</span
          >
        </div>
      </div>
    </div>
    <div class="external-analyst--main--bottom">
      <span class="external-analyst--main--bottom--title"
        >Enhance your career as an EA with us</span
      >
      <span class="external-analyst--main--bottom--description"
        >If you are a crypto asset enthusiast and follow the crypto market, we’d
        love to consider you as an external analyst on our platform. To become
        an external analyst, you can enroll on our platform and we will get back
        to you.</span
      >
    </div>
    <div class="external-analyst--main--toolkit-btn">
      <span (click)="openPdf()" class="external-analyst--main--toolkit-btn--text">TOOLKIT</span>
    </div>
  </div>
</div>
