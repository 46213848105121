<div class="article-wrapper mt-4" *ngIf="article">
  <div class="article-header">
    <div class="article-header-title mb-4">
      <h4 style="font-size:30px">{{ article.title }}</h4>

      <h1 style="font-size:18px" class="mt-4">{{article.postHeading}}</h1>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="article-header-userdetails flex flex-row gap-3">
          <div *ngIf="article.writer.img; else defaultImage">
            <img alt="cbw-img" style="height: 35px; width: 45px; margin-top: 6px" [src]="article.writer.img" alt="" />
          </div>
          <ng-template #defaultImage>
            <img alt="cbw-img" style="height: 35px; width: 45px; margin-top: 6px" src="assets/images/profile-image.jpg" alt="profile-image" />
          </ng-template>
          <div class="article-header-userdetails-info flex flex-column">
            <span class="useremail">{{ article.writer.name }}</span>
            <span class="usertype">{{ article.writer.roleName }}</span>
            <span class="location text-sm text-600">{{
              article.writer.country
            }}</span>
            <!-- <span class="-date text-sm text-600">Apr,19,2023</span> -->
          </div>
        </div>
      </div>
      <div class="col-md-6 flex justify-content-end">
        <div class="article-header-social flex flex-column align-items-end">
          <span class="article-date mb-1 text-600">{{
            article.date | date : "dd-MM-yyyy"
          }}</span>
          <span class="flex gap-2 mb-1 align-items-center text-600">
            {{ article.likes.length
            }}<i
              class="pi pi-thumbs-up-fill cursor-pointer"
              [ngStyle]="{ color: postLiked ? 'blue' : '' }"
              (click)="likePost(article.id)"
            ></i
            >| <i class="pi pi-envelope"></i
            >{{ article.comments.length }} Comment(s)
          </span>

          <!-- below will be for profile page later -->
          <!-- <div class="share flex mt-2 gap-2">
            <i (click)="openFacebookLink()" style="color: #4267b2"
              class="pi pi-facebook text-xl cursor-pointer"></i>
            <i (click)="openTwitterLink()" style="color: #03a9f4"
              class="pi pi-twitter text-xl cursor-pointer"></i>
            <i (click)="openLinkedinLink()" style="color: #03a9f4"
            class="pi pi-linkedin text-xl cursor-pointer"></i>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!--header ends -->
  <div class="row">
    <div class="col-12 col-md-12 article-thumbnail my-4">
      <img alt="cbw-img" class="w-full" height="350px" src="{{ article.thumbnail }}" alt="{{article.imageAlt}}" />
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-12 article-body" [innerHTML]="article.content">
      <hr />
    </div>

    <div class="col-12 col-md-8 article-tags grid gap-2">
      <p-tag class="ml-1 cursor-pointer tags hash-tags" value="{{article.tags}}" [rounded]="true"></p-tag>
    </div>

    <div class="col-12 col-md-4 social flex flex-column align-items-end">
      <span class="flex gap-2 mb-1 align-items-center text-600">
        {{ article.likes.length
        }}<i
          class="pi pi-thumbs-up-fill cursor-pointer"
          [ngStyle]="{ color: postLiked ? 'blue' : '' }"
          (click)="likePost(article.id)"
        ></i
        >| <i class="pi pi-envelope"></i
        >{{ article.comments.length }} Comment(s)
      </span>
      <div class="share flex mt-2 gap-2">
        <i (click)="openFacebookLink()" style="color: #4267b2"
          class="pi pi-facebook text-xl cursor-pointer"></i>
        <i (click)="openTwitterLink()" style="color: #03a9f4"
          class="pi pi-twitter text-xl cursor-pointer"></i>
        <i (click)="openLinkedinLink()" style="color: #03a9f4"
        class="pi pi-linkedin text-xl cursor-pointer"></i>
      </div>
        <!-- <i class="pi pi-bookmark-fill text-xl cursor-pointer"></i> -->
      </div>
  </div>

    <div class="col-12">
      <hr class="mt-4" />
    </div>
  <!--article-body row ends-->

  <div class="comment-section row">
    <span
      *ngIf="routePath != 'preview' && loggedInUser"
      [style]="{ visibility: isCommentFieldVisible ? 'hidden' : 'visible' }"
      (click)="showCommentField()"
      class="text-right text-primary cursor-pointer"
      >Make Comment</span
    >
    <div
      class="col-12 col-md-12 flex gap-3"
      *ngFor="let comment of article.comments"
    >
      <img
        class="comment-section-user-image mt-3"
        height="35px"
        width="45px"
        src="{{ comment.profileImageLink ?? '' }}"
        alt=""
      />
      <div class="col-10 col-md-10 user-comment-wrapper">
        <span class="user-name"
          >{{ comment.firstName }} {{ comment.lastName }}</span
        >
        <span class="comment-date ml-2 text-600 text-sm">{{
          comment.commentdatetime | date : "dd-MM-yyyy"
        }}</span>
        <div class="comment-body mt-2" style="font-size: 14px">
          {{ comment.postcomment }}
        </div>
      </div>
    </div>

    <div
      #commentField
      class="col-12 col-md-12 flex flex-column"
      [style]="{ visibility: isCommentFieldVisible ? 'visible' : 'hidden' }"
    >
      <textarea #cmnt rows="3" class pInputTextarea></textarea>
      <p-button
        label="Comment"
        class="align-self-end"
        styleClass="p-button-raised p-button-sm mt-2"
        (click)="comment(article.id, cmnt)"
      ></p-button>
    </div>
  </div>
</div>
