<p-card header="Dashboard">
   <div class="container-fluid main-dashboard h-screen">
    <ng-container [ngSwitch]="userType">
        <ng-template [ngSwitchCase]="'Admin'">
          <p>Lorem ipsum dolor sit amet. Admin</p>
        </ng-template>
        <ng-template [ngSwitchCase]="'ExternalAnalyst'">
            <ng-container *ngTemplateOutlet="analyst"></ng-container>
        </ng-template>
        <ng-template [ngSwitchCase]="'CBWWriter'">
            <ng-container *ngTemplateOutlet="uDashboard"></ng-container>
        </ng-template>
        
      </ng-container>
   </div>
</p-card>


<ng-template #admin>
    <div class="row">
        <p><span class="font-bold">ADMIN</span> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum, rerum.</p>
    </div>
</ng-template>

<ng-template #uDashboard>
    <div class="row u-dash">
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-book"></i>
            <span>Total Posts: {{postData?.postDataCount??0}}</span>
        </div>
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-book"></i>
            <span>Approved Posts: {{postData?.approvedPosts??0}}</span>
        </div>
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-book"></i>
            <span>Rejected Posts: {{postData?.rejectedPosts??0}}</span>
        </div>
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-book"></i>
            <span>Pending Posts: {{postData?.pendingPosts??0}}</span>
        </div>
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-book"></i>
            <span>Drafted Posts: {{postData?.draftPosts??0}}</span>
        </div>
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-video"></i>
            <span>Approved Videos: {{postData?.approvedVideos??0}}</span>
        </div>
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-video"></i>
            <span>Rejected Videos: {{postData?.rejectedVideos??0}}</span>
        </div>
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-video"></i>
            <span>Pending Videos: {{postData?.pendingVideos??0}}</span>
        </div>
    </div>
</ng-template>
<ng-template #analyst>
    <div class="row u-dash">
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-book"></i>
            <span>Total Posts: {{postData?.postDataCount??0}}</span>
        </div>
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-book"></i>
            <span>Approved Posts: {{postData?.approvedPosts??0}}</span>
        </div>
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-book"></i>
            <span>Rejected Posts: {{postData?.rejectedPosts??0}}</span>
        </div>
        <div class="col-12 col-md-3 u-dash-card">
            <i class="pi pi-book"></i>
            <span>Pending Posts: {{postData?.pendingPosts??0}}</span>
        </div>
    </div>
</ng-template>

<ng-template #writer>
    <div class="row">
        <!-- <p><span class="font-bold">WRITER</span> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum, rerum.</p> -->
    </div>
</ng-template>


