import { Component } from '@angular/core';

@Component({
  selector: 'app-car-composite-index',
  templateUrl: './car-composite-index.component.html',
  styleUrls: ['./car-composite-index.component.scss']
})
export class CarCompositeIndexComponent {

}
