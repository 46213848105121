<div class="terms-of-use container">
    <h2 class="sub-page-title text-center mb-4">Terms <span class="color-orange">of</span>   Use</h2>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p>Comments or opinions expressed via Crypto Business World Website are those of their respective contributors and do not represent the views of Crypto Business World, its management, or employees.</p>
            <ul class="tick">
                <div class="gap50">By using the Crypto Business World, you agree that:</div>
                <br>
                <li>
                    Your blog posts and comments will not intentionally or unintentionally defame, compromise the trade secrets or confidential information of, or violate the copyright or other intellectual property rights of, any third party. You agree to defend, indemnify and hold Crypto Business World harmless from and against any claims, losses or liabilities based on a violation of these terms and conditions
                </li>
                <li>All posted comment may be re-used by Crypto Business World or quoted in other media and might be attributed to you by name.</li>
                <li>You will not upload, post or otherwise transmit any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment</li>
                <li>You will not use the Crypto Business World Website in connection with any commercial endeavors</li>
            </ul>
            <p>
                Crypto Business World reserves the right to delete any blogs, postings or comments that in the sole judgement of Crypto Business World violates this agreement or which may be offensive, illegal or violate the rights, harm, or threaten the safety of any person.
            </p>
            <p>
                Crypto Business World reserves the right to refuse, suspend or terminate membership and/or modify or withdraw the Crypto Business World Web service at any time with or without notice to members.
            </p>
            <p>
                Crypto Business World is not responsible for the accuracy or veracity of content posted on the Crypto Business World Website, or for the content, accuracy and opinions expressed on links to third party sites. Links to third party sites are in no way investigated, monitored or checked for accuracy or completeness by Crypto Business World.
            </p>
            <p>We reserve the right to change these Terms of Use at any time.</p>
        </div>
    </div>
</div>