import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class YoutubeLinksService {

  api=environment.apiURL
  constructor(private http: HttpClient) { }

  createYoutubeVideoLink(data:any):Observable<any>{
    return this.http.post(`${this.api}/api/youtubeVideos/`,data)
  }

  getYoutubeVideoLinks(owner:any,params:any):Observable<any>{
    return this.http.get(`${this.api}/api/youtubeVideos/`,{params}) //${owner}
  }

  updateYoutubeVideoLink(id:any,data:any):Observable<any>{
    return this.http.put(`${this.api}/api/youtubeVideos/${id}`,data) //${owner}
  }

  deleteYoutubeVideoLink(id:string):Observable<any>{
    return this.http.delete(`${this.api}/api/youtubeVideos/${id}`)
  }

  disablePost(id:any): Observable<any>{
    return this.http.put(`${this.api}/api/post/disablePostById/${id}`,{})
  }

}
