import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-inspirations',
  templateUrl: './inspirations.component.html',
  styleUrls: ['./inspirations.component.scss']
})
export class InspirationsComponent {
  loadMore: boolean = false;

  allPeopleList: any = [];
  constructor(private authService: AuthService) { }
  ngOnInit() {
    this.getAllPeople();
  }
  getAllPeople() {
    this.authService.getAllPeople().subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          this.allPeopleList[index] = element;
        });
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
