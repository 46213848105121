import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { UserEmailVerificationComponent } from './components/user-email-verification/user-email-verification.component';
import { AboutComponent } from './pages/about/about.component';
import { ArticleDetailComponent } from './pages/article-detail/article-detail.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CreateInterviewComponent } from './pages/create-interview/create-interview.component';
import { CreatePostComponent } from './pages/create-post/create-post.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FaqListComponent } from './pages/faq-list/faq-list.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { InterviewListComponent } from './pages/interview-list/interview-list.component';
import { LoginComponent } from './pages/login/login.component';
import { MainDashboardComponent } from './pages/main-dashboard/main-dashboard.component';
import { PostListComponent } from './pages/post-list/post-list.component';
import { PressReleaseListComponent } from './pages/press-release-list/press-release-list.component';
import { PressReleaseComponent } from './pages/press-release/press-release.component';
import { RecommendedNewsComponent } from './pages/recommended-news/recommended-news.component';
import { RegisterComponent } from './pages/register/register.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { YotubeVideoLinksComponent } from './pages/yotube-video-links/yotube-video-links.component';
import { AllArticlesComponent } from './pages/market/all-articles/all-articles.component';
import { EditorsChoiceComponent } from './pages/market/editors-choice/editors-choice.component';
import { FeaturedArticlesComponent } from './pages/market/featured-articles/featured-articles.component';
import { HotStoriesComponent } from './pages/market/hot-stories/hot-stories.component';
import { TrendingArticlesComponent } from './pages/market/trending-articles/trending-articles.component';
import { HowToBecomeEaComponent } from './pages/external-analyst/how-to-become-ea/how-to-become-ea.component';
import { BasicRatingComponent } from './pages/index/basic-rating/basic-rating.component';
import { CarCompositeIndexComponent } from './pages/index/car-composite-index/car-composite-index.component';
import { IndexGovernanceComponent } from './pages/index/index-governance/index-governance.component';
import { IndexMethodologyComponent } from './pages/index/index-methodology/index-methodology.component';
import { PressReleasePageComponent } from './pages/press-release-page/press-release-page.component';
import { EventsComponent } from './pages/events/events.component';
import { VideosComponent } from './pages/videos/videos.component';
import { LearnComponent } from './pages/learn/learn.component';
import { RecommendedNewsPageComponent } from './pages/recommended-news-page/recommended-news-page.component';
import { BreakingNewsPageComponent } from './pages/breaking-news-page/breaking-news-page.component';
import { DailyNewsPageComponent } from './pages/daily-news-page/daily-news-page.component';
import { InspirationsComponent } from './pages/inspirations/inspirations.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ArticleDetailAllUserComponent } from './pages/article-detail-all-user/article-detail-all-user.component';
import { InspirationDetailPageComponent } from './pages/inspiration-detail-page/inspiration-detail-page.component';
import { LearnCategoryDetailsComponent } from './pages/learn-category-details/learn-category-details.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'register/',
    component: RegisterComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'login/',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'verifyEmail/:userId/',
    component: UserEmailVerificationComponent,
  },
  {
    path: 'verifyEmail/:userId',
    component: UserEmailVerificationComponent,
  },
  {
    path: 'forgot-password/:mode/:userId/',
    component: ForgotPasswordComponent,
  },
  {
    path: 'forgot-password/:mode/:userId',
    component: ForgotPasswordComponent,
  },
  {
    path: 'updateForgotPasswordWithNewPassword/:userId/',
    redirectTo: 'forgot-password/reset/:userId/',
    pathMatch: 'full',
  },
  {
    path: 'updateForgotPasswordWithNewPassword/:userId',
    redirectTo: 'forgot-password/reset/:userId',
    pathMatch: 'full',
  },
  {
    path: 'article-detail/:id/:posttitle/',
    component: ArticleDetailComponent,
  },
  {
    path: 'article-detail/:id/:posttitle',
    component: ArticleDetailComponent,
  },
  {
    path: 'article-detail/:id/',
    component: ArticleDetailComponent,
  },
  {
    path: 'article-detail/:id',
    component: ArticleDetailComponent,
  },
  {
    path: 'Article/:id/',
    component: ArticleDetailComponent,
  },
  {
    path: 'Article/:id',
    component: ArticleDetailComponent,
  },
  {
    path: 'ArticleDetail/:id/:posttitle/',
    component: ArticleDetailComponent,
  },
  {
    path: 'ArticleDetail/:id/:posttitle',
    component: ArticleDetailComponent,
  },
  {
    path: 'preview/:id/:posttitle/',
    component: ArticleDetailComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: { userType: ['SuperAdmin', 'ExternalAnalyst', 'CBWWriter'] },
  },
  {
    path: 'preview/:id/:posttitle',
    component: ArticleDetailComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: { userType: ['SuperAdmin', 'ExternalAnalyst', 'CBWWriter'] },
  },
  // {
  //   path: 'articles-preview/:id/:posttitle',
  //   component: ArticleDetailAllUserComponent,
  //   // canActivate: [AuthGuard],
  //   // canActivateChild: [AuthGuard],
  //   // data: {userType:['SuperAdmin','ExternalAnalyst','CBWWriter']}
  // },
  {
    path: 'articles-preview/:posttitle/',
    component: ArticleDetailAllUserComponent,
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    // data: {userType:['SuperAdmin','ExternalAnalyst','CBWWriter']}
  },
  {
    path: 'articles-preview/:posttitle',
    component: ArticleDetailAllUserComponent,
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    // data: {userType:['SuperAdmin','ExternalAnalyst','CBWWriter']}
  },
  {
    path: 'articles-preview/:id/:posttitle/',
    component: ArticleDetailComponent,
  },
  {
    path: 'articles-preview/:id/:posttitle',
    component: ArticleDetailComponent,
  },
  {
    path: 'crypto-business-world-people/:id/:name/',
    component: InspirationDetailPageComponent,
  },
  {
    path: 'crypto-business-world-people/:id/:name',
    component: InspirationDetailPageComponent,
  },
  {
    path: 'crypto-business-world-learn/:id/:name/',
    component: LearnCategoryDetailsComponent,
  },
  {
    path: 'crypto-business-world-learn/:id/:name',
    component: LearnCategoryDetailsComponent,
  },
  /* auth routes starts */
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: MainDashboardComponent,
        data: {
          userType: [
            'SuperAdmin',
            'ExternalAnalyst',
            'CBWWriter',
            'ContentApprover',
          ],
        },
      },
      {
        path: 'create-post',
        component: CreatePostComponent,
        data: {
          userType: [
            'SuperAdmin',
            'ExternalAnalyst',
            'CBWWriter',
            'ContentApprover',
          ],
        },
      },
      {
        path: 'create-video',
        component: CreatePostComponent,
        data: { userType: ['SuperAdmin', 'CBWWriter', 'ContentApprover'] },
      },
      {
        path: 'create-interview',
        component: CreatePostComponent,
        data: { userType: ['SuperAdmin', 'CBWWriter', 'ContentApprover'] },
      },
      {
        path: 'post-list',
        component: PostListComponent,
        data: {
          userType: [
            'SuperAdmin',
            'CBWWriter',
            'ExternalAnalyst',
            'ContentApprover',
          ],
        },
      },

      {
        path: 'post-list/:mode/:id',
        component: CreatePostComponent,
        data: {
          userType: [
            'SuperAdmin',
            'ExternalAnalyst',
            'CBWWriter',
            'ContentApprover',
          ],
        },
      },
      {
        path: 'video-list',
        component: PostListComponent,
        data: { userType: ['SuperAdmin', 'CBWWriter', 'ContentApprover'] },
      },
      {
        path: 'create-video/:mode/:id',
        component: CreatePostComponent,
        data: { userType: ['SuperAdmin', 'CBWWriter', 'ContentApprover'] },
      },
      {
        path: 'interview-list',
        component: InterviewListComponent,
        data: { userType: ['SuperAdmin', 'CBWWriter', 'ContentApprover'] },
      },
      {
        path: 'create-interview/:mode/:id',
        component: CreatePostComponent,
        data: {
          userType: [
            'SuperAdmin',
            'ExternalAnalyst',
            'CBWWriter',
            'ContentApprover',
          ],
        },
      },
      {
        path: 'press-release/:mode/:id',
        component: CreatePostComponent,
        data: {
          userType: [
            'SuperAdmin',
            'ExternalAnalyst',
            'CBWWriter',
            'ContentApprover',
          ],
        },
      },
      {
        path: 'press-release',
        component: CreatePostComponent,
        data: { userType: ['SuperAdmin', 'CBWWriter', 'ContentApprover'] },
      },
      {
        path: 'press-release-list',
        component: PressReleaseListComponent,
        data: { userType: ['SuperAdmin', 'CBWWriter', 'ContentApprover'] },
      },
      {
        path: 'faq',
        component: FaqComponent,
        data: { userType: ['SuperAdmin'] },
      },
      {
        path: 'faq-list',
        component: FaqListComponent,
        data: { userType: ['SuperAdmin'] },
      },
      {
        path: 'youtube-videos',
        component: YotubeVideoLinksComponent,
        data: { userType: ['SuperAdmin', 'CBWWriter', 'ContentApprover'] },
      },
      {
        path: 'people-list',
        component: CreatePostComponent,
        data: { userType: ['SuperAdmin', 'CBWWriter', 'ContentApprover'] },
      },
      {
        path: 'events-list',
        component: CreatePostComponent,
        data: { userType: ['SuperAdmin', 'CBWWriter', 'ContentApprover'] },
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        data: { userType: ['SuperAdmin', 'ExternalAnalyst', 'CBWWriter'] },
      },
      {
        path: 'user-profile/:mode/:userid',
        component: UserProfileComponent,
        data: { userType: ['SuperAdmin', 'ExternalAnalyst', 'CBWWriter'] },
      },

      //////SUPER SUPERADMIN SPECIFIC//////
      {
        path: 'user-list',
        component: UserListComponent,
        data: { userType: ['SuperAdmin'] },
      },
      {
        path: 'recommended-news',
        component: RecommendedNewsComponent,
        data: { userType: ['SuperAdmin'] },
      },

      //wild card
      { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
    ],
  },
  /* auth ends here */
  {
    path: 'crypto-business-world-press-release/',
    component: PressReleasePageComponent,
  },
  {
    path: 'crypto-business-world-press-release',
    component: PressReleasePageComponent,
  },
  {
    path: 'crypto-business-world-market/',
    component: AllArticlesComponent,
  },
  {
    path: 'crypto-business-world-market',
    component: AllArticlesComponent,
  },
  {
    path: 'crypto-business-editors-choice/',
    component: EditorsChoiceComponent,
  },
  {
    path: 'crypto-business-editors-choice',
    component: EditorsChoiceComponent,
  },
  {
    path: 'crypto-business-hot-story/',
    component: HotStoriesComponent,
  },
  {
    path: 'crypto-business-hot-story',
    component: HotStoriesComponent,
  },
  {
    path: 'cbw-Article/Analysis/',
    component: FeaturedArticlesComponent,
  },
  {
    path: 'cbw-Article/Analysis',
    component: FeaturedArticlesComponent,
  },
  {
    path: 'cbw-Article/Trending/',
    component: TrendingArticlesComponent,
  },
  {
    path: 'cbw-Article/Trending',
    component: TrendingArticlesComponent,
  },
  {
    path: 'crypto-business-world-car-composite-index/',
    component: CarCompositeIndexComponent,
  },
  {
    path: 'crypto-business-world-car-composite-index',
    component: CarCompositeIndexComponent,
  },
  {
    path: 'crypto-business-world-car-issuers-basic-ratings/',
    component: BasicRatingComponent,
  },
  {
    path: 'crypto-business-world-car-issuers-basic-ratings',
    component: BasicRatingComponent,
  },
  {
    path: 'crypto-business-world-index-methodology/',
    component: IndexMethodologyComponent,
  },
  {
    path: 'crypto-business-world-index-methodology',
    component: IndexMethodologyComponent,
  },
  {
    path: 'crypto-business-world-index-governance/',
    component: IndexGovernanceComponent,
  },
  {
    path: 'crypto-business-world-index-governance',
    component: IndexGovernanceComponent,
  },
  {
    path: 'Crypto-Business-World-How-to-become-EA/',
    component: HowToBecomeEaComponent,
  },
  {
    path: 'Crypto-Business-World-How-to-become-EA',
    component: HowToBecomeEaComponent,
  },
  {
    path: 'global-crypto-events/',
    component: EventsComponent,
  },
  {
    path: 'global-crypto-events',
    component: EventsComponent,
  },
  {
    path: 'crypto-business-world-video/',
    component: VideosComponent,
  },
  {
    path: 'crypto-business-world-video',
    component: VideosComponent,
  },
  {
    path: 'Crypto-Business-World-learn-categories/',
    component: LearnComponent,
  },
  {
    path: 'Crypto-Business-World-learn-categories',
    component: LearnComponent,
  },
  {
    path: 'crypto-business-world-recommended-news/',
    component: RecommendedNewsPageComponent,
  },
  {
    path: 'crypto-business-world-recommended-news',
    component: RecommendedNewsPageComponent,
  },
  {
    path: 'cbw-Article/BreakingNews/',
    component: BreakingNewsPageComponent,
  },
  {
    path: 'cbw-Article/BreakingNews',
    component: BreakingNewsPageComponent,
  },
  {
    path: 'cbw-Article/DailyNews/',
    component: DailyNewsPageComponent,
  },
  {
    path: 'cbw-Article/DailyNews',
    component: DailyNewsPageComponent,
  },
  {
    path: 'crypto-business-world-inspirations/',
    component: InspirationsComponent,
  },
  {
    path: 'crypto-business-world-inspirations',
    component: InspirationsComponent,
  },
  {
    path: 'Home/PrivacyPolicy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'Home/TermOfUse',
    component: TermsOfUseComponent,
  },
  {
    path: 'Home/Disclaimer',
    component: DisclaimerComponent,
  },
  {
    path: '**',
    component: HomeComponent,
  },
  // Add a wildcard route to redirect to the home page
  // { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
