import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-article-detail-all-user',
  templateUrl: './article-detail-all-user.component.html',
  styleUrls: ['./article-detail-all-user.component.scss'],
})
export class ArticleDetailAllUserComponent {
  constructor(private activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this._checkModeInit();
  }

  _checkModeInit() {
    this.activeRoute.params.subscribe((param) => {
      console.log(param);
    });
  }
}
