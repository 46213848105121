import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent {
  isAllEvent: boolean = true;
  regionIndex: number = 0;
  allEventList: any = [];

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.getAllEvents();
  }

  redirectToEventLink(link: string) {
    window.open(link, '_blank');
  }

  getAllEvents() {
    this.authService.getAllEvents().subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          if (element.status == 'approved') {
            this.allEventList.push(element);
          }
        });
        console.log('this.allEventList: ', this.allEventList[0].eventVenue);
        // console.log('Response: ', response);
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
