<div class="editors-choice">
  <span class="editors-choice__head">Editors Choice</span>
  <div class="editors-choice__article" *ngFor="let data of editorsChoiceList">
    <img *ngIf="data.categoryId != 10 && data.categoryId != 13" class="editors-choice__article__img" src="{{ data.postImageURL }}" alt="{{data.imageAlt??''}}" />
    <img *ngIf="data.categoryId == 10 || data.categoryId == 13" class="editors-choice__article__img" src="{{ data.postThumbnailURL }}" alt="{{data.imageAlt??''}}" />
    <span class="editors-choice__article__content">
      <a
        routerLink="/articles-preview/{{ data.postTitle | urlPrettier }}"
        class="editors-choice__article__content__text"
        >{{ data.postTitle }}</a
      >
      <span class="editors-choice__article__content__date">{{
        data.createdDateTime  | date : "dd-MM-yyyy"
      }}</span>
    </span>
  </div>
</div>
