<div class="privacy-policy container">
    <h2 class="sub-page-title text-center mb-4">Privacy Policy</h2>

    <div class="row">
        <h4 class="text-center mb-3"><b>Effective Date: August 01, 2023</b></h4>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p>We at CryptoBusinessWorld.com (“we”, “us”, “Website” or "Crypto Business World" or “CBW”) respect your privacy. This Privacy Policy (“Policy”) applies to personal and non-personal customer information obtained by Crypto Business World through our Website, News or any other source to which this Policy is linked or referenced.</p>
            <p>The Policy describes the types of information we obtain about visitors of our Website ("You", "Your") and how we use said information. We also describe the measures we take to protect the security of the information and how you can contact us about our privacy practices.</p>
            <ul class="tick">
                <h4><b>1. Types of information we obtain</b></h4>
                <li>
                    <ul class="tick">
                        Non-personal Information:
                        <br>
                        <li> (a) When you visit CryptoBusinessWorld.com our system obtains information regarding your use of our Website through automated means, including, but not limited to, cookies, web server logs, JavaScript and similar technologies.</li>
                        <li> (b) The information we obtain in this manner or which may be provided by the mobile devices you use with the Website may include the time of visit, pages visited, and time spent on each page of the webpages; referring site details (such as the name of the website, the name of the searching system, the name of the social network etc.); the type of web browser; browser language; type of operating system; flash version, JavaScript support, screen resolution, and screen color processing ability; network location and IP address; downloading files; clicks on external links as well as video on the Website; form submission errors; scroll depth; interactions with Website widgets; geolocation information.</li>
                    </ul>
                </li>
                <li>
                    <ul class="tick">

                        Personal Information:
                        <br>
                        <li>
                            (a) In a limited number of cases, we may collect personal information that you voluntarily provide to us, such as your name and email. We do not collect and process your personal information without your consent.
                        </li>
                        <li> (b) By voluntarily providing us with personal information, including, without limitation, by using the Website, you expressly and irrevocably agree to our use of your personal information in accordance with this Privacy Policy. If you provide personal information to us, you acknowledge and agree that such personal information may be transferred from your current location to the servers of the Website and to the servers of authorized third parties.</li>
                    </ul>
                </li>
                <li>
                    <ul class="tick">
                        Additional Information:
                        <br>
                        <li> (a) From time to time we reserve the right to obtain information about legal entities that visit our Website, through their representatives.</li>
                        <li> (b) We may obtain information about the website of a legal entity, the name of the legal entity, the contact information of the legal entity, the country of incorporation, and the link to the ICO of the legal entity (if applicable).</li>
                    </ul>
                </li>

                <div class="gap50"></div>
            </ul>
            <ul class="tick">
                <h4><b>2. How we collect information?</b></h4>
                <li>
                    We may collect personal identification information such as your email and name from you only if you voluntarily submit such information to us. You can always refuse to supply personal identification information. We may collect non-personal identification information about you whenever you interact with our Website.
                </li>
            </ul>
            <ul class="tick">
                <h4><b>3. How we use the information we obtain?</b></h4>
                <li>
                    <ul class="tick">

                        We use non-personal information for the following purposes:
                        <br>
                        <li>(a) to prevent you from seeing unnecessary advertisements or requiring you to re-enter the Website more than is necessary;</li>
                        <li>(b) to calculate the aggregate number of people visiting our Website and which parts of the Website are most popular;</li>
                        <li>(c) to improve the content of our web pages and to customize the content and layout of our pages;</li>
                        <li>(d) to deliver the information you request from the Website, such as an article, news, review, analysis etc;</li>
                    </ul>
                </li>
                <li>
                    If at any time you provide us with your personal information, we collect that information. Areas in which we may use your personal information include, but are not limited to, providing you with email newsletters, feedback and especially the ability to comment on articles, interactive forms. If you provide us with your email address, you may receive periodic promotional emails from us and carefully selected partners with information regarding special offers or services. You may also receive informational emails from us related to administrative notices regarding the operation of the Website. You may at any time withdraw your consent to receive electronic emails by sending a withdrawal notice to Crypto Business World or by pressing the dedicated button ("an unsubscribe button") in any email from Crypto Business World.
                </li>
                <li>
                    We also have the right to disclose your identity to any third party who is claiming that any content posted by you constitutes a violation of their intellectual property rights, or of their right to privacy.
                </li>
                <li>
                    You agree that we may share with and transfer your information that no longer identifies you to a third party who is a contracting party with us, usually our outsourced services' providers or advertising partners.
                </li>
            </ul>
            <ul class="tick">
                <h4><b>4. Cookies</b></h4>
                <li>
                    Cookies are files with a small amount of data that may include an anonymous unique identifier. Cookies are sent to your browser from the Website and stored on your computer's hard drive.
                </li>
                <li>
                    Like many websites, We use "cookies" to collect information. We use cookies on our Website to collect data about your visit and to allow you to navigate from page to page, count visits, and see which areas and features of our Website are popular. This helps us to gather feedback in order to constantly improve our Website and better serve our customers. Cookies do not allow us to gather any personal information about you and we do not generally store any personal information that you provided to us in your cookies.
                </li>
                <li>
                    You can control and/or delete cookies if you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being stored therein. If you do this, however, some services and functionalities of our Website may not work.
                </li>
            </ul>
            <ul class="tick">
                <h4><b>5. Data retention</b></h4>
                <li>
                    We will retain your information for as long as is needed to provide you services, or as required to fulfil our legal obligations. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                </li>
                <li>
                    By contacting us at CBW@CryptoAssetRating.com you can at any time obtain confirmation of whether or not personal data concerning you is being processed.
                </li>
                <li>
                    You have the right to access and export your information at any time by sending a request to CBW@CryptoAssetRating.com.
                </li>
            </ul>
            <ul class="tick">
                <h4><b>6. Privacy complaints</b></h4>
                <li>
                    We take privacy concerns seriously. If you believe that we have not complied with this Privacy Policy with respect to your information, you may contact us at CBW@CryptoAssetRating.com. In your message please describe in as much detail as possible the ways in which you believe that the Website’s Privacy Policy has not been complied with. We will investigate your complaint promptly and will reply to you within 30 days.
                </li>
            </ul>
            <ul class="tick">
                <h4><b>7. How we protect personal Information</b></h4>
                <li>
                    We maintain administrative, technical and physical safeguards designed to protect the personal information we have about you against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure or use.
                </li>
                <li>
                    Unfortunately, there is no such thing as completely secure data transmission or storage, so we cannot guarantee that our security will not be breached (by technical measures or through violation of our policies and procedures).
                </li>
                <li>
                    Without prejudice to our efforts on protection of your information nothing herein constitutes a warranty of the security of the Website, and you agree to transmit information at your own risk. Please note that the Website does not guarantee that your information may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or administrative safeguards.
                </li>
            </ul>

            <ul class="tick">
                <h4><b>8. Third-party links</b></h4>
                <li>
                    Occasionally, at our discretion, we may include or offer third-party products or services on the Website. These third-party providers have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked services. Nonetheless, we seek to protect the integrity of our Website and welcome any feedback about these services.
                </li>
            </ul>
            <ul class="tick">
                <h4><b>9. Analytics</b></h4>
                <li>
                    To determine how many persons visit our Website and to better understand the areas of greatest interest to our visitors, we reserve the right to have relationships with web analytics companies that compile this information for us. For further information, consult the privacy policies of the web analytics company we use: Google Inc. http://www.google.com/intl/en/privacy.html
                </li>
            </ul>
            <ul class="tick">
                <h4><b>10. Changes to the Privacy Policy</b></h4>
                <li>
                    This Privacy Policy may be updated periodically and without prior notice to you to reflect changes in our personal information policies. For significant changes, we will notify you by posting a prominent privacy policy on our Website indicating at the top of the Policy when it was most recently updated.
                </li>
            </ul>
            <ul class="tick">
                <h4><b>11. Children</b></h4>
                <li>
                    We do not intend to solicit or collect Personal Information from anyone under the the legal age of your country. If you are not of a legal age of your country, you shall not provide Personal Information on this Site.
                </li>
            </ul>
            <ul class="tick">
                <h4><b>12. Right to be forgotten</b></h4>
                <li>
                    If you want to request that we no longer use and process your information to provide you services, please write us at CBW@CryptoAssetRating.com. We will respond to your request within 30 days.
                </li>
            </ul>
            <ul class="tick">
                <h4><b>13. Law and harm</b></h4>
                <li>
                    Notwithstanding anything to the contrary in this Policy, we may preserve or disclose your information if we believe that it is reasonably necessary to comply with a law, regulation, legal process, or governmental request; to protect the safety of any person; to address fraud, security or technical issues; or to protect our or our visitors' rights or property.
                </li>
                <li>
                    Crypto Business World may share your personal information with law enforcement, data protection authorities, government officials, and other authorities when Crypto Business World is legally bound to do so or to protect the safety of visitors and Crypto Business World platform.
                </li>
            </ul>
            <ul class="tick">
                <h4><b>14. Contacting Us</b></h4>
                <li>
                    If you have any questions about this Privacy Policy, the practices of our Website, or your dealings with our Website, please contact us at CBW@CryptoAssetRating.com
                </li>
            </ul>
        </div>
    </div>
</div>
