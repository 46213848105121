<div class="new-home">
  <div class="editors-choice">
    <div class="col-8 editors-choice__left">
      <div class="bread-crumb">
        <p-breadcrumb class="max-w-full mb-4" [model]="items" [home]="home"></p-breadcrumb>
      </div>
      <div class="search">
        <p-dropdown class="select-publisher-dropdown" [options]="allUsersList" [(ngModel)]="selectedPublisher"
          optionLabel="name" placeholder="Select Publisher"></p-dropdown>
        <p-calendar class="p-calender-dropdown" [(ngModel)]="startDate" placeholder="Start Date"
          [showIcon]="true"></p-calendar>
        <p-calendar class="p-calender-dropdown" [(ngModel)]="endDate" placeholder="End Date"
          [showIcon]="true"></p-calendar>
      </div>
      <div class="editors-choice__search">
        <input class="editors-choice__search__input" [(ngModel)]="titleSearchValue"
          placeholder="Search Articles by Title" />
        <span class="editors-choice__search__action">
          <span class="editors-choice__search__search-btn" (click)="search(titleSearchValue, true)">Search</span>
          <span (click)="reset()" class="cursor-pointer ml-auto">
            <i class="fa fa-refresh"></i>
            <span>Reset</span>
          </span>
        </span>
      </div>
      <div class="editors-choice__left__main-content">
        <span class="editors-choice__left__main-content__head">Editor's Choice</span>
        <div class="orange-line"></div>
        <div class="editors-choice__left__main-content__articles" *ngFor="
            let data of editorsChoiceList
              | paginate
                : {
                    itemsPerPage: 10,
                    currentPage: page,
                    totalItems: totalItems
                  }
          ">
          <span class="editors-choice__left__main-content__articles__img">
            <img *ngIf="data.categoryId != 10 && data.categoryId != 13" alt="{{ data.imageAlt ?? 'cbw-img' }}"
              class="editors-choice__left__main-content__articles__img__img" src="{{ data.postImageURL }}" />
            <img *ngIf="data.categoryId == 10 || data.categoryId == 13" alt="{{ data.imageAlt ?? 'cbw-img' }}"
              class="editors-choice__left__main-content__articles__img__img" src="{{ data.postThumbnailURL }}" />
          </span>
          <div class="editors-choice__left__main-content__articles__content">
            <a routerLink="/articles-preview/{{ data.postTitle }}"
              class="editors-choice__left__main-content__articles__content__head">{{ data.postTitle }}</a>
            <span class="editors-choice__left__main-content__articles__content__text">{{ data.shortDescription }}
            </span>
            <span class="editors-choice__left__main-content__articles__content__details">
              <span class="editors-choice__left__main-content__articles__content__details__date">{{ data.createdDateTime | date : "dd-MM-yyyy"
                }}</span>
              <span class="editors-choice__left__main-content__articles__content__details__engagement">
                <span class="editors-choice__left__main-content__articles__content__details__engagement__likes">
                  <img alt="cbw-img" src="assets/images/like.png" />
                  <span
                    class="editors-choice__left__main-content__articles__content__details__engagement__likes__count">
                    {{ data.likeCount }}
                  </span>
                  <span class="editors-choice__left__main-content__articles__content__details__engagement__likes__text">
                    Like(s) |
                  </span>
                </span>
                <span class="editors-choice__left__main-content__articles__content__details__engagement__comments">
                  <img alt="cbw-img" src="assets/images/comments.png" />
                  <span
                    class="editors-choice__left__main-content__articles__content__details__engagement__comments__count">
                    {{ data.commentCount }}
                  </span>
                  <span
                    class="editors-choice__left__main-content__articles__content__details__engagement__comments__text">
                    Comment(s)</span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div *ngIf="editorsChoiceList.length != 0" class="pagination">
          <pagination-controls (pageChange)="pageChange($event)" [maxSize]="5" previousLabel=""
            nextLabel=""></pagination-controls>
        </div>
      </div>
    </div>
    <div class="col-4 editors-choice__right">
      <app-hot-stories-comp></app-hot-stories-comp>
    </div>
  </div>
</div>
