import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostListService {

  $post:BehaviorSubject<any> = new BehaviorSubject({});

  api= environment.apiURL;
  constructor(private http: HttpClient) { }

  getPosts(query: any):Observable<any>{
    console.log(query)
    let params = new HttpParams()
    // .set('isEditorChoice', 'false')
    // .set('isHotStory', 'false')
    .set('pageNo',(query.page/10)+1).set('limit',query.row)
    .set('searchByTitle',query.searchKey??'')
    .set('publisherId',query.publisherId);

    
    if(query.selectedfilter !='all' && query.selectedfilter != undefined){
      params=params.set('status',query.selectedfilter)
      params=params.set('allStatus',false)
    }else{
      params=params.set('allStatus',true)
    }

    if(query.categoryId){ //for video
      //get only the video post by the user (by category and the publisherid)
      params=params.set('searchByTitle',query.searchKey??'')
      return this.http.get(`${this.api}/api/post/getPosts/${query.categoryId}`,{params})
    }else{
      params=params.set('searchTitle',query.searchKey??'')
      //get all the posts of user by publisherId (including videos and other) and then filter out the rest
      return this.http.get(`${this.api}/api/post/getAllPosts`,{params})
    }
  }

  getAllPosts(query: any):Observable<any>{
    console.log('getting all post by admin',query)
    console.log(query)
    let params = new HttpParams()
    // .set('allStatus',true)
    // .set('isEditorChoice', 'false')
    .set('searchTitle',query.searchKey??'')
    // .set('isHotStory', 'false')
    .set('pageNo',(query.page/10)+1).set('limit',query.row)
    

    if(query.selectedfilter !='all' && query.selectedfilter != undefined){
      params=params.set('status',query.selectedfilter)
      params=params.set('allStatus',false)
    }else{
      params=params.set('allStatus',true)
    }

    if(query.categoryId){ //for video
      params=params.set('categoryId',query.categoryId);
      // get only all the video post for admin
      return this.http.get(`${this.api}/api/post/getAllPosts`,{params})
    }else{
      // get all the posts for admin and then filter out reset
      return this.http.get(`${this.api}/api/post/getAllPosts`,{params})
    }
  }

  getCategoryById(id: string|number): Observable<any>{
    return this.http.get(`${this.api}/api/category/${id}`)
  }

  getCategoryByName(name: string|number): Observable<any>{
    return this.http.get(`${this.api}/api/category/name/${name}`)
  }

  updatePostStatus(postid:any,query:any):Observable<any>{
    console.log('in service',query);
    const params = new HttpParams()
    .set('adminId',query.adminId)
    .set('status',query.status)
    .set('remarks',query.remarks);
    return this.http.put(`${this.api}/api/postupdatePostStatusdByAdmin/${postid}/`,{},{params})
  }


  getPostByCategory(categoryId: string|number): Observable<any>{
    return this.http.get(`${this.api}/api/post/getPosts/${categoryId}`)
  }

  getPostByUserId(userId: string|number,status:any): Observable<any>{
    const params = new HttpParams()
    .set('status',status)
    return this.http.get(`${this.api}/api/post/getPostByUserId/${userId}`,{params})
  }

  getUserById(userId: string|number): Observable<any>{
    const params = new HttpParams()
    .set('status','all')
    return this.http.get(`${this.api}/api/user/getUsersByRoleId/${userId}`,{params})
  }
  getAllUser(): Observable<any>{
    
    return this.http.get(`${this.api}/api/user/getAllUsers/`)
  }

  disablePost(id:any): Observable<any>{
    return this.http.put(`${this.api}/api/post/disablePostById/${id}`,{})
  }
}
