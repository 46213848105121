<div class="hot-stories">
  <span class="hot-stories__head">Hot Stories</span>
  <div class="hot-stories__article" *ngFor="let data of hotStoriesList">
    <img *ngIf="data.categoryId != 10 && data.categoryId != 13" alt="{{data.imageAlt??'cbw-img'}}" class="hot-stories__article__img" src="{{ data.postImageURL }}" />
    <img *ngIf="data.categoryId == 10 || data.categoryId == 13" alt="{{data.imageAlt??'cbw-img'}}" class="hot-stories__article__img" src="{{ data.postThumbnailURL }}" />
    <span class="hot-stories__article__content">
      <a
        routerLink="/articles-preview/{{ data.postTitle | urlPrettier }}"
        class="hot-stories__article__content__text"
        >{{ data.postTitle }}</a
      >
      <span class="hot-stories__article__content__date">{{
        data.createdDateTime | date : "dd-MM-yyyy"
      }}</span>
    </span>
  </div>
</div>
