import { Component } from '@angular/core';
import { ActivatedRoute, ResolveStart, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CBWUI';
  isAuthSide:boolean=false;
  pageLoaded:boolean =false;

  constructor(private activeRoute: ActivatedRoute,
    private route: Router,
    private authService: AuthService) {
  }

  ngOnInit(){
    this._checkRoutePath();
    this.authService.hasPageLoaded.subscribe(res => {
      this.pageLoaded = res;
    })

    // Redirection logic for HTTP to HTTPS
    if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
      const newUrl = `https://${window.location.host}${window.location.pathname}${window.location.search}`;
      window.location.href = newUrl;
    }

    // Cookie consent screen
    document.addEventListener('DOMContentLoaded', function () {
      const cookiesPopup: HTMLElement | null = document.getElementById('cookies-popup');
      const acceptBtn: HTMLElement | null = document.getElementById('accept-all-btn');

      // Function to get a cookie by name
      function getCookie(name: any) {
        const value = `; ${document.cookie}`;
        const parts: any = value.split(`; ${name}=`);
        if (parts.length === 2) return parts?.pop().split(';').shift();
      }

      // Function to set a cookie
      function setCookie(name: any, value: any) {
        var d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = `${name}=${value}; ${expires} path=/`;
      }

      // Check if user already accepted cookies
      const acceptedCookies = getCookie('acceptedCookies');

      console.log(acceptedCookies,cookiesPopup,acceptBtn)
      if (!acceptedCookies && cookiesPopup) {
        cookiesPopup.style.display = 'block';
      }

      if (acceptBtn && cookiesPopup) {
        acceptBtn.addEventListener('click', function () {
          // Set a cookie to remember user's choice (with no expiration date)
          setCookie('acceptedCookies', 'true');

          cookiesPopup.style.display = 'none';
        });
      }
    });

    // Get the current URL
    let currentUrl = this.route.url;
    const fullUrl = window.location.href;
    currentUrl = fullUrl.replace(/^.*\/\/[^/]+/, '');

    if (currentUrl.includes('/')) {
      currentUrl = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
      //console.log('current url is:', currentUrl);
    }
    this.updateTags(currentUrl);
  }

  private updateTags(currentUrl: string) {
    const originalTitle = document.title;
    const newTitle = `${originalTitle} | ${currentUrl}`;
    document.title = newTitle;

    // Update the meta description tag using plain JavaScript
    const metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      const originalDescription = metaDescriptionTag.getAttribute('content');
      const newDescription = `${originalDescription} | ${currentUrl}`;
      metaDescriptionTag.setAttribute('content', newDescription);
    }

    if(currentUrl === '/' || currentUrl === '') { // Home page only
      document.title = `Crypto Business World: Your Source for Timely Crypto News`;
      if(metaDescriptionTag) metaDescriptionTag.setAttribute('content', 'Crypto Business World provides breaking news, analysis, and comprehensive coverage of digital assets and decentralized technologies.');
    }

    // Update the cannonical url
    const cannonicalUrl = document.querySelector('link[rel="canonical"]');
    if (cannonicalUrl) {
      const cannonicalUrlhref = cannonicalUrl.getAttribute('href');
      cannonicalUrl.setAttribute('href', `https://cryptobusinessworld.com/${currentUrl}`);
    }

    // add a dummy html in last of bidy for seo different content
    // Create a new div element
    const newDiv = document.createElement('div');

    // Add a class name to the new div
    newDiv.className = 'page-name-disabled';

    // Add content or attributes to the div if needed
    newDiv.textContent = currentUrl;

    // Append the new div to the end of the body
    document.body.appendChild(newDiv);

    setTimeout(()=>{
      // Get the existing div with id "disclaimer-content"
      const disclaimerDiv = document.getElementById('disclaimer-content');
      //console.log(disclaimerDiv)

      // Append the new div to the "disclaimer-content" div
      if (disclaimerDiv) {
        disclaimerDiv.appendChild(newDiv);
      }
    }, 300)
  }

  _checkRoutePath(){
    this.route.events.subscribe(res => {
      if(res instanceof ResolveStart)
        this.isAuthSide = res['url'].split('/').includes('dashboard') ||
        res['url'].split('/').includes('login') || res['url'].split('/').includes('register')
    })
  }
}
