<div class="login-form-container">
  <div class="welcome-banner">
    <div class="welcome-banner-text">
      <h5>Welcome to</h5>
      <h3>Crypto Business World</h3>

      <p>
        Crypto Business World (CBW) is a high-quality content platform focused
        on the crypto market. We have various content categories such as
        Analysis, Articles, News, Reports, Interviews, Press Release, Videos,
        Pod case, Case Studies, and webinars. CBW is supported by the in-house
        team of content creators and selected group of crowd sourced content
        creator.
      </p>
    </div>
    <div [formGroup]="loginForm" class="car-login-card">
      <div class="car-login-card-form">
        <img
          class="car-logo"
          src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/new-cbw.png"
          alt="car-logo"
        />
        <div class="form-floating">
          <input
            type="email"
            class="form-control"
            id="floatingInput"
            formControlName="email"
            placeholder="User Name"
          />
          <label for="floatingInput">User Name</label>
        </div>
        <div class="form-floating">
          <input
            type="{{ passwordType }}"
            class="form-control"
            id="floatingPassword"
            formControlName="password"
            placeholder="Password"
          />
          <img
            *ngIf="passwordType == 'password'"
            (click)="passwordType = 'text'"
            src="assets/images/hide-eye.png"
            class="password-eyes"
          />
          <img
            *ngIf="passwordType == 'text'"
            (click)="passwordType = 'password'"
            src="assets/images/eye.png"
            class="password-eyes"
          />
          <label for="floatingPassword">Password</label>
        </div>

        <div
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
          *ngIf="invalidUser"
        >
          Invalid Username or Password.
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            (click)="invalidUser = false"
          ></button>
        </div>
        <hr />

        <div class="car-login-card-controls">
          <a href="javascript:void()" (click)="forgotPassword = true"
            >Forgot Password?</a
          >
          <button
            type="button"
            class="btn btn-warning b-rounded"
            [disabled]="loginForm.invalid"
            (click)="login()"
          >
            Sign In
          </button>
        </div>

        <!-- <div class="car-remember-check">
                  <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                      <label class="form-check-label" for="defaultCheck1">
                        Remember me for this application
                      </label>
                    </div>
              </div> -->
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Forgot Password"
  [(visible)]="forgotPassword"
  [style]="{ width: '50vw', height: '300px' }"
  class="p-d-flex p-flex-column p-jc-center p-ai-center"
>
  <div class="dialog-content flex flex-column align-items-center">
    <span class="mb-3">Please enter your registered email address to reset your password:</span>
    <input
      type="email"
      placeholder="Email"
      #email
      class="p-inputtext w-full mb-3"
    />
    <p-button label="Reset Password"
     (click)="sendEmailResetMail(email)"
      styleClass="p-button-sm mt-3"></p-button>
  </div>
</p-dialog>
