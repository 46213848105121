
<p-card class="post-card">
  <!-- <div class="container-fluid"> -->
    <div class="row">
        <div class="col">
            <h3>Press Release List</h3>

            <p-table #dt [value]="pressReleaseList" [scrollable]="true" scrollDirection="horizontal"
            [style]="{ 'width': 'calc(100vw - 310px)' }" [(selection)]="selectedPosts" dataKey="postId" [responsive]="true" [resizableColumns]="true"
             responsiveLayout="stack" [breakpoint]="'960px'" [rows]="10" [lazy]="true"
             (onLazyLoad)="getPressReleaseListData($event)" [totalRecords]="totalRecords"
                [paginator]="true" [rowsPerPageOptions]="[10, 20, 50, 100, 200, 300, 500]"
                [globalFilterFields]="['postTitle','createdDateTime','postTags','likeCount','commentCount']"
                styleClass=" p-datatable-gridlines p-datatable-striped p-datatable-sm" 
                [rowHover]="true">
                <ng-template pTemplate="caption">
                    <div class="flex p-fluid justify-content-between">
                        <div class="flex gap-2 align-items-center">
                            <div class="flex flex-column md:flex-row md:justify-content-between">
                                <p-dropdown [options]="filterBy" placeholder="" [(ngModel)]="selectedFilter" (onChange)="onSelectionChange($event)"
                                    styleClass="p-inputtext-sm mb-2 md:mb-0 "></p-dropdown>
                            </div>
                            <app-approve-reject *ngIf="userType=='SuperAdmin'" (approveReject)="multipleSelectionReview($event)"  [buttonVisible]="'both'"></app-approve-reject>

                        </div>
                        <div class="">
                            <!-- <span class="p-input-icon-left ml-auto">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" class="p-inputtext-sm" #textInput
                                    (input)="dt.filterGlobal(textInput.value, 'contains')" placeholder="Search keyword" />
                            </span> -->
                        </div>
                    </div>
                    <app-approve-reject *ngIf="userType=='Admin'"></app-approve-reject>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 4rem" *ngIf="userType=='SuperAdmin'">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>

                        <th></th>
                        <th [pSortableColumn]="'postTitle'">Title
                            <p-sortIcon [field]="'postTitle'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'createdDateTime'">Date
                            <p-sortIcon [field]="'createdDateTime'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'postTags'">Post Tag
                            <p-sortIcon [field]="'postTags'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'likeCount'">Likes Count
                            <p-sortIcon [field]="'likeCount'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'commentCount'">Comments Count
                            <!-- <p-sortIcon [field]="'commentCount'"></p-sortIcon> -->
                        </th>
                        <th [pSortableColumn]="'remarks'">Remarks
                        </th>

                        <!-- <th></th> -->
                        <th *ngIf="userType=='SuperAdmin'">Created By</th>
                        <th *ngIf="userType=='SuperAdmin' || userType == 'ContentApprover'">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" (dblclick)="editRecord(rowData.postId)">
                        
                        
                        <td *ngIf="userType=='SuperAdmin'">
                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                        </td>

                        <td class="text-center">
                            <a (click)="deleteRecord(rowData.postId)" pTooltip="Delete">
                                <i class="pi pi-trash"></i>
                            </a>
                        </td>

                        <td>
                          <span class="p-column-postTitle"></span>
                          <a routerLink="/dashboard/post-list/edit/{{rowData.postId}}" class="post-list-title">
                            {{rowData?.postTitle.substring(0,45).concat('...')}}
                          </a>
                        </td>
                        <td><span class="p-column-createdDateTime"></span>{{rowData?.createdDateTime | date: 'dd/MM/yyyy'}}</td>
                        <td><span class="p-column-postTags"></span>{{rowData?.postTags}}</td>
                        <td><span class="p-column-likeCount"></span>{{rowData?.likeCount}}</td>
                        <td><span class="p-column-commentCount"></span>{{rowData?.commentCount}}</td>
                        <td><span class="p-column-remarks"></span>{{rowData?.remarks}}</td>
                        <!-- <td>Preview</td> -->
                        <td *ngIf="userType=='SuperAdmin'"><span class="p-column-createdBy"></span>{{rowData?.user?.firstName}} {{rowData?.user?.lastName}}</td>
                        <td class="" *ngIf="userType=='SuperAdmin' || userType == 'ContentApprover'">
							<!-- {{rowData?.status}} -->
							<div class="flex gap-2 align-items-center">
								<ng-container [ngSwitch]="rowData?.status">
									<ng-template ngSwitchCase="approved">
										<span>Approved</span>
										<app-approve-reject (approveReject)="catchReview($event,rowData.postId)" [buttonVisible]="'reject'"></app-approve-reject>
									</ng-template>
									<ng-template ngSwitchCase="rejected">
										<span>Rejected</span>
										<app-approve-reject (approveReject)="catchReview($event,rowData.postId)" [buttonVisible]="'approve'"></app-approve-reject>
									</ng-template>
									<ng-template ngSwitchCase="drafted">
										<span>Drafted</span>
										<!-- <app-approve-reject (approveReject)="catchReview($event,rowData.postId)" [buttonVisible]="'approve'"></app-approve-reject> -->
									</ng-template>
									<ng-template ngSwitchCase="pendingApproval">
										<span>Pending Approval</span>
										<app-approve-reject (approveReject)="catchReview($event,rowData.postId)" [buttonVisible]="'approve'"></app-approve-reject>
									</ng-template>

								</ng-container>
							</div>

						</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">No Records found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
<!-- </div>   -->
</p-card>

<p-dialog header="Rejection Reason" [position]="'right'" [(visible)]="rejectionReasonDialog" [modal]="true" [style]="{ width: '35vw' }" [draggable]="true" [resizable]="true">
	<div class="m-0 p-fluid">
	  <textarea rows="2" pInputTextarea [(ngModel)]="rejectionReasonMessage" placeholder="Specify rejection reason.."></textarea>
	  <p class="" *ngIf="rejectionMessageEmpty">Please enter the rejection message.</p>
	  <app-approve-reject [buttonVisible]="'reject'" (approveReject)="rejectPost()"></app-approve-reject>
	</div>
</p-dialog>
