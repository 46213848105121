import { Component } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

  faqCategories=[
    {label:'Tokenization', value:'Tokenization'},
    {label:'Investors', value:'Investors'},
    {label:'General', value:'General'},
  ]
  constructor(){}
}
