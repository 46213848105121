import { Component } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  title = 'CBWUI';
  apiURL = environment.apiURL;
  pageName = '';

  constructor(private router: Router, private activeRoute: ActivatedRoute) {}

  ngOnInit(){
    console.log('homepage loaded!')
    // Get the current URL
    // let currentUrl = this.router.url;
    // console.log('Current URL:', currentUrl);

    // if (currentUrl.includes('/')) {
    //   currentUrl = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
    // }
    // this.pageName = currentUrl;
    //this.updateTags(currentUrl);
  }

  private updateTags(segment: string) {
    const originalTitle = document.title;
    const newTitle = `${originalTitle} | ${segment}`;
    document.title = newTitle;

    // Update the meta description tag using plain JavaScript
    const metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      const originalDescription = metaDescriptionTag.getAttribute('content');
      const newDescription = `${originalDescription} | ${segment}`;
      metaDescriptionTag.setAttribute('content', newDescription);
    }
  }
}
