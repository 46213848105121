<div class="car-composite-index">
  <img
    class="car-composite-index--img"
    src="./assets/images/index-banner.png"
  />
  <div class="car-composite-index--main">
    <div class="car-composite-index--main--top">
      <img
        class="car-composite-index--main--top--img"
        src="./assets/images/Index-icon.png"
      />
      <span class="car-composite-index--main--top--title"
        >CAR Composite Index</span
      >
      <span class="car-composite-index--main--top--description">
        Indices allow investors to gain an insight into the performance of an
        asset or a segment of that index class. Indices are used for various
        financial instruments and to benchmark the performance designed to
        replicate the performance of a given asset class.
      </span>
      <span class="car-composite-index--main--top--description">
        At CAR, Index is managed by CAR's index committee and steering
        committee. These indexes are based on weighted market capitalization.
      </span>
    </div>
    <div class="car-composite-index--main--bottom">
      <img
        class="car-composite-index--main--bottom--img"
        src="./assets/images/progress_one.png"
      />
      <h3>Data Loading.....</h3>
      <h4>Index details are in process. We appreciate your patience</h4>
    </div>
  </div>
</div>
