<div class="disclaimer">
    <h2 class="sub-page-title text-center mb-4">Legal <span class="color-orange">Disclaimer</span></h2>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p>Crypto Business World (CBW) strives to ensure that all information contained on pages hosted by CryptoBusinessWorld.com is correct and up to date. We cannot, however, accept responsibility for the content of external websites linked to through CryptoBusinessWorld.com. Third party content is accessed at the user’s own risk. </p>
            <p>
                Crypto Business World is provided on "as is" basis and without any warranty whatsoever. We and our affiliates and third party service providers disclaim, to the maximum extent permitted by law, any and all warranties, express or implied, including (but not limited to) implied warranties of merchantability, fitness for a particular purpose, and non-infringement of proprietary rights, or any other warranty, condition, guarantee or representation, whether oral or electronic. You are solely responsible for any damage to your computer or mobile device, loss of use, or loss of your user content.
            </p>
            <p>
                All the things you do and all the information you submit or post to Crypto Business World remain your responsibility. You will not hold us legally liable for any of your user content or actions that infringe the law or the rights of a third party or person in any way.
            </p>
            <p>
                Our goal is to serve both your and community interests, so if you have an issue or dispute, you agree to raise it and try to resolve it with us informally. You can contact us with feedback and concerns here or by emailing us at CBW@CryptoAssetRating.com
            </p>
            <p>CryptoBusinessWorld.com cannot accept responsibility for any loss or inconvenience caused by reliance on any material contained in this site.</p>
            <p>
                Please note that despite the nature of much of the material created and hosted on this website, CryptoBusinessWorld.com is not a financial reference resource and the opinions of authors and other contributors are their own and should not be taken as financial advice. If you require advice of this sort, Crypto Business World strongly recommends contacting a qualified industry professional.
            </p>
            <p>All materials on this site are for informational purposes only. None of the material should be interpreted as investment advice.</p>
            <p>
                By using CryptoBusinessWorld.com you agree to hold Crypto Business World, its affiliates, officers, directors, employees, agents, and third party service providers harmless from and defend them against any claims, costs, damages, losses, expenses, and any other liabilities, including attorneys’ fees and costs, arising out of or related to your access to or use of Crypto Business World, your violation of this user agreement, and/or your violation of the rights of any third party or person.
                We will not be liable for any special, consequential, indirect, incidental, punitive, reliance, or exemplary damages, whether in tort, contract, or any other legal theory, arising out of or in any way connected with this agreement or your use of or attempt to use Crypto Business World, including (but not limited to) damages for loss of profits, goodwill, use, or data. This limitation on liability shall not be affected even if we have been advised of the possibility of such damages. Some states do not allow for the exclusion of implied warranties or the limitation or exclusion of liability for incidental or consequential damages, so the above exclusions may not apply to you. You may have other rights that vary from state to state.
            </p>
            <p>You agree to release us, our affiliates, and third-party service providers, and each associated director, employee, agents, and officers, from claims, demands and damages (actual and consequential), of every kind and nature, known and unknown, disclosed or undisclosed, arising out of or in any way connected to your use of Crypto Business World.</p>
            <p>Any claim or dispute between you and us arising out of or relating to this user agreement, in whole or in part, shall be governed by the laws of United States of America.</p>
        </div>
    </div>
</div>
