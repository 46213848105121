import { Component } from '@angular/core';

@Component({
  selector: 'app-u-block-ui',
  templateUrl: './u-block-ui.component.html',
  styleUrls: ['./u-block-ui.component.scss']
})
export class UBlockUiComponent {

}
