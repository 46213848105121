<div class="latest-breaking-news">
  <span class="latest-breaking-news__head">Latest Breaking News</span>
  <div
    class="latest-breaking-news__article"
    *ngFor="let data of latestBreakingNewsList"
  >
    <img
      class="latest-breaking-news__article__img"
      src="{{ data.postImageURL }}"
    />
    <span class="latest-breaking-news__article__content">
      <a
        routerLink="/articles-preview/{{ data.postTitle | urlPrettier }}"
        class="latest-breaking-news__article__content__text"
        >{{ data.postTitle }}</a
      >
      <span class="latest-breaking-news__article__content__date">{{
        data.createdDateTime | date : "dd-MM-yyyy"
      }}</span>
    </span>
  </div>
</div>
