import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserListService {
  api= environment.apiURL;
  constructor(private http: HttpClient) { }

  getUsersByRoleId(query: any):Observable<any>{
    return this.http.get(`${this.api}/api/user/getUsersByRoleId/${query.roleId}?status=${query.status ? query.status : 'all'}`)
  }

  getAllRolesId(): Observable<any>{
    return this.http.get(`${this.api}/api/role/`)
  }

  approveOrRejectUser(userId: number, adminUserId: number, status: string = 'approve', remarks: string = ''): Observable<any> {
    const url = `${this.api}/api/user/approveOrRejectuser/${userId}?adminUserId=${adminUserId}&status=${status}&remarks=${remarks}`;

    return this.http.put(url, {});
  }

  getUserDetails(userId: number): Observable<any> {
    return this.http.get(`${this.api}/api/user/getDetails/${userId}`);
  }

  getAllIndustries(): Observable<any> {
    return this.http.get(`${this.api}/api/industry/`);
  }

  getAllDomains(): Observable<any> {
    return this.http.get(`${this.api}/api/domain/`);
  }

  getAllDegree(): Observable<any> {
    return this.http.get(`${this.api}/api/degree/`);
  }

  getAllCourses(): Observable<any> {
    return this.http.get(`${this.api}/api/courseTypes/`);
  }

  getAllCountries(): Observable<any> {
    return this.http.get(`${this.api}/api/country/`);
  }

  getEducationDetailsByUserId(userId: number): Observable<any> {
    return this.http.get(`${this.api}/api/educationDetail/getEducationDetailsByUserId/${userId}`);
  }

  updateUserByUserId(userId: number, updatedUser: any): Observable<any> {
    return this.http.put(`${this.api}/api/user/updateUser/${userId}`, updatedUser);
  }

  createEducationalDetails(educationalDetails: any): Observable<any> {
    return this.http.post(`${this.api}/api/educationDetail/`, educationalDetails);
  }

  updateEducationalDetails(educationalDetailsId: number, educationalDetails: any): Observable<any> {
    return this.http.put(`${this.api}/api/educationDetail/${educationalDetailsId}`, educationalDetails);
  }

  getProfessionalDetailsByUserId(userId: number): Observable<any> {
    return this.http.get(`${this.api}/api/professionalDetail/getProfessionalDetailsByUserId/${userId}`);
  }

  createProfessionalDetails(professionalDetails: any): Observable<any> {
    return this.http.post(`${this.api}/api/professionalDetail/`, professionalDetails);
  }

  updateProfessionalDetails(professionalDetailsId: number, professionalDetails: any): Observable<any> {
    return this.http.put(`${this.api}/api/professionalDetail/${professionalDetailsId}`, professionalDetails);
  }
}
