<section id="disclaimer" class="container">
  <div class="disclaimer-content1" id="disclaimer-content">
    <div class="arrow-div-people">
      <a href="#content-wrapper">
        <img alt="cbw-img" src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/arrow-up.png" alt="arrow"
          class="arrrow-people-page" />
      </a>
    </div>
    <b>Disclaimer:</b>
    The information is for informational purposes only.​ This advertisement does
    not constitute financial advice or any other advice. You should consult with
    a financial professional to determine what may be best for your individual
    needs. None of the information and/or content available through this
    advertisement is intended as an offer or solicitation of an offer to buy or
    sell, or as a recommendation, endorsement, or sponsorship of any company,
    financial product, security or commodity. To the maximum extent permitted by
    law, we disclaim any and all liability in the event any information,
    commentary, analysis, opinions, advice and/or recommendations prove to be
    inaccurate, incomplete or unreliable or result in any investment or other
    losses. In Making the investment decision, investors must rely on their own
    examination of the issuer and the terms of the offerings, including the
    merits and risks involved. Investments are speculative, illiquid, and
    involve a high degree of risk , including the possible loss of investment.
  </div>
</section>
<footer>
  <div class="ccp-footer">
    <div class="ccap-footer-middle">
      <div class="row footer-contact align-items-center">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <img alt="cbw-img" src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/footer-logo.png"
            alt="CBW_logo" class="footer-logo" />
        </div>

        <div class="col-lg-3 col-md-4 col-sm-12 col-12 footer-adressing">
          <span class="footer-env"><img
              src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/email-icon.svg"
              alt="CBWLogo" /></span>
          <span class="ccap-text">
            Stay connected in email
            <br />
            <small>info@cryptobusinessworld.com</small>
          </span>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-12 col-12 footer-adressing">
          <span class="footer-env"><img
              src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/Subscribe.svg" alt="email" /></span>
          <span class="ccap-text" data-toggle="modal" data-target="#exampleModalCenter">
            <label class="envenv cursor-pointer" (click)="subscribeDialog=true">
              Subscribe with Us
              <img alt="cbw-img" src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/Path-sub.svg"
                alt="Path" id="img-path" />
            </label>
          </span>
        </div>

        <div class="col-lg-3 col-md-2 col-sm-12 col-12 footer-adressing">
          <span class="footer-env"><img alt="cbw-img"
              src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/support.svg" alt="user" /></span>
          <span class="ccap-text">
            <small class="custom-ccap">Call us at any time</small>
            <small class="custom-ccap">+1 888-666-0441</small>
            <small class="custom-ccap">+1 888-666-3176</small>
          </span>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12 col-12" style="margin-right: 10px;">
          <h5 style="">
            <a href="https://play.google.com/store/apps/details?id=com.cbw.cryptobusinessworld" target="_blank"><img
                width="100%" src="assets/images/google-play-badge.png" /></a>
          </h5>
        </div>
      </div>
      <div class="break-border-main-footer"></div>
      <div class="footer-lower-side">
        <div class="row margin-auto">
          <div class="col-md-4 col-sm-12 col-12">
            <a target="_blank" href="https://www.facebook.com/officialcbw"><img
                src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/facebook-1.svg" alt="facebook"
                class="social-icon" /></a>
            <a target="_blank" href="https://twitter.com/CryptoBizWorld"><img
                src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/Twitter-1.svg" alt="Twitter"
                class="social-icon" /></a>
            <a target="_blank" href="https://www.linkedin.com/company/cryptobusinessworld"><img
                src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/LinkedIn-1.svg"
                class="social-icon" /></a>
            <a target="_blank" href="https://www.youtube.com/c/CryptoBreakingNewsOfficial"><img
                src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/YouTube.svg"
                class="social-icon" /></a>
            <a target="_blank" href="https://t.me/CryptoBusinessWorldCBW"><img
                src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/Channel-1.svg" alt="Channel"
                class="social-icon" /></a>
          </div>
          <div class="col-md-4 col-sm-12 center ccap-copyright">
            <p>
              <span class="spanblue">Crypto Bussiness World - </span><span class="spanwhite">copyright©2023</span>
            </p>
          </div>
          <div class="col-md-4 col-sm-12 right ccap-privacy">
            <span class="fz14">
              <a class="ccap-line" href="/Home/PrivacyPolicy" target="_blank">Privacy Policy</a>
              |
              <a class="ccap-line" href="/Home/TermOfUse" target="_blank">Terms of Use</a>
              |
              <a class="ccap-line" href="/Home/Disclaimer" target="_blank">Disclaimer</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>


<p-dialog
  header="Subscribe with us."
  [(visible)]="subscribeDialog"
  [style]="{ width: '50vw', height: '300px' }"
  class="p-d-flex p-flex-column p-jc-center p-ai-center"
>
  <div class="dialog-content flex flex-column align-items-center">
    <span class="mb-3"
      >Please enter your email address for subscription</span
    >
    <input
      type="email"
      placeholder="Email"
      #email
      class="p-inputtext w-full mb-3"
    />
    <p-button label="Subscribe"
     (click)="SubscribeWithUs(email)"
      styleClass="p-button-sm"></p-button>
  </div>
</p-dialog>
