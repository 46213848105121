import { Component } from '@angular/core';

@Component({
  selector: 'app-basic-rating',
  templateUrl: './basic-rating.component.html',
  styleUrls: ['./basic-rating.component.scss']
})
export class BasicRatingComponent {

}
