<div class="breaking-news">
  <span class="breaking-news--head">Breaking News</span>
  <div class="orange-line"></div>
  <div class="breaking-news--body">
    <div *ngFor="let data of breakingNewsList; let index = index">
      <div *ngIf="index < 5" class="breaking-news--body--boxes">
        <span>
          <img class="breaking-news--body--boxes--img" src="{{ data.postImageURL }}" />
        </span>
        <span class="breaking-news--body--boxes--content">
          <a routerLink="/articles-preview/{{
            data.postTitle  | urlPrettier
          }}" class="breaking-news--body--boxes--content--title">{{
            data.postTitle
            }}</a>
          <span class="breaking-news--body--boxes--content--date">{{
            data.createdDateTime | date : "dd-MM-yyyy"
            }}</span>
        </span>
      </div>
    </div>
  </div>
  <div class="breaking-news--bottom">
    <span routerLink="/cbw-Article/BreakingNews" class="breaking-news--bottom--btn">MORE FROM BREAKING NEWS</span>
  </div>
</div>
