<div class="container-fluid">
    <div class="row">
        <div class="col">
            <h1>Frequently Asked Questions List</h1>
            <p-table #dt [value]="faqList" responsiveLayout="stack" [breakpoint]="'960px'" [rows]="10"
                [paginator]="true" [rowsPerPageOptions]="[10, 20, 50, 100, 200, 300, 500]"
                [globalFilterFields]="['category','question','ownername']"
                styleClass=" p-datatable-gridlines p-datatable-striped p-datatable-sm" selectionMode="single"
                [rowHover]="true">
                <ng-template pTemplate="caption">
                    <div class="flex p-fluid justify-content-between">
                        <div class="">
                            <div class="flex flex-column md:flex-row md:justify-content-between">
                                <p-dropdown [options]="filterBy" placeholder="" (onChange)="onSelectionChange(dt)"
                                    styleClass="p-inputtext-sm mb-2 md:mb-0 "></p-dropdown>
                            </div>
                        </div>
                        <div class="">
                            <span class="p-input-icon-left ml-auto">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" class="p-inputtext-sm"
                                    (input)="dt.filterGlobal($event, 'contains')" placeholder="Search keyword" />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th></th>
                        <th [pSortableColumn]="'category'">Category
                            <p-sortIcon [field]="'category'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'question'">Question
                            <p-sortIcon [field]="'question'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'status'">Status
                            <p-sortIcon [field]="'status'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'ownername'">Owner Name
                            <p-sortIcon [field]="'ownername'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'createdate'">Create Date
                            <p-sortIcon [field]="'createdate'"></p-sortIcon>
                        </th>

                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" (dblclick)="editRecord(rowData.id)">
                        <td class="text-center">
                            <a (click)="deleteRecord(rowData.id)" pTooltip="Delete">
                                <i class="pi pi-trash"></i>
                            </a>
                        </td>

                        <td><span class="p-column-category"></span>{{rowData?.category}}</td>
                        <td><span class="p-column-question"></span>{{rowData?.question }}</td>
                        <td><span class="p-column-status"></span>{{rowData?.status}}</td>
                        <td><span class="p-column-ownername"></span>{{rowData?.ownername}}</td>
                        <td><span class="p-column-createdate"></span>{{rowData?.createdate | date: 'dd/MM/yyyy'}}</td>

                        <td>Preview</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No Records found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>