import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-inspiration-detail-page',
  templateUrl: './inspiration-detail-page.component.html',
  styleUrls: ['./inspiration-detail-page.component.scss']
})
export class InspirationDetailPageComponent {
  peopleDetails: any = [];

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.getAllPeopleByPeopleId(history.state.id)
  }

  getAllPeopleByPeopleId(peopleId: number) {
    this.authService.getAllPeopleByPeopleId(peopleId).subscribe(
      (response: any) => {
        [response].forEach((element: any, index: number) => {
          this.peopleDetails[index] = element;
        });
        console.log("peopleDetails: ", this.peopleDetails);
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
