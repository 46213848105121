<div class="events">
  <div class="events--main">
    <img alt="cbw-img" class="events--main--img" src="./assets/images/CBW-event-banner.png" />
    <div class="events--main--btns">
      <span [ngClass]="
          isAllEvent
            ? 'events--main--btns--btn-selected'
            : 'events--main--btns--btn'
        " (click)="isAllEvent = true">All Events</span>
      <span [ngClass]="
          !isAllEvent
            ? 'events--main--btns--btn-selected'
            : 'events--main--btns--btn'
        " (click)="isAllEvent = false">Select Region</span>
    </div>
    <div *ngIf="!isAllEvent" class="events--main--btns">
      <span *ngFor="let region of allEventList; let index = index" [ngClass]="
          regionIndex == index
            ? 'events--main--btns--btn-selected'
            : 'events--main--btns--btn'
        " (click)="regionIndex = index">{{ region.eventVenue }}</span>
    </div>
    <div *ngIf="isAllEvent">
      <div class="events--main--all-events" *ngFor="let data of allEventList">
        <span class="events--main--all-events--region">{{ data.eventVenue }}</span>
        <img alt="cbw-img" class="events--main--all-events--regionImg" src="assets/images/Virtual_Summit_event.png" />
        <div class="events--main--all-events--details">
          <img alt="cbw-img" class="events--main--all-events--details--venueImg" src="{{ data.imageUrl }}" />
          <span class="events--main--all-events--details--details">
            <span class="events--main--all-events--details--details--title">{{
              data.eventName
              }}</span>
            <span class="events--main--all-events--details--details--description">{{ data.eventDescription }}</span>
            <span class="events--main--all-events--details--details--info-head">Schedule is as follows:</span>
            <span class="events--main--all-events--details--details--info">
              <span class="events--main--all-events--details--details--info--text">Date:
              </span>
              <span>{{ data.eventDate }}</span>
            </span>
            <span class="events--main--all-events--details--details--info">
              <span class="events--main--all-events--details--details--info--text">Venue:
              </span>
              <span>{{ data.eventVenue }}</span>
            </span>
            <span class="events--main--all-events--details--details--info">
              <span class="events--main--all-events--details--details--info--text">Website:
              </span>
              <span>{{ data.eventWebsite }}</span>
            </span>
            <div class="events--main--all-events--details--details--view-details"
              (click)="redirectToEventLink(data.eventWebsite)">
              <span class="events--main--all-events--details--details--view-details--text">
                View Event Details
              </span>
              <img alt="cbw-img" class="events--main--all-events--details--details--view-details--img"
                src="./assets/images/right-arrow.svg" />
            </div>
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="!isAllEvent">
      <div class="events--main--all-events">
        <span class="events--main--all-events--region">{{
          allEventList[regionIndex].eventVenue
          }}</span>
        <img alt="cbw-img" class="events--main--all-events--regionImg" src="assets/images/Virtual_Summit_event.png" />
        <div class="events--main--all-events--details">
          <img alt="cbw-img" class="events--main--all-events--details--venueImg" src="{{ allEventList[regionIndex].imageUrl }}" />
          <span class="events--main--all-events--details--details">
            <span class="events--main--all-events--details--details--title">{{
              allEventList[regionIndex].eventName
              }}</span>
            <span class="events--main--all-events--details--details--description">{{
              allEventList[regionIndex].eventDescription }}</span>
            <span class="events--main--all-events--details--details--info-head">Schedule is as follows:</span>
            <span class="events--main--all-events--details--details--info">
              <span class="events--main--all-events--details--details--info--text">Date:
              </span>
              <span>{{ allEventList[regionIndex].eventDate }}</span>
            </span>
            <span class="events--main--all-events--details--details--info">
              <span class="events--main--all-events--details--details--info--text">Venue:
              </span>
              <span>{{ allEventList[regionIndex].eventVenue }}</span>
            </span>
            <span class="events--main--all-events--details--details--info">
              <span class="events--main--all-events--details--details--info--text">Website:
              </span>
              <span>{{ allEventList[regionIndex].eventWebsite }}</span>
            </span>
            <div class="events--main--all-events--details--details--view-details" (click)="
                redirectToEventLink(allEventList[regionIndex].eventWebsite)
              ">
              <span class="events--main--all-events--details--details--view-details--text">
                View Event Details
              </span>
              <img alt="cbw-img" class="events--main--all-events--details--details--view-details--img"
                src="./assets/images/right-arrow.svg" />
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
