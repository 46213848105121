import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss']
})
export class ArticleDetailComponent {

  constructor(private activeRoute: ActivatedRoute){}

  ngOnInit(){
    this._checkModeInit();
  }

  _checkModeInit(){
    this.activeRoute.params.subscribe(param => {
      console.log(param)
    })
  }
}
