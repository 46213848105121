import { Component, ElementRef, ViewChild } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { UserListService } from './../../shared/services/user-list.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent {

  @ViewChild('searchInput', { static: false })
  searchInput!: ElementRef<HTMLInputElement>;

  isAllLoaded: boolean = true;
  user: any;
  userStatus:string='pending'
  userList: any = []
  roleList: { label: any; value: any; }[] = []
  selectedRole: any
  selectedStatus: any = 'all'
  rejectionReasonMessage: string = ''
  rejectionReasonDialog: boolean = false;
  userSelectedForReject: any;
  rejectionMessageEmpty: boolean = false;

  filterBy=[
    {label:'All',value:'all'},
    {label:'Email Verified', value:'emailVerified'},
    {label:'Email Not Verified', value:'emailNotVerified'},
    {label:'Approved', value:'approved'},
    {label:'Rejected', value:'rejected'},
  ];

  constructor(private router: Router, private authService:AuthService, private userListService: UserListService, private toastr: ToastrService, private notificationService: NotificationService){}

  async ngOnInit() {
    this.authService.loggedInUser.subscribe(res => {
      this.user = res.user;
    });

    try {
      const res = await this.userListService.getAllRolesId().toPromise();

      if (res?.length > 0) {
        for (const role of res) {
          if (role.roleName !== 'SuperAdmin') {
            this.roleList.push({ label: role.roleName, value: role.roleId });
            this.selectedRole = 'ExternalAnalyst';
          }
        }
      }

      if (this.roleList.length > 0) {
        this.userList = await this.userListService.getUsersByRoleId({ roleId: this.roleList[0]?.value }).toPromise();
      }
      // this.isAllLoaded = true;
      this.authService.hasPageLoaded.next(true)
    } catch (error) {
      // Handle any errors that occur during the API calls
      console.error('Error:', error);
      this.notificationService.showError('Something went wrong. Please try again!')
      this.authService.hasPageLoaded.next(true)
    }
  }

  catchReview(event: any,rowData:any){
    console.log('userData is: ', rowData)
    this.userSelectedForReject=rowData;

    if(event.trigger == 'reject'){
      this.rejectionReasonDialog=true;
      return;
    }

    // Approval flow
    this.rejectionReasonDialog=false;
    this.userListService.approveOrRejectUser(this.userSelectedForReject?.userId, this.user.userId, 'approve')
    .subscribe(
      response => {
        this.authService.hasPageLoaded.next(true)
        console.log('Request successful:', response);
        this.notificationService.showSuccess('Approval Successfull')
        location.reload();
      },
      error => {
        this.authService.hasPageLoaded.next(true)
        console.error('An error occurred:', error);
        this.notificationService.showError('Something went wrong. Please try again!')
      }
    );
  }

  rejectUser(){
    
    console.log('rejectionReasonMessage is: ', this.rejectionReasonMessage)
    if(!this.rejectionReasonMessage) {
      this.rejectionMessageEmpty = true
      return;
    } else {
      this.rejectionMessageEmpty = false
    }

    this.rejectionReasonDialog = false;
    this.userListService.approveOrRejectUser(this.userSelectedForReject?.userId, this.user.userId, 'reject', this.rejectionReasonMessage)
      .subscribe(
        response => {
          this.authService.hasPageLoaded.next(true)
          console.log('Request successful:', response);
          this.notificationService.showSuccess('Rejection Successfull')
          location.reload();
        },
        error => {
          this.authService.hasPageLoaded.next(true)
          console.error('An error occurred:', error);
          this.notificationService.showError('Something went wrong. Please try again!')
        }
      );
  }

  editRecord(id:string){
    this.router.navigate(['/dashboard','user-profile','edit',id]);
  }
  deleteRecord(id:string){

  }

  async onSelectionStatusChange(event :any){
    this.selectedStatus = event.value
    this.userList = await this.userListService.getUsersByRoleId({ roleId: this.selectedRole, status: event.value }).toPromise();
  }

  async onSelectionRoleChange(event :any){
    this.selectedRole = event.value
    this.userList = await this.userListService.getUsersByRoleId({ roleId: event.value, status: this.selectedStatus }).toPromise();
  }

  logRowData(rowData: any) {
    console.log(rowData);
  }

}
