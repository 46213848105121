<div class="recommended-news">
  <span class="recommended-news--head">Recommended News</span>
  <div class="orange-line"></div>
  <div class="recommended-news--body">
    <div *ngFor="let data of recommendedNewsList; let index = index">
      <div *ngIf="index < 4" class="recommended-news--body--boxes">
        <span>
          <img class="recommended-news--body--boxes--img" src="{{ data.postImageURL }}" />
        </span>
        <span class="recommended-news--body--boxes--content">
          <a routerLink="/articles-preview/{{
            data.postTitle  | urlPrettier
          }}" class="recommended-news--body--boxes--content--title">{{
            data.postTitle
            }}</a>
          <span class="recommended-news--body--boxes--content--date">{{
            data.createdDateTime | date : "dd-MM-yyyy"
            }}</span>
        </span>
      </div>
    </div>
  </div>
  <div class="recommended-news--bottom">
    <span routerLink="/crypto-business-world-recommended-news" class="recommended-news--bottom--btn">MORE RECOMMENDED
      NEWS</span>
  </div>
</div>
