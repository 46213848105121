import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  api = environment.apiURL;
  constructor(private http: HttpClient) { }

  createPost(data : any):Observable<any> {
    return this.http.post(`${this.api}/api/post/`, data)
  }

  updatePost(data : any,id:any):Observable<any> {
    return this.http.put(`${this.api}/api/post/${id}`, data)
  }

  getCategories():Observable<any> {
    return this.http.get(`${this.api}/api/category/`)
  }

  getIndustries():Observable<any> {
    return this.http.get(`${this.api}/api/industry/`)
  }

  uploadMedia(data: any): Observable<any> {
    return this.http.post(`${this.api}/api/uploadFile/`, data)
  }

  getCategoryById(id: string|number): Observable<any>{
    return this.http.get(`${this.api}/api/category/${id}`)
  }

  getPostById(id: string|number): Observable<any>{
    return this.http.get(`${this.api}/api/post/getPostById/${id}`)
  }

  deltePostById(id: string|number): Observable<any>{
    return this.http.delete(`${this.api}/api/post/getPostById/${id}`)
  }




  createEvent(data : any):Observable<any> {
    return this.http.post(`${this.api}/api/event/`, data)
  }

  getEvents(params:any):Observable<any> {
    return this.http.get(`${this.api}/api/event/`,{params})
  }

  updateEvent(id:any,data : any):Observable<any> {
    return this.http.put(`${this.api}/api/event/${id}`, data)
  }

  createPeople(data : any):Observable<any> {
    return this.http.post(`${this.api}/api/people/`, data)
  }
  getPeople(params:any):Observable<any> {
    return this.http.get(`${this.api}/api/people`,{params})
  }
  updatePeople(id:any,data : any):Observable<any> {
    return this.http.put(`${this.api}/api/people/updatePeople/${id}`, data)
  }

  getAllCountries(): Observable<any> {
    return this.http.get(`${this.api}/api/country/`);
  }

  disablePost(id:any): Observable<any>{
    return this.http.put(`${this.api}/api/post/disablePostById/${id}`,{})
  }
  disablePeople(id:any): Observable<any>{
    return this.http.put(`${this.api}/api/people/disablePeopleById/${id}`,{})
  }
  disableEvent(id:any): Observable<any>{
    return this.http.put(`${this.api}/api/event/disableEventById/${id}`,{})
  }
}
