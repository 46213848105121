<div class="container home bg-white-color">
  <!-- <a href="{{apiURL}}">{{apiURL}}</a> -->
  <div class="row align-items-start">
    <div class="col-md-3">
      <app-daily-news></app-daily-news>
    </div>
    <div class="col-md-6">
      <app-breaking-news></app-breaking-news>
    </div>
    <div class="col-md-3">
      <app-trending-analysis-articles></app-trending-analysis-articles>
    </div>
  </div>
  <div class="line-border"></div>
  <div>
    <app-homepage-recommended-news></app-homepage-recommended-news>
  </div>
  <div class="line-border"></div>
  <div>
    <app-homepage-press-releases></app-homepage-press-releases>
  </div>
  <div class="line-border"></div>
  <div>
    <app-homepage-breaking-news></app-homepage-breaking-news>
  </div>
  <div class="line-border"></div>
  <div>
    <img
      src="./assets/images/register-as-an-external-analyst.png"
      style="width: -webkit-fill-available; width: -moz-available"
    />
  </div>
  <div class="line-border"></div>
  <div>
    <app-homepage-trending-videos></app-homepage-trending-videos>
  </div>
  <div class="line-border"></div>
  <div>
    <app-homepage-editors-choice></app-homepage-editors-choice>
  </div>
  <div class="line-border"></div>
  <div>
    <app-homepage-hot-stories></app-homepage-hot-stories>
  </div>
  <div class="line-border"></div>
  <div>
    <app-homepage-twitter-feeds></app-homepage-twitter-feeds>
  </div>
  <div class="line-border"></div>
</div>
