import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-daily-news',
  templateUrl: './daily-news.component.html',
  styleUrls: ['./daily-news.component.scss'],
})
export class DailyNewsComponent {
  dailyNewsList: any = [];
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.searchCategoryByName();
  }

  searchCategoryByName() {
    this.authService.searchCategoryByName('DailyNews').subscribe(
      (response: any) => {
        this.getPostsByCategoryId(response.categoryId);
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }

  getPostsByCategoryId(id: number) {
    this.authService.getPostsByCategoryId(-1, id, 1).subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          this.dailyNewsList[index] = element;
        });
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
