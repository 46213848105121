import { Component, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment'; // Import Moment.js
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { User } from 'src/app/shared/models/user.model';
import { usersList } from 'src/app/shared/users.list';
import { postList } from 'src/app/shared/post.list';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Location } from '@angular/common';
import { PostService } from '../../shared/services/post.service';
import { AuthService } from 'src/app/auth/auth.service';
import { forkJoin, map, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { peopleModel } from 'src/app/shared/models/people.model';
import { UserListService } from 'src/app/shared/services/user-list.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {

  private unsubscribe$ = new Subject<void>();

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.India];
  basicForm: FormGroup;
  educationForm: FormGroup;
  professionalDetailsForm: FormGroup;
  fileUploadForm: FormGroup;
  otherDetailsForm: FormGroup;
  aboutYourselfForm: FormGroup;
  isAllFormLoaded: boolean = true;
  resumeForm: FormGroup;
  profileImageForm: FormGroup;
  resumeFile: File | null;
  profileImageFile: File | null;

  progressDetails: any = []

  targetUserId: number
  userProfileData: any = {}
  categoryList : any = []
  regionList: any = [];
  domainList: any = []
  degreeList: any = []
  edulistDetails: any = {}
  industryList: any = []
  countryList: any = []
  courseList: any = []
  genders= [
    {label:'Male',value:'male'},
    {label:'Female',value:'female'}
  ]
  months = [
    {label: 'Select', value: ""},
    {label: 'January', value: 1},
    {label: 'February', value: 2},
    {label: 'March', value: 3},
    {label: 'April', value: 4},
    {label: 'May', value: 5},
    {label: 'June', value: 6},
    {label: 'July', value: 7},
    {label: 'August', value: 8},
    {label: 'September', value: 9},
    {label: 'October', value: 10},
    {label: 'November', value: 11},
    {label: 'December', value: 12},
  ]

  loggedInUser:any;
  cryptoexp:any
  id:any=''
  reqFields:any;

  constructor(private activeRoute : ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private authService: AuthService,
    private userListService: UserListService,
    private postService: PostService){}

  ngOnInit(){
    this.initForms();
    this.isAllFormLoaded= true;
    this.checkActiveRoute();
    this.loggedInUser = this.authService.getUser();
    //console.log(this.loggedInUser)
    if(!this.targetUserId) this.targetUserId = this.loggedInUser?.userId
    console.log('targetUserId is: ', this.targetUserId)

    try {
      let startTime = performance.now()
      const getUserDetails$ = this.userListService.getUserDetails(this.targetUserId);
      const getEducationDetailsByUserId$ = this.userListService.getEducationDetailsByUserId(this.targetUserId);
      const getProfessionalDetailsByUserId$ = this.userListService.getProfessionalDetailsByUserId(this.targetUserId);
      const getAllIndustries$ = this.userListService.getAllIndustries();
      const getAllDomains$ = this.userListService.getAllDomains();
      const getAllDegree$ = this.userListService.getAllDegree();
      const getAllCourses$ = this.userListService.getAllCourses();
      const getAllCountries$ = this.userListService.getAllCountries();

      // Combine all the observables using forkJoin
      forkJoin({
        userDetails: getUserDetails$.pipe(catchError(error => of(null))),
        educationDetails: getEducationDetailsByUserId$.pipe(catchError(error => of(null))),
        professionalDetails: getProfessionalDetailsByUserId$.pipe(catchError(error => of(null))),
        industries: getAllIndustries$.pipe(catchError(error => of(null))),
        domains: getAllDomains$.pipe(catchError(error => of(null))),
        degrees: getAllDegree$.pipe(catchError(error => of(null))),
        courses: getAllCourses$.pipe(catchError(error => of(null))),
        countries: getAllCountries$.pipe(catchError(error => of(null)))
      }).subscribe(
        (data) => {
          console.log(data)
          // Data object contains the result of all the observables
          this.userProfileData = { ...this.userProfileData, ...data.userDetails, ...data.educationDetails, ...data.professionalDetails };
          console.log('userProfileData is: ', this.userProfileData)
          
          if (data.industries?.length > 0) {
            for (const industry of data.industries) {
              this.industryList.push({ label: industry.industryName, value: industry.industryId });
            }
          }

          if (data.domains?.length > 0) {
            for (const domain of data.domains) {
              this.domainList.push({ label: domain.domainName, value: domain.domainId });
            }
          }

          if (data.degrees?.length > 0) {
            for (const degree of data.degrees) {
              this.degreeList.push({ label: degree.degreeName, value: degree.degreeId });
            }
          }

          if (data.courses?.length > 0) {
            for (const course of data.courses) {
              this.courseList.push({ label: course.courseTypeName, value: course.courseTypeId });
            }
          }

          if (data.countries?.length > 0) {
            for (const country of data.countries) {
              this.countryList.push({ label: country.nicename, value: country.id });
            }
          }

          // Function to run when all the observables are completed
          this.initBasicForm();
          this.initEducationForm();
          this.initProfessionalDetailsForm();
          this.initOtherDetailsForm();
          this.initAboutYourselfForm();
          this.initResumeForm();
          this.initProfileImageForm();

          this.initProfileProgress();

          let endTime = performance.now()
          console.log(`total time taken ${(endTime - startTime)/1000} seconds`)

          // this.isAllFormLoaded = true
          this.authService.hasPageLoaded.next(true)
        },
        (error) => {
          console.error('Error in ngOnInit of UserProfileComponent:', error);
          this.authService.hasPageLoaded.next(true)
        }
      );
    } catch (error) {
      console.error('Error in ngOnInit of UserProfileComponent:', error);
    }
  }

  checkActiveRoute(){
    this.activeRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe(param => {
      if(param['mode']=='edit' && param['userid']){
        this.targetUserId = param['userid']
      }
    });
  }

  initProfileProgress() {
    this.progressDetails=[
      {name:'Basic Details', status: this.userProfileData?.phone },
      {name:'Education Details', status: this.userProfileData?.instituteName },
      {name:'Professional Details', status: this.userProfileData?.organization},
      {name:'Other Details', status: this.userProfileData?.DOB},
      {name:'About Yourself', status: this.userProfileData?.aboutYourself},
      {name:'Resume Upload', status: this.userProfileData?.resumeLink},
      {name:'Profile Image Upload', status: this.userProfileData?.profileImageLink},
      {name:'LinkedIn Profile', status: this.userProfileData?.linkedInURL},
      {name:'Facebook Profile', status: this.userProfileData?.facebookURL},
      {name:'Twitter Profile', status: this.userProfileData?.twitterURL},
    ]
  }

  initBasicForm() {
    this.basicForm.patchValue({
      firstName: this.userProfileData?.firstName, 
      lastName: this.userProfileData?.lastName,
      email: this.userProfileData?.email,
      phone: this.userProfileData?.phone,
      gender: this.userProfileData?.gender,
      blogURL: this.userProfileData?.blogURL,
      linkedInURL: this.userProfileData?.linkedInURL,
      facebookURL: this.userProfileData?.facebookURL,
      twitterURL: this.userProfileData?.twitterURL
    });
  }

  initEducationForm() {
    this.educationForm.patchValue({
      educationDetailId: this.userProfileData?.educationDetailId,
      instituteName: this.userProfileData?.instituteName,
      courseTypeId: this.userProfileData?.courseTypeId ? this.userProfileData?.courseTypeId : 1,
      courseStartYear: this.userProfileData?.courseStartYear,
      courseEndYear: this.userProfileData?.courseEndYear,
      degreeId: this.userProfileData?.degreeId? this.userProfileData?.degreeId : 1,
    });
  }

  initProfessionalDetailsForm() {
    this.professionalDetailsForm .patchValue({
      designation: this.userProfileData?.designation,
      organization: this.userProfileData?.organization,
      fromMonth: this.userProfileData?.fromMonth ? this.userProfileData?.fromMonth : 1,
      fromYear: this.userProfileData?.fromYear,
      toMonth: this.userProfileData?.toMonth,
      toYear: this.userProfileData?.toYear,
      IsCryptoAssetExp: this.userProfileData?.IsCryptoAssetExp ? 'yes' : 'no',
      domainId: this.userProfileData?.domainId ? this.userProfileData?.domainId : 1,
      BriefExp: this.userProfileData?.BriefEx,
    });
  }

  initOtherDetailsForm() {
    this.otherDetailsForm.patchValue({
      DOB: this.userProfileData?.DOB,
      countryId: this.userProfileData?.countryId,
      state: this.userProfileData?.state,
      city: this.userProfileData?.city,
      industryId: this.userProfileData?.industryId,
      shortDescription: this.userProfileData?.shortDescription,
      specification: this.userProfileData?.specification,
    });
  }

  initAboutYourselfForm() {
    this.aboutYourselfForm.patchValue({
      aboutYourself: this.userProfileData?.aboutYourself,
    });
  }

  initResumeForm() {
    this.resumeForm.patchValue({
      resumeLink: this.userProfileData?.resumeLink,
    });
  }

  initProfileImageForm() {
    this.profileImageForm.patchValue({
      profileImageLink: this.userProfileData?.profileImageLink,
    });
  }


  onCryptoAssetExpSelected(value: string) {
    this.professionalDetailsForm.patchValue({
      IsCryptoAssetExp: value
    });
  }

  onBasicSubmit() {
    //if (this.basicForm.valid) {
      // console.log(this.basicForm.value)
      let data = {...this.basicForm.value, phone: this.basicForm.value?.phone?.dialCode + this.basicForm.value?.phone?.number}

      this.userListService.updateUserByUserId(this.userProfileData?.userId, data).subscribe(
        (response) => {
          console.log('User updated successfully:', response);
          this.notificationService.showSuccess("User updated successfully")
        },
        (error) => {
          this.notificationService.showError("Error while updateing user")
          console.error('Error updating user:', error);
        }
      );
    //}
  }

  onEducationSubmit() {
    if (this.educationForm.valid) {
      let data = {...this.educationForm.value, userId: this.userProfileData?.userId, educationDetailId: this.userProfileData?.educationDetailId}
      console.log('submit formdata is: ', data);

      if(this.userProfileData?.educationDetailId) {
        this.userListService.updateEducationalDetails(this.userProfileData.educationDetailId, data).subscribe(
          (response) => {
            console.log('Education Details updated successfully:', response);
            this.notificationService.showSuccess("Education Details updated successfully")
          },
          (error) => {
            this.notificationService.showError("Error updating Education Details")
            console.error('Error updating Education Details:', error);
          }
        );
      } else {
        this.userListService.createEducationalDetails(data).subscribe(
          (response) => {
            console.log('Education Details created successfully:', response);
            this.notificationService.showSuccess("Education Details created successfully")
          },
          (error) => {
            this.notificationService.showError("Error creating Education Details")
            console.error('Error creating Education Details:', error);
          }
        );
      }
    }
  }

  onProfessionSubmit() {
    if (this.professionalDetailsForm.valid) {
      let data = {...this.professionalDetailsForm.value, userId: this.userProfileData?.userId, professionDetailId: this.userProfileData?.professionDetailId, IsCryptoAssetExp: this.professionalDetailsForm?.value?.IsCryptoAssetExp == 'yes' ? true : false}
      console.log('submit formdata is: ', data);

      if(this.userProfileData?.professionDetailId) {
        this.userListService.updateProfessionalDetails(this.userProfileData.professionDetailId, data).subscribe(
          (response) => {
            console.log('Professional Details updated successfully:', response);
            this.notificationService.showSuccess("Professional Details updated successfully")
          },
          (error) => {
            this.notificationService.showError("Error updating Professional Details")
            console.error('Error updating Professional Details:', error);
          }
        );
      } else {
        this.userListService.createProfessionalDetails(data).subscribe(
          (response) => {
            console.log('Professional Details created successfully:', response);
            this.notificationService.showSuccess("Professional Details created successfully")
          },
          (error) => {
            this.notificationService.showError("Error creating Professional Details")
            console.error('Error creating Professional Details:', error);
          }
        );
      }
    }
  }

  onOtherDetailsSubmit() {
    if (this.otherDetailsForm.valid) {
      const formattedDOB = moment(this.otherDetailsForm.value.DOB).format('YYYY-MM-DD');
      let data = {...this.otherDetailsForm.value, DOB: formattedDOB}
      console.log('onOtherDetailsSubmit data is: ', data)

      this.userListService.updateUserByUserId(this.userProfileData?.userId, data).subscribe(
        (response) => {
          console.log('User updated successfully:', response);
          this.notificationService.showSuccess("User updated successfully")
        },
        (error) => {
          this.notificationService.showError("Error while updateing user")
          console.error('Error updating user:', error);
        }
      );
    }
  }

  onAboutYourselfSubmit() {
    if (this.aboutYourselfForm.valid) {
      let data = {...this.aboutYourselfForm.value}
      console.log('onAboutYourselfSubmit data is: ', data)

      this.userListService.updateUserByUserId(this.userProfileData?.userId, data).subscribe(
        (response) => {
          console.log('User updated successfully:', response);
          this.notificationService.showSuccess("User updated successfully")
        },
        (error) => {
          this.notificationService.showError("Error while updateing user")
          console.error('Error updating user:', error);
        }
      );
    }
  }

  onResumeSelected(event: any) {
    const files: FileList = event.files;
    if (files && files.length > 0) {
      this.resumeFile = files.item(0); // Get the first selected file
      // We can check for file type and size restrictions here if needed
      console.log(this.resumeFile?.name, this.resumeFile?.type, this.resumeFile?.size)
    }
  }

  onResumeUpload() {
    if (this.resumeFile) {
      // Create a FormData object and append the selected file to it
      const formData: FormData = new FormData();
      formData.append('file', this.resumeFile)

      // Send the FormData to the server using HttpClient
      this.postService.uploadMedia(formData).subscribe(
        (response) => {
          console.log('Resume Uploaded successfully:', response);
          this.notificationService.showSuccess("Resume Uploaded successfully")
          this.userProfileData.resumeLink = response.url;
          let data = {resumeLink: this.userProfileData?.resumeLink}

          this.userListService.updateUserByUserId(this.userProfileData?.userId, data).subscribe(
            (response) => {
              console.log('User updated successfully with resumeLink:', response);
              this.notificationService.showSuccess("User updated successfully with resumeLink")
            },
            (error) => {
              this.notificationService.showError("Error while updateing user with resumeLink")
              console.error('Error updating user with resumeLink:', error);
            }
          );
        },
        (error) => {
          this.notificationService.showError("Error while uploading Resume")
          console.error('Error while uploading Resume:', error);
        }
      );
    } else {
      console.warn('file not found: ', this.resumeFile)
    }
  }

  onProfileImageSelected(event: any) {
    const files: FileList = event.files;
    if (files && files.length > 0) {
      this.profileImageFile = files.item(0); // Get the first selected file
      // We can check for file type and size restrictions here if needed
      console.log(this.profileImageFile?.name, this.profileImageFile?.type, this.profileImageFile?.size)
    }
  }

  onProfileImageUpload() {
    if (this.profileImageFile) {
      // Create a FormData object and append the selected file to it
      const formData: FormData = new FormData();
      formData.append('file', this.profileImageFile)

      // Send the FormData to the server using HttpClient
      this.postService.uploadMedia(formData).subscribe(
        (response) => {
          console.log('Resume Uploaded successfully:', response);
          this.notificationService.showSuccess("Resume Uploaded successfully")
          this.userProfileData.profileImageLink = response.url;
          let data = {profileImageLink: this.userProfileData?.profileImageLink}

          this.userListService.updateUserByUserId(this.userProfileData?.userId, data).subscribe(
            (response) => {
              console.log('User updated successfully with profileImageLink:', response);
              this.notificationService.showSuccess("User updated successfully with profileImageLink")
            },
            (error) => {
              this.notificationService.showError("Error while updateing user with profileImageLink")
              console.error('Error updating user with profileImageLink:', error);
            }
          );
        },
        (error) => {
          this.notificationService.showError("Error while uploading ProfileImage")
          console.error('Error while uploading ProfileImage:', error);
        }
      );
    } else {
      console.warn('file not found: ', this.profileImageFile)
    }
  }














  initForms(){
    
      this.basicForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName:[],
        email:[],
        phone:[],
        gender:[],
        blogURL:[],
        linkedInURL:[],
        facebookURL:[],
        twitterURL:[],
      });
    
  
 
      this.educationForm = this.fb.group({
        educationDetailId:[], 
        courseTypeId:['', Validators.required],
        courseStartYear:['', Validators.required],
        instituteName:['', Validators.required],
        courseEndYear:['', Validators.required],
        degreeId:['', Validators.required]
      });
  
  

      this.professionalDetailsForm = this.fb.group({
        designation:['', Validators.required],
        organization:['', Validators.required],
        fromMonth:['', Validators.required],
        fromYear:['', Validators.required],
        toMonth:['1'],
        toYear:['1'],
        IsCryptoAssetExp:['', Validators.required],
        domainId:['', Validators.required],
        BriefExp:[],
      });

  
    
      this.otherDetailsForm = this.fb.group({
        DOB:['', Validators.required],
        countryId:[],
        state:['', Validators.required],
        city:['', Validators.required],
        industryId:[],
        shortDescription:[],
        specification:[],
      });
  
  
    
      this.aboutYourselfForm = this.fb.group({
        aboutYourself: ['', Validators.required]
      });
   
  
    
      this.resumeForm = this.fb.group({
        resumeLink: ['', Validators.required]
      });
   
  
    
      this.profileImageForm = this.fb.group({
        profileImageLink: []
      });
    
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

