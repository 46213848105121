<div class="user-profile-container">

  <!-- <div *ngIf="!isAllFormLoaded" class="d-flex justify-content-center">
    <img alt="cbw-img" class="border-circle basic-spinner" src="assets/images/basic-spinner.gif" alt="">
  </div> -->

  <div *ngIf="isAllFormLoaded"class="user-profile-wrapper">
    <div class="row ">
        <div class="col-12 col-sm-12 col-md-4">
            <div class="row user-header bg-warning align-items-center">
                <div class="col-3 col-md-3 user-hearder-image">
                <!-- <img alt="cbw-img" *ngIf="!userProfileData.profileImageLink" height="70px" class="border-circle" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80" alt=""> -->

                <img alt="cbw-img" *ngIf="!userProfileData.profileImageLink" height="70px" class="border-circle" src="assets/images/profile-image.jpg" alt="">

                <img alt="cbw-img" *ngIf="userProfileData.profileImageLink" height="70px" class="border-circle" src="{{userProfileData.profileImageLink}}" alt="">
                </div>
                <div class="col-9 col-md-9 user-header-email text-white font-bold text-lg">
                    <span>{{userProfileData?.username}}</span>
                </div>
            </div>
            <div class="row user-progress-details border-bottom-1">
                <ng-container *ngFor="let progressdetail of progressDetails;">
                    <div class="col-10 col-md-10">{{progressdetail.name}}</div>
                    <div class="col-2 col-md-2 text-right font-bold progress-icons">
                      <div *ngIf="progressdetail.status; else crossIcon">
                        <i class="pi pi-check"></i>
                      </div>
                      <ng-template #crossIcon>
                        <i class="pi pi-times"></i>
                      </ng-template>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-8"></div>
    </div>

    <div class="row tab-view">
        <div class="col-12 col-md-12">
            <p-tabView [scrollable]="true" [style]="{'position':'relative'}">
                <!-- <p-button styleClass="p-button-sm" class="absolute" [ngStyle]="{'right':'20px','top':'70px'}" label="Update"></p-button> -->
                <p-tabPanel header="Basic Info">
                  <form *ngIf="isAllFormLoaded" [formGroup]="basicForm" (ngSubmit)="onBasicSubmit()">
                    <!-- First Name -->
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="flex flex-column">
                          <label htmlFor="firstName"><span class="red-star">* </span>First Name</label>
                          <input pInputText class="p-inputtext-md" id="firstName" aria-describedby="username-help"
                                formControlName="firstName" />
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="flex flex-column">
                          <label htmlFor="lastName"><span class="red-star">* </span>Last Name</label>
                          <input pInputText class="p-inputtext-md" id="lastName" aria-describedby="username-help"
                                formControlName="lastName" />
                        </div>
                      </div>
                    </div>

                    <!-- Email -->
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="flex flex-column">
                          <label htmlFor="email"><span class="red-star">* </span>Email</label>
                          <input pInputText class="p-inputtext-md" id="email" aria-describedby="username-help"
                                formControlName="email" />
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="flex flex-column" id="mobile">
                          <label htmlFor="phone"><span class="red-star">* </span>Mobile</label>
                          <ngx-intl-tel-input
                            [cssClass]="'form-control'"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="CountryISO.India"
                            [maxLength]="15"
                            [phoneValidation]="true"
                            [separateDialCode]="false"
                            [numberFormat]="PhoneNumberFormat.National"
                            formControlName="phone"
                          ></ngx-intl-tel-input>
                        </div>
                      </div>
                    </div>

                    <!-- Specialization and Blog Link -->
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="flex flex-column p-fluid">
                          <label htmlFor="gender"><span class="red-star">* </span>Gender</label>
                          <p-dropdown [options]="genders" formControlName="gender" styleClass="p-inputtext-md"></p-dropdown>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="flex flex-column">
                          <label htmlFor="blogLink">Blog Link</label>
                          <input pInputText class="p-inputtext-md" id="blogURL" aria-describedby="username-help"
                                formControlName="blogURL" />
                        </div>
                      </div>
                    </div>

                    <!-- LinkedIn and Facebook Links -->
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="flex flex-column">
                          <label htmlFor="linkedInLink">LinkedIn Link</label>
                          <input required pInputText class="p-inputtext-md" id="linkedInURL" aria-describedby="username-help" formControlName="linkedInURL" />
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="flex flex-column">
                          <label htmlFor="facebookLink">Facebook Link</label>
                          <input pInputText class="p-inputtext-md" id="facebookURL" aria-describedby="username-help" formControlName="facebookURL" />
                        </div>
                      </div>
                    </div>

                    <!-- Twitter Link -->
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <div class="flex flex-column">
                          <label htmlFor="twitterLink">Twitter Link</label>
                          <input pInputText class="p-inputtext-md" id="twitterURL" aria-describedby="username-help" formControlName="twitterURL" />
                        </div>
                      </div>
                    </div>

                    <!-- Submit Button -->
                    <p-button styleClass="p-button-sm" class="absolute" [ngStyle]="{'right':'20px','top':'70px'}" label="Update" type="submit"></p-button>
                  </form>
                </p-tabPanel>

                <p-tabPanel header="Education Details">
                  <form *ngIf="isAllFormLoaded" [formGroup]="educationForm" (ngSubmit)="onEducationSubmit()">
                    <div class="row">
                      <div class="col-12 col-md-6">
                          <div class="flex flex-column ">
                              <label htmlFor="instituteName"><span class="red-star">* </span>Institute</label>
                              <input pInputText class="p-inputtext-md" id="instituteName" aria-describedby="institute-help" formControlName="instituteName" />
                          </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="flex flex-column p-fluid">
                          <label for="courseTypeId"><span class="red-star">* </span>Course Type</label>
                          <p-dropdown id="courseTypeId" [options]="courseList" formControlName="courseTypeId" optionLabel="label" optionValue="value"></p-dropdown>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <label htmlFor="yearOfCompletion"><span class="red-star">* </span>Year Of Completion</label>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="flex flex-column p-fluid">
                                      <input type="number" pInputText class="p-inputtext-md" id="courseStartYear" aria-describedby="institute-help" formControlName="courseStartYear" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="flex flex-column p-fluid">
                                      <input type="number" pInputText class="p-inputtext-md" id="courseEndYear" aria-describedby="institute-help" formControlName="courseEndYear" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="flex flex-column p-fluid">
                                <label htmlFor="degreeId">Degree</label>
                                <p-dropdown id="degreeId" [options]="degreeList" formControlName="degreeId" optionLabel="label" optionValue="value"></p-dropdown>
                                <!-- <select id="degree" class="p-dropdown" multiple>
                                    <option *ngFor="let degreeOption of degreeList" [value]="degreeOption.value">{{ degreeOption.label }}</option>
                                </select> -->
                            </div>
                        </div>
                    </div>

                    <!-- Submit Button -->
                    <p-button styleClass="p-button-sm" class="absolute" [ngStyle]="{'right':'20px','top':'70px'}" label="Update" type="submit"></p-button>
                  </form>
                </p-tabPanel>

                <p-tabPanel header="Professional Details">
                  <form *ngIf="isAllFormLoaded" [formGroup]="professionalDetailsForm" (ngSubmit)="onProfessionSubmit()">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="flex flex-column ">
                              <label htmlFor="designation"><span class="red-star">* </span>Designation</label>
                              <input pInputText class="p-inputtext-md" id="designation" aria-describedby="institute-help" formControlName="designation" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="flex flex-column ">
                              <label htmlFor="organization"><span class="red-star">* </span>Organization</label>
                              <input pInputText class="p-inputtext-md" id="organization" aria-describedby="institute-help" formControlName="organization" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-6">
                          <label htmlFor="fromMonth"><span class="red-star">* </span>From Month</label>
                          <div class="flex flex-column  p-fluid">
                            <p-dropdown id="fromMonth" [options]="months" formControlName="fromMonth" optionLabel="label" optionValue="value"></p-dropdown>
                          </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <label htmlFor="fromYear"><span class="red-star">* </span>From Year</label>
                        <div class="flex flex-column  p-fluid">
                          <input type="number" pInputText class="p-inputtext-md" id="fromYear" aria-describedby="institute-help" formControlName="fromYear" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="flex flex-column  p-fluid">
                              <label htmlFor="toMonth">To Month</label>
                              <p-dropdown id="toMonth" [options]="months" formControlName="toMonth" optionLabel="label" optionValue="value"></p-dropdown>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <label htmlFor="toYear">To Year</label>
                          <div class="flex flex-column  p-fluid">
                            <input type="number" pInputText class="p-inputtext-md" id="toYear" aria-describedby="institute-help" formControlName="toYear" />
                          </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="flex flex-column p-fluid ">
                              <label htmlFor="domainId"><span class="red-star">* </span>Which domain this experience is relevant for?</label>
                              <p-dropdown id="domainId" [options]="domainList" formControlName="domainId" optionLabel="label" optionValue="value"></p-dropdown>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="flex flex-column">
                            <label htmlFor="username"><span class="red-star">* </span>Is this experience relevant for crypto asset?</label>
                            <div class="flex flex-wrap gap-3">
                              <div class="flex align-items-center">
                                <p-radioButton name="IsCryptoAssetExp" formControlName="IsCryptoAssetExp" value="yes" inputId="cryptoexpyes"></p-radioButton>
                                <label for="cryptoexpyes" class="ml-2">Yes</label>
                              </div>
                              <div class="flex align-items-center">
                                <p-radioButton name="IsCryptoAssetExp" formControlName="IsCryptoAssetExp" value="no" inputId="cryptoexpno"></p-radioButton>
                                <label for="cryptoexpno" class="ml-2">No</label>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-12">
                            <div class="flex flex-column ">
                              <label htmlFor="BriefExp">Provide brief details about your experience</label>
                              <textarea rows="2"  pInputTextarea name="BriefExp" formControlName="BriefExp"></textarea>
                            </div>
                        </div>
                    </div>

                    <p-button styleClass="p-button-sm" class="absolute" [ngStyle]="{'right':'20px','top':'70px'}" label="Update" type="submit"></p-button>
                  </form>
                </p-tabPanel>

                <p-tabPanel header="Other Details">
                  <form *ngIf="isAllFormLoaded" [formGroup]="otherDetailsForm" (ngSubmit)="onOtherDetailsSubmit()">
                    <div class="row">
                        <div class="col-12 col-md-6">
                          <div class="flex flex-column p-fluid">
                            <label htmlFor="DOB"><span class="red-star">* </span>Date of Birth</label>
                            <p-calendar class="p-inputtext-md" formControlName="DOB" dateFormat="yy-mm-dd" [showIcon]="true"></p-calendar>
                          </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="flex flex-column p-fluid ">
                                <label htmlFor="countryId"><span class="red-star">* </span>Country</label>
                                <p-dropdown id="countryId" [options]="countryList" formControlName="countryId" optionLabel="label" optionValue="value"></p-dropdown>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="flex flex-column ">
                                <label htmlFor="state"><span class="red-star">* </span>State</label>
                                <input pInputText class="p-inputtext-md" id="state" aria-describedby="institute-help" formControlName="state" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="flex flex-column ">
                              <label htmlFor="username">City</label>
                              <input pInputText class="p-inputtext-md" id="city" aria-describedby="institute-help" formControlName="city" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="flex flex-column p-fluid">
                              <label htmlFor="industryId"><span class="red-star">* </span>Experience in which industry</label>
                              <p-dropdown id="industryId" [options]="industryList" formControlName="industryId" optionLabel="label" optionValue="value"></p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="flex flex-column ">
                                <label htmlFor="shortDescription">Why do you think you are a suitable candidate for this role?</label>
                                <textarea rows="2" pInputTextarea formControlName="shortDescription" name="shortDescription" id="shortDescription"></textarea>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="flex flex-column ">
                                <label htmlFor="specification">What specific experience do you have in the domain of crypto assets?</label>
                                <textarea rows="2" pInputTextarea formControlName="specification" name="specification" id="specification"></textarea>
                            </div>
                        </div>
                    </div>

                    <p-button styleClass="p-button-sm" class="absolute" [ngStyle]="{'right':'20px','top':'70px'}" label="Update" type="submit"></p-button>
                  </form>
                </p-tabPanel>

                <p-tabPanel header="About Yourself">
                  <form *ngIf="isAllFormLoaded" [formGroup]="aboutYourselfForm" (ngSubmit)="onAboutYourselfSubmit()">
                    <div class="row">
                        <div class="col-12 col-md-12">
                          <div class="flex flex-column ">
                            <label htmlFor="aboutYourself">About Yourself</label>
                            <textarea rows="2" pInputTextarea formControlName="aboutYourself" name="aboutYourself" id="aboutYourself"></textarea>
                          </div>
                        </div>
                    </div>

                    <p-button styleClass="p-button-sm" class="absolute" [ngStyle]="{'right':'20px','top':'70px'}" label="Update" type="submit"></p-button>
                  </form>
              </p-tabPanel>

                <p-tabPanel header="File Upload">
                  <div class="row">
                      <div class="col-12 col-md-6">
                        <form *ngIf="isAllFormLoaded" [formGroup]="resumeForm" (ngSubmit)="onResumeUpload()" enctype="multipart/form-data">
                          <!-- <input type="file" formControlName="resumeLink" /> -->
                          <!-- file upoload size is in bytes -->
                          <label htmlFor="resumeLink">Resume</label>
                            <p-fileUpload mode="basic" name="resumeLink" accept=".pdf,.doc.docx"
                            [maxFileSize]="25000000" chooseLabel="Browse" (onSelect)="onResumeSelected($event)"></p-fileUpload>
                          <button class="btn btn-md btn-secondary upload-button" type="submit">Upload</button>
                        </form>

                        <div *ngIf="userProfileData.resumeLink" [style]="{'margin-top':'20px'}">
                          <p [style]="{'margin-bottom':'0'}">Uploaded Resume URL:</p>
                          <p [style]="{'margin-top':'2px'}"><a href="{{userProfileData?.resumeLink}}" target="_blank">{{userProfileData?.resumeLink}}</a></p>
                        </div>
                      </div>

                      <div class="col-12 col-md-6">
                        <form *ngIf="isAllFormLoaded" [formGroup]="profileImageForm" (ngSubmit)="onProfileImageUpload()" enctype="multipart/form-data">
                          <!-- <input type="file" formControlName="resumeLink" /> -->
                          <!-- file upoload size is in bytes -->
                          <label htmlFor="profileImageLink">Profile Image</label>
                            <p-fileUpload mode="basic" name="profileImageLink" accept=".jpg,.jpeg,.png"
                            [maxFileSize]="25000000" chooseLabel="Browse" (onSelect)="onProfileImageSelected($event)"></p-fileUpload>
                          <button class="btn btn-md btn-secondary upload-button" type="submit">Upload</button>
                        </form>

                        <div *ngIf="userProfileData.profileImageLink" [style]="{'margin-top':'20px'}">
                          <p [style]="{'margin-bottom':'0'}">Uploaded Profile Image URL:</p>
                          <p [style]="{'margin-top':'2px'}"><a href="{{userProfileData.profileImageLink}}" target="_blank">{{userProfileData?.profileImageLink}}</a></p>
                        </div>
                      </div>
                  </div>

              </p-tabPanel>

            </p-tabView>
        </div>
    </div>

  </div>
</div>

