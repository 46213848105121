<div class="breaking-news">
  <div class="breaking-news--head mt-3">
    <span class="breaking-news--head--text">BREAKING NEWS</span>
    <span class="breaking-news--head--date">{{ now | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
  </div>
  <div class="breaking-news--title">
    Breaking News frm Crypto Business World
  </div>
  <div class="breaking-news--des">www.cryptobusinessworld.com</div>
  <video
    controls
    controlsList="nodownload"
    style="width: -webkit-fill-available; width: -moz-available"
    src="./assets/videos/us-supreme-court-coinbase.mp4"
  ></video>
  <div
    id="carouselExampleSlidesOnly"
    class="carousel slide"
    data-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          class="d-block w-100"
          src="./assets/images/earn-fixed-rewards.jpg"
          alt="First slide"
          style="height: 15rem"
        />
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="./assets/images/subscribe-to-our-newsletter.jpg"
          alt="Second slide"
          style="height: 15rem"
        />
      </div>
    </div>
  </div>
</div>
