import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasPageLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loggedInUser: BehaviorSubject<any> = new BehaviorSubject({});
  api: string = environment.apiURL;
  ipCheckerURL: string = environment.ipCheckerURL;
  constructor(private httpClient: HttpClient) { }

  login(data: any): Observable<any> {
    // localStorage.setItem('token', JSON.stringify(user))
    // this.loggedInUser.next(user);
    return this.httpClient.post(`${this.api}/api/user/login`, data);
  }

  logout() {
    localStorage.removeItem('token');
    this.loggedInUser.next({});
  }

  getUser() {
    if (localStorage.getItem('token')) {
      const user = JSON.parse(localStorage.getItem('token') ?? '');
      this.loggedInUser.next({ user });
      return user;
    } else {
      this.loggedInUser.next({});
    }
  }

  getUserRole(roleid: any): Observable<any> {
    return this.httpClient.get(`${this.api}/api/role/${roleid}`);
  }

  getAllUserRoles(): Observable<any> {
    return this.httpClient.get(`${this.api}/api/role`);
  }

  setToken(data: any) {
    localStorage.setItem('token', JSON.stringify(data));
  }

  verifyEmail(userid: any): Observable<any> {
    return this.httpClient.post(
      `${this.api}/api/user/verifyEmail/${userid}`,
      {}
    );
  }
  passwordResetEmail(useremail: string): Observable<any> {
    return this.httpClient.post(
      `${this.api}/api/user/triggerForgotPasswordEmail/${useremail}`,
      {}
    );
  }
  passwordReset(userid: any, pass: any): Observable<any> {
    return this.httpClient.post(
      `${this.api}/api/user/updateForgotPasswordWithNewPassword/${userid}`,
      { newPassword: pass }
    );
  }
  changePassword(userid: any, pass: any): Observable<any> {
    return this.httpClient.post(
      `${this.api}/api/user/changePasswordWithOldPassword/${userid}`,
      pass
    );
  }

  removeToken() {
    localStorage.removeItem('token');
  }

  getUserIPV4(){
    return this.httpClient.get(this.ipCheckerURL);
  }











































  getPostCountByCategoryId(categoryId: number) {
    return this.httpClient.get(
      `${this.api}/api/post/getPostsCount?categoryId=${categoryId}`
    );
  }

  getPostCountByCategoryIdWithValues(publisherId: number, categoryId: number, title: string, startDate: any, endDate: any) {
    return this.httpClient.get(
      `${this.api}/api/post/getPostsCount?categoryId=${categoryId}${publisherId != -1 ? '&publisherId=' + publisherId : ''}${startDate != undefined ? '&startDate=' + startDate : ''}${endDate != undefined ? '&endDate=' + endDate : ''}${title != '' ? '&searchByTitle=' + title : ''}`
    );
  }

  getEditorsChoiceCount() {
    return this.httpClient.get(
      `${this.api}/api/post/getPostsCount?isEditorChoice=true`
    );
  }

  getEditorsChoiceCountWithValues(publisherId: number, title: string, startDate: any, endDate: any) {
    return this.httpClient.get(
      `${this.api}/api/post/getPostsCount?isEditorChoice=true&allStatus=false${publisherId != -1 ? '&publisherId=' + publisherId : ''}${startDate != undefined ? '&startDate=' + startDate : ''}${endDate != undefined ? '&endDate=' + endDate : ''}${title != '' ? '&searchByTitle=' + title : ''}`
    );
  }

  getHotStoriesCount() {
    return this.httpClient.get(
      `${this.api}/api/post/getPostsCount?isHotStory=true`
    );
  }

  getHotStoriesCountWithValues(publisherId: number, title: string, startDate: any, endDate: any) {
    return this.httpClient.get(
      `${this.api}/api/post/getPostsCount?isHotStory=true&allStatus=false${publisherId != -1 ? '&publisherId=' + publisherId : ''}${startDate != undefined ? '&startDate=' + startDate : ''}${endDate != undefined ? '&endDate=' + endDate : ''}${title != '' ? '&searchByTitle=' + title : ''}`
    );
  }

  getAllPostsCount() {
    return this.httpClient.get(`${this.api}/api/post/getPostsCount`);
  }

  getAllPostsCountWithValues(publisherId: number, title: string, startDate: any, endDate: any) {
    return this.httpClient.get(`${this.api}/api/post/getPostsCount?${publisherId != -1 ? '&publisherId=' + publisherId : ''}${startDate != undefined ? '&startDate=' + startDate : ''}${endDate != undefined ? '&endDate=' + endDate : ''}${title != '' ? '&searchByTitle=' + title : ''}`);
  }

  searchCategoryByName(name: string) {
    return this.httpClient.get(`${this.api}/api/category/name/${name}`);
  }

  searchCategoryById(id: number) {
    return this.httpClient.get(`${this.api}/api/category/${id}`);
  }

  getPostsByCategoryId(publisherId: number, id: number, pageNumber: number) {
    return publisherId != -1
      ? this.httpClient.get(
        `${this.api}/api/post/getPosts/${id}?publisherId=${publisherId}&pageNo=${pageNumber}`
      )
      : this.httpClient.get(
        `${this.api}/api/post/getPosts/${id}?pageNo=${pageNumber}`
      );
  }

  getAllPosts(publisherId: any, allStatus: boolean, pageNumber: number) {
    return publisherId != -1
      ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&allStatus=${allStatus}&pageNo=${pageNumber}`
      )
      : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?allStatus=${allStatus}&pageNo=${pageNumber}`
      );
  }

  getEditorsChoice(
    publisherId: number,
    allStatus: boolean,
    isEditorChoice: boolean,
    pageNumber: number
  ) {
    return publisherId != -1
      ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&pageNo=${pageNumber}`
      )
      : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&pageNo=${pageNumber}`
      );
  }

  getHotStories(
    publisherId: number,
    allStatus: boolean,
    isHotstories: boolean,
    pageNumber: number
  ) {
    return publisherId != -1
      ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isHotstories=${isHotstories}&allStatus=${allStatus}&pageNo=${pageNumber}`
      )
      : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isHotstories=${isHotstories}&allStatus=${allStatus}&pageNo=${pageNumber}`
      );
  }

  createPostLike(data: any) {
    return this.httpClient.post(`${this.api}/api/postLike/like`, data);
  }

  createPostUnLike(postLikeId: number) {
    return this.httpClient.put(
      `${this.api}/api/postLike/unlike/${postLikeId}`,
      postLikeId
    );
  }

  getPostlikeByPostid(postId: number) {
    return this.httpClient.get(
      `${this.api}/api/postLike/getByPostId/${postId}`
    );
  }

  createPostComment(data: any) {
    return this.httpClient.post(`${this.api}/api/postComment/comment`, data);
  }

  getAllEvents() {
    return this.httpClient.get(`${this.api}/api/event/`);
  }

  getAllUsers() {
    return this.httpClient.get(`${this.api}/api/user/getAllUsers`);
  }

  getPostByTitle(publisherId: number, title: string, pageNumber: number) {
    return publisherId != -1
      ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&searchByTitle=${title}&pageNo=${pageNumber}`
      )
      : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?searchByTitle=${title}&pageNo=${pageNumber}`
      );
  }

  getPostByCategoryAndTitle(
    publisherId: number,
    categoryId: number,
    title: string,
    pageNumber: number
  ) {
    return publisherId != -1
      ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&categoryId=${categoryId}&searchByTitle=${title}&pageNo=${pageNumber}`
      )
      : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?categoryId=${categoryId}&searchByTitle=${title}&pageNo=${pageNumber}`
      );
  }

  getEditorsChoiceByTitle(
    publisherId: number,
    allStatus: boolean,
    isEditorChoice: boolean,
    title: string,
    pageNumber: number
  ) {
    return publisherId != -1
      ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&searchByTitle=${title}&pageNo=${pageNumber}`
      )
      : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&searchByTitle=${title}&pageNo=${pageNumber}`
      );
  }

  getHotStoriesByTitle(
    publisherId: number,
    allStatus: boolean,
    isHotstories: boolean,
    title: string,
    pageNumber: number
  ) {
    return publisherId != -1
      ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isHotstories=${isHotstories}&allStatus=${allStatus}&searchByTitle=${title}&pageNo=${pageNumber}`
      )
      : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isHotstories=${isHotstories}&allStatus=${allStatus}&searchByTitle=${title}&pageNo=${pageNumber}`
      );
  }

  getPostByCategoryAndDate(
    publisherId: number,
    categoryId: number,
    startDate: any,
    endDate: any,
    pageNumber: number
  ) {
    return publisherId != -1
      ? (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&categoryId=${categoryId}&startDate=${startDate}&endDate=${endDate}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&categoryId=${categoryId}&startDate=${startDate}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&categoryId=${categoryId}&endDate=${endDate}&pageNo=${pageNumber}`
      )))
      : (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?categoryId=${categoryId}&startDate=${startDate}&endDate=${endDate}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?categoryId=${categoryId}&startDate=${startDate}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?categoryId=${categoryId}&endDate=${endDate}&pageNo=${pageNumber}`
      )));
  }

  getPostByCategoryTitleDate(
    publisherId: number,
    categoryId: number,
    startDate: any,
    endDate: any,
    title: string,
    pageNumber: number
  ) {
    return publisherId != -1
      ? (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&categoryId=${categoryId}&startDate=${startDate}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&categoryId=${categoryId}&startDate=${startDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&categoryId=${categoryId}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      )))
      : (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?categoryId=${categoryId}&startDate=${startDate}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?categoryId=${categoryId}&startDate=${startDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?categoryId=${categoryId}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      )));
  }

  getPostByDate(publisherId: number, startDate: any, endDate: any, pageNumber: number) {
    return publisherId != -1
      ? (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&startDate=${startDate}&endDate=${endDate}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&startDate=${startDate}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&endDate=${endDate}&pageNo=${pageNumber}`
      )))
      : (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?startDate=${startDate}&endDate=${endDate}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?startDate=${startDate}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?endDate=${endDate}&pageNo=${pageNumber}`
      )));
  }

  getPostByTitleAndDate(
    publisherId: number,
    startDate: any,
    endDate: any,
    title: string,
    pageNumber: number
  ) {
    return publisherId != -1
      ? (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&startDate=${startDate}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&startDate=${startDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      )))
      : (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?startDate=${startDate}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?startDate=${startDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      )));
  }

  getEditorsChoiceByDate(
    publisherId: number,
    allStatus: boolean,
    isEditorChoice: boolean,
    startDate: any,
    endDate: any,
    pageNumber: number
  ) {
    return publisherId != -1
      ? (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&startDate=${startDate}&endDate=${endDate}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&startDate=${startDate}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&endDate=${endDate}&pageNo=${pageNumber}`
      )))
      : (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&startDate=${startDate}&endDate=${endDate}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&startDate=${startDate}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&endDate=${endDate}&pageNo=${pageNumber}`
      )));
  }

  getEditorsChoiceByDateAndTitle(
    publisherId: number,
    allStatus: boolean,
    isEditorChoice: boolean,
    startDate: any,
    endDate: any,
    title: string,
    pageNumber: number
  ) {
    return publisherId != -1
      ? (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&startDate=${startDate}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&startDate=${startDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      )))
      : (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&startDate=${startDate}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&startDate=${startDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isEditorChoice=${isEditorChoice}&allStatus=${allStatus}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      )));
  }

  getHotStoriesByDate(
    publisherId: number,
    allStatus: boolean,
    isHotstories: boolean,
    startDate: any,
    endDate: any,
    pageNumber: number
  ) {
    return publisherId != -1
      ? (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isHotstories=${isHotstories}&allStatus=${allStatus}&startDate=${startDate}&endDate=${endDate}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isHotstories=${isHotstories}&allStatus=${allStatus}&startDate=${startDate}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isHotstories=${isHotstories}&allStatus=${allStatus}&endDate=${endDate}&pageNo=${pageNumber}`
      )))
      : (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isHotstories=${isHotstories}&allStatus=${allStatus}&startDate=${startDate}&endDate=${endDate}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isHotstories=${isHotstories}&allStatus=${allStatus}&startDate=${startDate}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isHotstories=${isHotstories}&allStatus=${allStatus}&endDate=${endDate}&pageNo=${pageNumber}`
      )));
  }

  getHotStoriesByDateAndTitle(
    publisherId: number,
    allStatus: boolean,
    isHotstories: boolean,
    startDate: any,
    endDate: any,
    title: string,
    pageNumber: number
  ) {
    return publisherId != -1
      ? (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isHotstories=${isHotstories}&allStatus=${allStatus}&startDate=${startDate}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isHotstories=${isHotstories}&allStatus=${allStatus}&startDate=${startDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?publisherId=${publisherId}&isHotstories=${isHotstories}&allStatus=${allStatus}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      )))
      : (startDate != undefined && endDate != undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isHotstories=${isHotstories}&allStatus=${allStatus}&startDate=${startDate}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : (startDate != undefined && endDate == undefined ? this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isHotstories=${isHotstories}&allStatus=${allStatus}&startDate=${startDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      ) : this.httpClient.get(
        `${this.api}/api/post/getAllPosts?isHotstories=${isHotstories}&allStatus=${allStatus}&endDate=${endDate}&searchByTitle=${title}&pageNo=${pageNumber}`
      )));
  }

  getAllPeople() {
    return this.httpClient.get(
      `${this.api}/api/people/`
    );
  }

  getAllPeopleByPeopleId(peopleId: number) {
    return this.httpClient.get(
      `${this.api}/api/people/${peopleId}`
    );
  }

  getAllFaqCategory() {
    return this.httpClient.get(
      `${this.api}/api/faqCategory/`
    );
  }

  getFAQByCategoryId(catId: number) {
    return this.httpClient.get(
      `${this.api}/api/faq/getFAQByCategoryId/{faqcategoryId}?categoryId=${catId}`
    );
  }

}
