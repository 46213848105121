<div class="car-side-nav">
    <div class="car-side-nav-logo">
        <span class="cursor-pointer" routerLink="/dashboard"><b>CBW</b> Pannel</span>
    </div>
    <ul class="car-side-nav-items mt-3">
      <li *ngFor="let item of navigationItems">
        <a class="pi pi-circle" *ngIf="!item.sublinks" [routerLink]="item.route" (mouseover)="viewSublinks(item.label)" [routerLinkActive]="[item.route!='/dashboard'? 'active' : '']">{{ item.label }}</a>
        <a class="parentsublink pi pi-circle"  (mouseover)="viewSublinks(item.label)" *ngIf="item.sublinks" [routerLink]="item.sublinks[0].route" [routerLinkActive]="['active']">{{ item.label }}</a>
        <ul class="sublink" *ngIf="item.sublinks && item.label == selectedSublink">
          <li *ngFor="let sublink of item.sublinks">
            <a [routerLink]="sublink.route" [routerLinkActive]="['active']">{{ sublink.label }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>