import { Component } from '@angular/core';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss']
})
export class FaqListComponent {

  filterBy = [
    { label: 'Draft', value: 'Draft' },
    { label: 'Approval Pending', value: 'Approval Pending' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Pending', value: 'Pending' },
    { label: 'All', value: 'All' }
];

  faqList=[
    { id:1, category: 'Investement', createdate: new Date(), status: 'Pending', ownername:'Sam Malik', question:'How to Invest in crypto'}
  ]
  constructor(){}

  onSelectionChange(dt :any){

  }

  editRecord(id:any){
    console.log(id)
  }

  deleteRecord(id :any){
  
  }
}
