import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticleDetailService {

  api= environment.apiURL;
  constructor(private http: HttpClient) { }

  getArticle(id:any,title:any):Observable<any>{
    return this.http.get(`${this.api}/api/post/getPostById/${id}`)
  }

  getArticleByTitle(title:any):Observable<any>{
    const params = new HttpParams()
    .set('allStatus',true)
    //.set('searchByTitle',title)
    .set('searchByTitle',title.replaceAll('-',' ')); //replaceAll() is required as the api accepts the original title with spaces only

    return this.http.get(`${this.api}/api/post/getAllPosts`,{params})
  }

  getUserOfArticle(id:any):Observable<any>{
    return this.http.get(`${this.api}/api/user/getDetails/${id}`)
  }
}
