import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, forkJoin, map, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PostListService } from 'src/app/shared/services/post-list.service';
import { InterviewListService } from '../../shared/services/interview-list.service';


interface interviewModel{
  categoryId: number | string,
  postId: number | string,
  categoryname: string |number|undefined,
  createdDateTime: Date | string |number,
  industryId: number | string,
  status: string
  peopleName:string|number
}

@Component({
  selector: 'app-interview-list',
  templateUrl: './interview-list.component.html',
  styleUrls: ['./interview-list.component.scss']
})
export class InterviewListComponent {

  private unsubscribe$ = new Subject<void>();
  userType='Admin';
  interviewList:interviewModel[]=[];
  loggedInUser:any;
  filterBy = [
    { label: 'All', value: 'all' },
    { label: 'Draft', value: 'drafted' },
    { label: 'Approval Pending', value: 'pendingApproval' },
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },
];
allInterviews:any;
selectedPosts:any[] = [];
selectedFilter='all';
rejectionReasonMessage: string = ''
rejectionReasonDialog: boolean = false;
userSelectedForReject: any;
rejectionMessageEmpty: boolean = false;
bulkReject=false;
totalRecords=1000000;
userList:any;
constructor(private authServic: AuthService,
  private interviewListService: InterviewListService,
  private router: Router,
  private notificationservice: NotificationService,
  private postListService: PostListService){}

ngOnInit(){
  this.loggedInUser = this.authServic.getUser();
  this.userType=this.loggedInUser.roleName;
  this.getUsers()
  // this._getListData();
}

getUsers(){
  this.postListService.getAllUser().pipe(takeUntil(this.unsubscribe$)).subscribe({
    next:res =>{
    this.userList = res
  },
  error:err =>{
    console.log(err);
  }
})
}

_getListData(event:any){
  let params = new HttpParams()
    .set('categoryId',12)
    // .set('allStatus', true)
    .set('searchByTitle',event.filters.global?.value??'')
    .set('pageNo',(event.first/10)+1)
    .set('limit',event.rows);

    if(this.selectedFilter !='all' && this.selectedFilter != undefined){
      params=params.set('status',this.selectedFilter)
      params=params.set('allStatus',false)
    }else{
      params=params.set('allStatus',true)
    }
    
  if(this.loggedInUser.roleName != 'SuperAdmin' || this.loggedInUser.roleName !='ContentApprover'){
    params.set('publisherId', this.loggedInUser.userId)
  }

  this.authServic.hasPageLoaded.next(false);
  forkJoin(
    [this.interviewListService.getInterviewList(params)]
  )
  .pipe(
    map((res:any) => {
      
      return res[0].map((press:any) => {
        return {...press,user:{...this.userList.find((u:any) => u.userId == press.ownerId)}}
      })

    }),
    takeUntil(this.unsubscribe$)
    )
    .subscribe({
    next:(res:any)=>{
    this.interviewList = res;
    this.allInterviews = [...this.interviewList]
    console.log(res)
    this.authServic.hasPageLoaded.next(true);
  },
  error:err=>{
    console.log(err)
    this.authServic.hasPageLoaded.next(true);
  }
})
}

onSelectionChange(event :any){
  if(event.value == 'all'){
    this.interviewList = this.allInterviews
    return;
  }
  
  this.interviewList=this.allInterviews.filter((post:any) => post.status == event.value)
}

editInterview(id :any){
  this.router.navigate(['dashboard/create-interview/edit/'+id])
}

deleteRecord(id:string){
  this.authServic.hasPageLoaded.next(false)
  this.postListService.disablePost(id).pipe(takeUntil(this.unsubscribe$)).subscribe({
    next:res => {
    console.log(res);
    this.authServic.hasPageLoaded.next(true)
    this.notificationservice.showSuccess('Post Deleted Successfully!')
    this.interviewList=this.interviewList.filter((interview:any) => interview.postId != id)
  },
  error:err=>{
    console.log(err);
    this.authServic.hasPageLoaded.next(true)
    this.notificationservice.showError('Something went Wrong!')
  }
})
}

catchReview(event: any,postid:any){
  console.log(event,postid)
  // if(!this.selectedPosts.length) return;
  this.userSelectedForReject=postid;
  if(event.trigger == 'reject'){
    this.rejectionReasonDialog = true;
    return;
  }else{
    this.updatePostStatus('approved',postid);
  }
}

rejectPost(){
  console.log('rejectionReasonMessage is: ', this.rejectionReasonMessage);
 if(!this.rejectionReasonMessage) {
      this.rejectionMessageEmpty = true
      return;
    } else {
      this.rejectionMessageEmpty = false
    }

    this.rejectionReasonDialog = false;
    if(this.bulkReject){
      this.multipleSelectionReview({trigger:'reject'},false)
    }else{
      this.updatePostStatus('rejected',this.userSelectedForReject,this.rejectionReasonMessage);
    }
    

}

updatePostStatus(status:any,postid:any,remarks=''){
  console.log(status)
  const query = {
    adminId: this.loggedInUser.userId,
    status: status,
    remarks: remarks
  }

  this.authServic.hasPageLoaded.next(false)
  // this.selectedPosts.forEach(postid => {
    this.postListService.updatePostStatus(postid,query)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: res =>{
        console.log(res)
        this.authServic.hasPageLoaded.next(true)
        this.notificationservice.showSuccess(`Post ${status} successfully`)
        setTimeout(() => {
          // this._getListData();
          location.reload()
        }, 1000);
      },
      error:err=>{
        this.notificationservice.showError('Something went wrong')
        console.log(err)
        this.authServic.hasPageLoaded.next(true)
      }
    })

  
}

multipleSelectionReview(event: any,initial=true) {
  if(!this.selectedPosts.length)
    return;
    
  const query = {
    adminId: this.loggedInUser.userId,
    status: event.trigger == 'approve' ? 'approved' : 'rejected',
    remarks:  this.rejectionReasonMessage
  }
  if(query.status == 'rejected' && initial==true){
    this.bulkReject=true;
    this.rejectionReasonDialog=true;
    return;
  }

  this.rejectionReasonDialog=false;
  
  // this.selectedPosts.forEach(post => 
  const processPosts = (selectedPosts: any, index: any) => {
    this.authServic.hasPageLoaded.next(false);
    if (index < selectedPosts.length) {
      this.postListService.updatePostStatus(selectedPosts[index].postId, query)
        .pipe(takeUntil(this.unsubscribe$),
          finalize(() => {
            processPosts(selectedPosts, index + 1);
          })
        )
        .subscribe({
          next: res => {
            console.log(res)
            this.authServic.hasPageLoaded.next(true);
            //this.notificationService.showSuccess(`Post with id ${post.postId} ${query.status} successfully updated`);
          },
          error: err => {
            this.notificationservice.showError('Something went wrong while updating posts');
            console.log(err);
            this.authServic.hasPageLoaded.next(true);
          }
        })
    } else {
      this.authServic.hasPageLoaded.next(true);
      this.notificationservice.showSuccess(`${index} Post(s) ${query.status} successfully`);
      setTimeout(() => {
        location.reload();
      }, 2000);
    }
  }

  processPosts(this.selectedPosts, 0);
}//function ends


ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}
}
