<div class="car-dashboard">
    <div class="car-dashboard-sidebar">
        <app-sidebar [navigationItems]="navigationItems"></app-sidebar>
    </div>
    <div class="car-dashboard-shell">
        <div class="car-top-nav">
            
                <span class="go-home" routerLink="/">Home</span>
            
            <div class="loggedin-user">
                <a class="loggedin-user-link text-right cursor-pointer" (click)="$event.preventDefault()">{{user?.firstName | titlecase}} {{user?.lastName | titlecase}}</a>
                <div class="loggedin-user-submenu">
                    <ul>
                        <li><a (click)="$event.preventDefault()">Profile</a></li>
                        <li><a  routerLink="/forgot-password/change/{{user.userId}}">Change Password</a></li>
                        <li><a (click)="logOut()">Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="car-dashboard-shell-content">
            <router-outlet></router-outlet>
        </div>
        
    </div>
</div>
