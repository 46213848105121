import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-trending-analysis-articles',
  templateUrl: './trending-analysis-articles.component.html',
  styleUrls: ['./trending-analysis-articles.component.scss'],
})
export class TrendingAnalysisArticlesComponent {
  isTrendingArticles: boolean = true;

  analysisList: any = [];

  trendingArticlesList: any = [];
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.searchCategoryByName();
  }

  searchCategoryByName() {
    this.authService.searchCategoryByName('Trending').subscribe(
      (response: any) => {
        this.getPostsByCategoryId(response.categoryId);
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );

    this.authService.searchCategoryByName('Analysis').subscribe(
      (response: any) => {
        this.getAnalysisPostsByCategoryId(response.categoryId);
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }

  getPostsByCategoryId(id: number) {
    this.authService.getPostsByCategoryId(-1, id, 1).subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          this.trendingArticlesList[index] = element;
        });
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }

  getAnalysisPostsByCategoryId(id: number) {
    this.authService.getPostsByCategoryId(-1, id, 1).subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          this.analysisList[index] = element;
        });
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
