<div class="register-form-container">
  <div class="row justify-content-center dis-contents">
    <div [formGroup]="registerForm" class="col-6 bg-light mt-3 width-full-available">
      <h5 class="mt-3">Register</h5>
      <hr>
      <div class="mb-3">
        <input type="text" class="form-control" id="formGroupExampleInput" formControlName="firstName"
          placeholder="First Name">
      </div>
      <div class="mb-3">
        <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Last Name">
      </div>
      <div class="mb-3 p-fluid">
        <p-dropdown [options]="genders" formControlName="gender" styleClass="p-inputtext-sm"></p-dropdown>
      </div>
      <div class="mb-3">
        <input type="email" class="form-control" id="emailId" formControlName="emailId" placeholder="Email address">
      </div>
      <div class="mb-3">
        <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="preferredCountries"
          [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name
                            ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
          [phoneValidation]="true" [separateDialCode]="false" [numberFormat]="PhoneNumberFormat.National" name="phone"
          formControlName="mobileNumber"></ngx-intl-tel-input>
      </div>

      <div class="mb-3">
        <select id="inputState" class="form-control" formControlName="user" placeholder="Select Role">
          <option value="" disabled selected>Select Role</option>
          <option *ngFor="let option of roleList" value="{{option.roleId}}">{{option.roleName}}</option>
        </select>
      </div>

      <div class="mb-3">
        <input type="password" class="form-control" id="password" formControlName="password"
          placeholder="Enter Password">
      </div>

      <div class="mb-3">
        <app-captcha></app-captcha>
      </div>

      <div class="mt-4 d-flex justify-content-center">
        <button type="button" [disabled]="registerForm.invalid || !captchaStatus" (click)="register()"
          class="btn btn-warning px-5 py-2 rounded-pill">Submit</button>
      </div>
    </div>
  </div>