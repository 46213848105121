import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PressReleaseService {

  api=environment.apiURL
  constructor(private http: HttpClient) { }

  getPressReleaseList(id:any,owner:any,event:any,selectedfilter:any){ //bycatgoryid
    // console.log(owner,event)
    // console.log(owner.roleName)
    let params = new HttpParams()
    .set('categoryId',id)
    // .set('allStatus', true)
    .set('pageNo',(event.first/10)+1)
    .set('limit',event.rows);

    if(selectedfilter !='all' && selectedfilter != undefined){
      params=params.set('status',selectedfilter)
      params=params.set('allStatus',false)
    }else{
      params=params.set('allStatus',true)
    }

    if(owner.roleName =='SuperAdmin' || owner.roleName =='ContentApprover'){
      return this.http.get(`${this.api}/api/post/getAllPosts`,{params})
    }else{
      params =params.set('publisherId', owner.userId)
      return this.http.get(`${this.api}/api/post/getAllPosts`,{params})
    }
    
  }


  getCategoryByName(categoryname:any){
    return this.http.get(`${this.api}/api/category/name/${categoryname}`)
  }

  disablePost(id:any): Observable<any>{
    return this.http.put(`${this.api}/api/post/disablePostById/${id}`,{})
  }
}
