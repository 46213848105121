<div class="card m-3">
  <span class="daily-news__head card-header">Daily News</span>
  <ul class="list-group list-group-flush" *ngFor="let data of dailyNewsList; let index = index">
    <li *ngIf="index < 5" class="list-group-item daily-news__article">
      <img *ngIf="data.categoryId != 10 && data.categoryId != 13" alt="{{data.imageAlt??'cbw-img'}}"
        class="daily-news__article__img" src="{{ data.postImageURL }}" />
      <img *ngIf="data.categoryId == 10 || data.categoryId == 13" alt="{{data.imageAlt??'cbw-img'}}"
        class="daily-news__article__img" src="{{ data.postThumbnailURL }}" />
      <span class="daily-news__article__content">
        <a routerLink="/articles-preview/{{ data.postTitle | urlPrettier }}" class="daily-news__article__content__text">
          {{ data.postTitle }}
        </a>
        <span class="daily-news__article__content__date">{{
          data.createdDateTime | date: 'yyyy-MM-dd HH:mm:ss'
          }}</span>
      </span>
    </li>
  </ul>
  <div class="daily-news__bottom">
    <span routerLink="/cbw-Article/DailyNews" class="daily-news__bottom__text-link">MORE FROM DAILY NEWS ></span>
  </div>
</div>
