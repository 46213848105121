import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './pages/register/register.component';
import { NgxCaptchaModule } from '@binssoft/ngx-captcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

import { CaptchaComponent } from './components/captcha/captcha.component';
import { HomeComponent } from './pages/home/home.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CreatePostComponent } from './pages/create-post/create-post.component';
import { HttpClientModule } from '@angular/common/http';
import { PostListComponent } from './pages/post-list/post-list.component';
import { CreateInterviewComponent } from './pages/create-interview/create-interview.component';
import { InterviewListComponent } from './pages/interview-list/interview-list.component';
import { UIMODULES } from './ui-modules/ui.modules';
import { PressReleaseComponent } from './pages/press-release/press-release.component';
import { PressReleaseListComponent } from './pages/press-release-list/press-release-list.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FaqListComponent } from './pages/faq-list/faq-list.component';
import { YotubeVideoLinksComponent } from './pages/yotube-video-links/yotube-video-links.component';
import { MainDashboardComponent } from './pages/main-dashboard/main-dashboard.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { RecommendedNewsComponent } from './pages/recommended-news/recommended-news.component';
import { ApproveRejectComponent } from './components/approve-reject/approve-reject.component';
import { UserEmailVerificationComponent } from './components/user-email-verification/user-email-verification.component';
import { MessageService } from 'primeng/api';
import { ArticleDetailComponent } from './pages/article-detail/article-detail.component';
import { ArticleComponent } from './components/article/article.component';
import { DailyNewsComponent } from './pages/daily-news/daily-news.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AllArticlesComponent } from './pages/market/all-articles/all-articles.component';
import { EditorsChoiceComponent } from './pages/market/editors-choice/editors-choice.component';
import { FeaturedArticlesComponent } from './pages/market/featured-articles/featured-articles.component';
import { HotStoriesComponent } from './pages/market/hot-stories/hot-stories.component';
import { TrendingArticlesComponent } from './pages/market/trending-articles/trending-articles.component';
import { HowToBecomeEaComponent } from './pages/external-analyst/how-to-become-ea/how-to-become-ea.component';
import { BasicRatingComponent } from './pages/index/basic-rating/basic-rating.component';
import { CarCompositeIndexComponent } from './pages/index/car-composite-index/car-composite-index.component';
import { IndexGovernanceComponent } from './pages/index/index-governance/index-governance.component';
import { IndexMethodologyComponent } from './pages/index/index-methodology/index-methodology.component';
import { BreakingNewsComponent } from './pages/breaking-news/breaking-news.component';
import { TrendingAnalysisArticlesComponent } from './pages/trending-analysis-articles/trending-analysis-articles.component';
import { HomepageRecommendedNewsComponent } from './pages/homepage-recommended-news/homepage-recommended-news.component';
import { HomepagePressReleasesComponent } from './pages/homepage-press-releases/homepage-press-releases.component';
import { HomepageBreakingNewsComponent } from './pages/homepage-breaking-news/homepage-breaking-news.component';
import { HomepageEditorsChoiceComponent } from './pages/homepage-editors-choice/homepage-editors-choice.component';
import { HomepageHotStoriesComponent } from './pages/homepage-hot-stories/homepage-hot-stories.component';
import { HomepageTwitterFeedsComponent } from './pages/homepage-twitter-feeds/homepage-twitter-feeds.component';
import { HomepageTrendingVideosComponent } from './pages/homepage-trending-videos/homepage-trending-videos.component';
import { EditorsChoiceCompComponent } from './pages/editors-choice-comp/editors-choice-comp.component';
import { PressReleasePageComponent } from './pages/press-release-page/press-release-page.component';
import { EventsComponent } from './pages/events/events.component';
import { LatestBreakingNewsCompComponent } from './pages/latest-breaking-news-comp/latest-breaking-news-comp.component';
import { VideosComponent } from './pages/videos/videos.component';
import { LearnComponent } from './pages/learn/learn.component';
import { RecommendedNewsPageComponent } from './pages/recommended-news-page/recommended-news-page.component';
import { BreakingNewsPageComponent } from './pages/breaking-news-page/breaking-news-page.component';
import { DailyNewsPageComponent } from './pages/daily-news-page/daily-news-page.component';
import { InspirationsComponent } from './pages/inspirations/inspirations.component';
import { HotStoriesCompComponent } from './pages/hot-stories-comp/hot-stories-comp.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { UBlockUiComponent } from './components/u-block-ui/u-block-ui.component';
import { ArticleDetailAllUserComponent } from './pages/article-detail-all-user/article-detail-all-user.component';
import { ArtilesAllComponent } from './components/artiles-all/artiles-all.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { InspirationDetailPageComponent } from './pages/inspiration-detail-page/inspiration-detail-page.component';
import { LearnCategoryDetailsComponent } from './pages/learn-category-details/learn-category-details.component';
import { UrlPrettierPipe } from './shared/config/pipes/url-prettier.pipe';
import { CommonModule } from '@angular/common';
import { ContextMenuModule } from 'primeng/contextmenu';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    HomeComponent,
    CaptchaComponent,
    LoginComponent,
    SidebarComponent,
    DashboardComponent,
    AboutComponent,
    ContactComponent,
    CreatePostComponent,
    UserProfileComponent,
    PostListComponent,
    CreateInterviewComponent,
    InterviewListComponent,
    PressReleaseComponent,
    PressReleaseListComponent,
    FaqComponent,
    FaqListComponent,
    YotubeVideoLinksComponent,
    MainDashboardComponent,
    UserListComponent,
    RecommendedNewsComponent,
    ApproveRejectComponent,
    UserEmailVerificationComponent,
    ArticleDetailComponent,
    ArticleComponent,
    DailyNewsComponent,
    HeaderComponent,
    FooterComponent,
    AllArticlesComponent,
    EditorsChoiceComponent,
    FeaturedArticlesComponent,
    HotStoriesComponent,
    TrendingArticlesComponent,
    CarCompositeIndexComponent,
    BasicRatingComponent,
    IndexMethodologyComponent,
    IndexGovernanceComponent,
    HowToBecomeEaComponent,
    BreakingNewsComponent,
    TrendingAnalysisArticlesComponent,
    HomepageRecommendedNewsComponent,
    HomepagePressReleasesComponent,
    HomepageBreakingNewsComponent,
    HomepageEditorsChoiceComponent,
    HomepageHotStoriesComponent,
    HomepageTwitterFeedsComponent,
    HomepageTrendingVideosComponent,
    EditorsChoiceCompComponent,
    PressReleasePageComponent,
    EventsComponent,
    LatestBreakingNewsCompComponent,
    VideosComponent,
    LearnComponent,
    RecommendedNewsPageComponent,
    BreakingNewsPageComponent,
    DailyNewsPageComponent,
    InspirationsComponent,
    HotStoriesCompComponent,
    ForgotPasswordComponent,
    UBlockUiComponent,
    ArticleDetailAllUserComponent,
    ArtilesAllComponent,
    InspirationDetailPageComponent,
    LearnCategoryDetailsComponent,
    UrlPrettierPipe,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    DisclaimerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    NgxCaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ContextMenuModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right', // Adjust the position as per your preference
      closeButton: true, // Show close button on notifications
      timeOut: 3000, // Set the duration for how long the notifications should be displayed (in milliseconds)
    }),
    NgxPaginationModule,
    ...UIMODULES,
  ],
  providers: [MessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
