<div class="learn">
  <div class="learn--main">
    <img alt="cbw-img" class="learn--main--img" src="assets/images/learn-crypto.png" />
    <!-- <div class="learn--main--search">
      <input
        class="learn--main--search--input"
        placeholder="Search Your Query"
      />
      <span class="learn--main--search--btn">Search</span>
    </div> -->
  </div>
  <div class="learn--main--results">
    <div class="learn--main--results--cards" *ngFor="let data of learnCatgoriesList">
      <!-- <img alt="cbw-img" src="{{ data.img }}" /> -->
      <img alt="cbw-img" class="cbw-img" src="assets/images/General-learn.jpg" />
      <span class="learn--main--results--cards--title"
        routerLink="/crypto-business-world-learn/{{data.FAQCategoryId}}/{{data.FAQCategoryTitle}}"
        [state]="{id: data.FAQCategoryId, title: data.FAQCategoryTitle, description: data.FAQCategoryDescription}">{{
        data.FAQCategoryTitle
        }}</span>
      <span class="learn--main--results--cards--description">{{
        data.FAQCategoryDescription
        }}</span>
    </div>
  </div>
</div>
