import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-hot-stories',
  templateUrl: './hot-stories.component.html',
  styleUrls: ['./hot-stories.component.scss'],
})
export class HotStoriesComponent {
  showComment: { [key: number]: boolean } = {};
  titleSearchValue: string = '';
  startDate: Date;
  endDate: Date;
  resetDate: Date;

  hotStoriesList: any = [];
  allUsersList: any = [];
  selectedPublisher: any;

  page: number = 1;
  totalItems: number;

  items: any
  home: any
  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.getAllUsers();
    this.getHotStoriesCountWithValues();

    this.items = [{ label: 'Home', routerLink:'/' }, { label: 'Hot Stories', routerLink:'/crypto-business-hot-story' }];


  }

  reset() {
    this.startDate = this.resetDate;
    this.endDate = this.resetDate;
    this.titleSearchValue = "";
    this.selectedPublisher = undefined;
    this.getHotStoriesCountWithValues();
  }

  getHotStoriesCountWithValues() {
    let startdate;
    let startmonth;
    let startyear;
    if (this.startDate != undefined) {
      startdate = this.startDate.toString().split(" ")[2];
      let startMonthName = this.startDate.toString().split(" ")[1];
      if (startMonthName == "Jan") {
        startmonth = 1;
      }
      if (startMonthName == "Feb") {
        startmonth = 2;
      }
      if (startMonthName == "Mar") {
        startmonth = 3;
      }
      if (startMonthName == "Apr") {
        startmonth = 4;
      }
      if (startMonthName == "May") {
        startmonth = 5;
      }
      if (startMonthName == "Jun") {
        startmonth = 6;
      }
      if (startMonthName == "Jul") {
        startmonth = 7;
      }
      if (startMonthName == "Aug") {
        startmonth = 8;
      }
      if (startMonthName == "Sep") {
        startmonth = 9;
      }
      if (startMonthName == "Oct") {
        startmonth = 10;
      }
      if (startMonthName == "Nov") {
        startmonth = 11;
      }
      if (startMonthName == "Dec") {
        startmonth = 12;
      }
      startyear = this.startDate.toString().split(" ")[3];
    }
    const convertedStartDate = this.startDate != undefined ? startyear + "-" + startmonth + "-" + startdate : this.startDate;

    let enddate;
    let endmonth;
    let endyear;
    if (this.endDate != undefined) {
      enddate = this.endDate.toString().split(" ")[2];
      let endMonthName = this.endDate.toString().split(" ")[1];
      if (endMonthName == "Jan") {
        endmonth = 1;
      }
      if (endMonthName == "Feb") {
        endmonth = 2;
      }
      if (endMonthName == "Mar") {
        endmonth = 3;
      }
      if (endMonthName == "Apr") {
        endmonth = 4;
      }
      if (endMonthName == "May") {
        endmonth = 5;
      }
      if (endMonthName == "Jun") {
        endmonth = 6;
      }
      if (endMonthName == "Jul") {
        endmonth = 7;
      }
      if (endMonthName == "Aug") {
        endmonth = 8;
      }
      if (endMonthName == "Sep") {
        endmonth = 9;
      }
      if (endMonthName == "Oct") {
        endmonth = 10;
      }
      if (endMonthName == "Nov") {
        endmonth = 11;
      }
      if (endMonthName == "Dec") {
        endmonth = 12;
      }
      endyear = this.endDate.toString().split(" ")[3];
    }
    const convertedEndDate = this.endDate != undefined ? endyear + "-" + endmonth + "-" + enddate : this.endDate;
    const pubId =
      this.selectedPublisher == undefined ? -1 : this.selectedPublisher.userId;

    this.authService.getHotStoriesCountWithValues(pubId, this.titleSearchValue, convertedStartDate, convertedEndDate).subscribe(
      (response: any) => {
        this.totalItems = response;
        this.getHotStories();
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }

  getAllUsers() {
    this.authService.getAllUsers().subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          this.allUsersList[index] = {
            userId: element.userId,
            name: element.firstName + ' ' + element.lastName,
          };
        });
        console.log('allUsersList: ', this.allUsersList);
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }

  pageChange(event: any) {
    this.page = event;
    this.getHotStoriesCountWithValues();
  }

  getHotStories() {
    this.hotStoriesList = [];
    const pubId =
      this.selectedPublisher == undefined ? -1 : this.selectedPublisher.userId;
    this.authService.getHotStories(pubId, false, true, this.page).subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          this.hotStoriesList[index] = element;
        });
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }

  getHotStoriesByTitle(title: string) {
    this.hotStoriesList = [];
    const pubId =
      this.selectedPublisher == undefined ? -1 : this.selectedPublisher.userId;

    this.authService.getHotStoriesCountWithValues(pubId, this.titleSearchValue, undefined, undefined).subscribe(
      (response: any) => {
        this.totalItems = response;
        this.authService.getHotStoriesByTitle(pubId, false, true, title, this.page).subscribe(
          (response: any) => {
            response.forEach((element: any, index: number) => {
              this.hotStoriesList[index] = element;
            });
          },
          (error: any) => {
            console.log('Error: ', error);
          }
        );
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );

  }

  getHotStoriesByDate() {
    this.hotStoriesList = [];

    let startdate;
    let startmonth;
    let startyear;
    if (this.startDate != undefined) {
      startdate = this.startDate.toString().split(" ")[2];
      let startMonthName = this.startDate.toString().split(" ")[1];
      if (startMonthName == "Jan") {
        startmonth = 1;
      }
      if (startMonthName == "Feb") {
        startmonth = 2;
      }
      if (startMonthName == "Mar") {
        startmonth = 3;
      }
      if (startMonthName == "Apr") {
        startmonth = 4;
      }
      if (startMonthName == "May") {
        startmonth = 5;
      }
      if (startMonthName == "Jun") {
        startmonth = 6;
      }
      if (startMonthName == "Jul") {
        startmonth = 7;
      }
      if (startMonthName == "Aug") {
        startmonth = 8;
      }
      if (startMonthName == "Sep") {
        startmonth = 9;
      }
      if (startMonthName == "Oct") {
        startmonth = 10;
      }
      if (startMonthName == "Nov") {
        startmonth = 11;
      }
      if (startMonthName == "Dec") {
        startmonth = 12;
      }
      startyear = this.startDate.toString().split(" ")[3];
    }
    const convertedStartDate = this.startDate != undefined ? startyear + "-" + startmonth + "-" + startdate : this.startDate;

    let enddate;
    let endmonth;
    let endyear;
    if (this.endDate != undefined) {
      enddate = this.endDate.toString().split(" ")[2];
      let endMonthName = this.endDate.toString().split(" ")[1];
      if (endMonthName == "Jan") {
        endmonth = 1;
      }
      if (endMonthName == "Feb") {
        endmonth = 2;
      }
      if (endMonthName == "Mar") {
        endmonth = 3;
      }
      if (endMonthName == "Apr") {
        endmonth = 4;
      }
      if (endMonthName == "May") {
        endmonth = 5;
      }
      if (endMonthName == "Jun") {
        endmonth = 6;
      }
      if (endMonthName == "Jul") {
        endmonth = 7;
      }
      if (endMonthName == "Aug") {
        endmonth = 8;
      }
      if (endMonthName == "Sep") {
        endmonth = 9;
      }
      if (endMonthName == "Oct") {
        endmonth = 10;
      }
      if (endMonthName == "Nov") {
        endmonth = 11;
      }
      if (endMonthName == "Dec") {
        endmonth = 12;
      }
      endyear = this.endDate.toString().split(" ")[3];
    }
    const convertedEndDate = this.endDate != undefined ? endyear + "-" + endmonth + "-" + enddate : this.endDate;

    const pubId =
      this.selectedPublisher == undefined ? -1 : this.selectedPublisher.userId;

    this.authService.getHotStoriesCountWithValues(pubId, this.titleSearchValue, convertedStartDate, convertedEndDate).subscribe(
      (response: any) => {
        this.totalItems = response;
        this.authService
          .getHotStoriesByDate(
            pubId,
            false,
            true,
            convertedStartDate,
            convertedEndDate,
            this.page
          )
          .subscribe(
            (response: any) => {
              response.forEach((element: any, index: number) => {
                this.hotStoriesList[index] = element;
              });
            },
            (error: any) => {
              console.log('Error: ', error);
            }
          );
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );

  }

  getHotStoriesByDateAndTitle(title: string) {
    this.hotStoriesList = [];

    let startdate;
    let startmonth;
    let startyear;
    if (this.startDate != undefined) {
      startdate = this.startDate.toString().split(" ")[2];
      let startMonthName = this.startDate.toString().split(" ")[1];
      if (startMonthName == "Jan") {
        startmonth = 1;
      }
      if (startMonthName == "Feb") {
        startmonth = 2;
      }
      if (startMonthName == "Mar") {
        startmonth = 3;
      }
      if (startMonthName == "Apr") {
        startmonth = 4;
      }
      if (startMonthName == "May") {
        startmonth = 5;
      }
      if (startMonthName == "Jun") {
        startmonth = 6;
      }
      if (startMonthName == "Jul") {
        startmonth = 7;
      }
      if (startMonthName == "Aug") {
        startmonth = 8;
      }
      if (startMonthName == "Sep") {
        startmonth = 9;
      }
      if (startMonthName == "Oct") {
        startmonth = 10;
      }
      if (startMonthName == "Nov") {
        startmonth = 11;
      }
      if (startMonthName == "Dec") {
        startmonth = 12;
      }
      startyear = this.startDate.toString().split(" ")[3];
    }
    const convertedStartDate = this.startDate != undefined ? startyear + "-" + startmonth + "-" + startdate : this.startDate;

    let enddate;
    let endmonth;
    let endyear;
    if (this.endDate != undefined) {
      enddate = this.endDate.toString().split(" ")[2];
      let endMonthName = this.endDate.toString().split(" ")[1];
      if (endMonthName == "Jan") {
        endmonth = 1;
      }
      if (endMonthName == "Feb") {
        endmonth = 2;
      }
      if (endMonthName == "Mar") {
        endmonth = 3;
      }
      if (endMonthName == "Apr") {
        endmonth = 4;
      }
      if (endMonthName == "May") {
        endmonth = 5;
      }
      if (endMonthName == "Jun") {
        endmonth = 6;
      }
      if (endMonthName == "Jul") {
        endmonth = 7;
      }
      if (endMonthName == "Aug") {
        endmonth = 8;
      }
      if (endMonthName == "Sep") {
        endmonth = 9;
      }
      if (endMonthName == "Oct") {
        endmonth = 10;
      }
      if (endMonthName == "Nov") {
        endmonth = 11;
      }
      if (endMonthName == "Dec") {
        endmonth = 12;
      }
      endyear = this.endDate.toString().split(" ")[3];
    }
    const convertedEndDate = this.endDate != undefined ? endyear + "-" + endmonth + "-" + enddate : this.endDate;

    const pubId =
      this.selectedPublisher == undefined ? -1 : this.selectedPublisher.userId;

    this.authService.getHotStoriesCountWithValues(pubId, this.titleSearchValue, convertedStartDate, convertedEndDate).subscribe(
      (response: any) => {
        this.totalItems = response;
        this.authService
          .getHotStoriesByDateAndTitle(
            pubId,
            false,
            true,
            convertedStartDate,
            convertedEndDate,
            title,
            this.page
          )
          .subscribe(
            (response: any) => {
              response.forEach((element: any, index: number) => {
                this.hotStoriesList[index] = element;
              });
            },
            (error: any) => {
              console.log('Error: ', error);
            }
          );
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );

  }

  search(title: string, isSearch: boolean) {
    if (isSearch) {
      this.page = 1;
    }
    if (
      this.titleSearchValue != '' &&
      (this.startDate != undefined ||
        this.endDate != undefined)
    ) {
      this.getHotStoriesByDateAndTitle(title);
    }
    if (
      this.titleSearchValue != '' &&
      (this.startDate == undefined && this.endDate == undefined)
    ) {
      this.getHotStoriesByTitle(title);
    }
    if (
      this.titleSearchValue == '' &&
      (this.startDate != undefined ||
        this.endDate != undefined)
    ) {
      this.getHotStoriesByDate();
    }
    if (
      (this.selectedPublisher != undefined || this.selectedPublisher == undefined) &&
      this.titleSearchValue == '' &&
      (this.startDate == undefined && this.endDate == undefined)
    ) {
      this.getHotStories();
    }
  }
}
