<p-toast position="top-right" [style]="{'font-size':'14px'}" [preventOpenDuplicates]="true"></p-toast>

<app-u-block-ui *ngIf="isAuthSide && !pageLoaded" style="z-Index:10000444;position:relative"></app-u-block-ui>
<app-header *ngIf="!isAuthSide"></app-header>
<router-outlet></router-outlet>
<app-footer *ngIf="!isAuthSide"></app-footer>

<div id="cookies-popup" class="cookies-popup">
  <p>We use cookies to improve your experience on our website. By clicking "Accept All," you agree to our Privacy Policy.</p>
  <button id="accept-all-btn" class="accept-btn">Accept All</button>
</div>

