<div class="trending-videos">
  <span class="trending-videos--head">Trending Videos</span>
  <div class="orange-line"></div>
  <div class="trending-videos--body">
    <div class="trending-videos--body--box" *ngFor="let data of trendingVideosList; let index = index">
      <div *ngIf="index < 4" class="trending-videos--body--boxes">
        <span>
          <video controls controlsList="nodownload" class="trending-videos--body--boxes--video"
            src="{{ data.postVideoURL }}"></video>
        </span>
        <a routerLink="/articles-preview/{{
          data.postTitle  | urlPrettier
        }}" class="trending-videos--body--boxes--description">{{
          data.postTitle
          }}</a>
        <!-- <span class="trending-videos--body--boxes--views"
          >{{ data.viewsCount }} View(s)</span
        > -->
      </div>
    </div>
  </div>
</div>