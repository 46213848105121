import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.scss'],
})
export class LearnComponent {
  learnCatgoriesList: any = [
  ];

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.getAllFaqCategory();
  }

  getAllFaqCategory() {
    this.learnCatgoriesList = [];
    this.authService.getAllFaqCategory().subscribe(
      (response: any) => {
        response.forEach((element: any, index: number) => {
          this.learnCatgoriesList[index] = element;
        });
        console.log('this.learnCatgoriesList: ', this.learnCatgoriesList);
      },
      (error: any) => {
        console.log('Error: ', error);
      }
    );
  }
}
