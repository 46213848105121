import { Component } from '@angular/core';
import { NgxCaptchaService } from '@binssoft/ngx-captcha';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent {

  constructor(){
    
  }
  captchaConfig:any = {
    type:1, 
    length:6, 
    cssClass:'customCaptcha',
    back: {
     stroke:"#2F9688",
     solid:"#f2efd2"
    } , 
    font:{
      color:"#000000", 
      size:"35px"
    }
  };

}
