import { Component, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, map, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PostListService } from 'src/app/shared/services/post-list.service';
import { PostService } from 'src/app/shared/services/post.service';

@Component({
  selector: 'app-recommended-news',
  templateUrl: './recommended-news.component.html',
  styleUrls: ['./recommended-news.component.scss']
})
export class RecommendedNewsComponent {

  private unsubscribe$ = new Subject<void>();

  filterBy = [
    { label: 'All', value: 'all' },
    { label: 'Draft', value: 'drafted' },
    { label: 'Approval Pending', value: 'pendingApproval' },
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },
    
];
  recommendedNewsList:any;
  loggedInUser:any;
  newsForm:FormGroup;
  id:any='';
  allRecommendedNewsList:any;
  constructor(private activeRoute : ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private postService: PostService,
    private postListService: PostListService,
    private authService: AuthService,
    private renderer: Renderer2){}

    ngOnInit(){
    this._initNewsForm();
    this.loggedInUser=this.authService.getUser();
    this.getNewsList()
}

_initNewsForm(){
  this.newsForm=this.fb.group({
    postTitle:[''],
    categoryId:[1],
    shortDescription:[''],
    postURL:['',[Validators.compose([Validators.required,Validators.pattern(/^[a-zA-Z0-9\-]+$/)])]],
    interviewDate:[new Date()],
    industryId:[1],
    ownerId:[1],
    status:['approved'],
    postTags:[''],
    isHotStory:[false],
    isShownAsBanner:[false],
    postHeading:['']
 
  })
}


save(){
  console.log(this.newsForm.value)
  this.authService.hasPageLoaded.next(false)

  let post = this.postService.createPost(this.newsForm.value)
  if(this.id){
    post =this.postService.updatePost(this.newsForm.value,this.id)
  }

  post.pipe(takeUntil(this.unsubscribe$))
  .subscribe({
    next:(post) => {
      this.newsForm.reset();
    this.notificationService.showSuccess(post.message);
      setTimeout(() => {
        location.reload();
      }, 1000);
  },
  error:(err) => {
    this.notificationService.showError(err.error.detail??'Something went wrong')
    this.authService.hasPageLoaded.next(true)
  },
  complete:()=>{
    this.authService.hasPageLoaded.next(true)
   }
})
}


deleteRecord(id:string){
  this.authService.hasPageLoaded.next(false)
  this.postListService.disablePost(id).pipe(takeUntil(this.unsubscribe$)).subscribe({
    next:res => {
    console.log(res);
    this.authService.hasPageLoaded.next(true)
    this.notificationService.showSuccess('Post Deleted Successfully!')
    // setTimeout(() => {
    //   location.reload();
    // }, 1000);
    this.recommendedNewsList=this.recommendedNewsList.filter((post:any) => post.postId != id)
  },
  error:err=>{
    console.log(err);
    this.authService.hasPageLoaded.next(true)
    this.notificationService.showError('Something went Wrong!')
  }
})
}

getNewsList(){
  forkJoin([this.postListService.getPostByCategory(1),this.postListService.getAllUser()]).pipe(takeUntil(this.unsubscribe$),
    map(res => {
      return res[0].map((news:any) => {
        return {...news,...res[1].find((u:any) => u.userId == news.ownerId)}
      })
    })
  )
  .subscribe({
    next:(post) => {
      this.recommendedNewsList = post
      this.allRecommendedNewsList=[...this.recommendedNewsList]
      this.authService.hasPageLoaded.next(true)
  },
  error:(err) => {
    this.notificationService.showError(err.error.detail??'Something went wrong')
    this.authService.hasPageLoaded.next(true)
  }
})
}

editRecord(id:any){
  this.id=id;
  const newsList = JSON.parse(JSON.stringify(this.recommendedNewsList))
  console.log(newsList)
  let news = newsList.find((n:any) => n.postId == id)
  if(news && !isNaN(new Date(news.interviewDate).getTime())){
    news.interviewDate = new Date(news.interviewDate)
  }else{
    news.interviewDate = new Date(news.createdDateTime)
  }
  this.newsForm.patchValue(news)
}

onSelectionChange(event :any) {
  if(event.value == 'all'){
    this.recommendedNewsList = this.allRecommendedNewsList
    return;
  }
  
  this.recommendedNewsList=this.allRecommendedNewsList.filter((post:any) => post.status == event.value)
}

ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}

}
