<p-card header="Create New FAQ">
    <div class="row">
        <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="cat">FAQ Category</label>
                <p-dropdown [options]="faqCategories"></p-dropdown>
            </div>
        </div>
        <div class="col-6  col-sm-12 col-lg-6 mb-2"></div>

        <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-3 d-flex align-items-end">
            <div class="gap-2 w-full">
                <label htmlFor="question">Question</label>
                <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
            </div>
        </div>
        
        <div class="row">
            <div class="col-12 mb-3">
                <label htmlFor="Answer">Answer</label>
                <p-editor [style]="{ height: '220px' }"></p-editor>
            </div>
        </div>


    </div>

    <div class="row">
        <div class="col-12 d-flex justify-content-end align-items-end gap-3">
            <p-button styleClass="p-button-sm" label="Submit"></p-button>
            <p-button styleClass="p-button-sm" label="Save"></p-button>
        </div>
    </div>
</p-card>