

<p-card class="post-card">
  <!-- <div class="container-fluid"> -->
  <!-- <div *ngIf="!isAllLoaded" class="d-flex justify-content-center">
    <img alt="cbw-img" class="border-circle basic-spinner" src="assets/images/basic-spinner.gif" alt="">
  </div> -->

    <div *ngIf="isAllLoaded" class="row user-list-container">
        <div class="col">
            <h3>User List</h3>
            <p-table #dt [value]="userList" scrollDirection="horizontal" [style]="{ 'width': 'calc(100vw - 310px)' }" [responsive]="true" responsiveLayout="stack" [breakpoint]="'960px'" [rows]="10"
                [paginator]="true" [rowsPerPageOptions]="[10, 20, 50, 100, 200, 300, 500]"
                [globalFilterFields]="['firstName','phone','email','country','roleName']"
                styleClass=" p-datatable-gridlines p-datatable-striped p-datatable-sm" selectionMode="single"
                [rowHover]="true">
                <ng-template pTemplate="caption">
                    <div class="flex p-fluid justify-content-between">
                        <div class="">
                            <div class="flex flex-row md:flex-row gap-3 md:justify-content-between">
                                <p-dropdown [options]="roleList" [(ngModel)]="selectedRole" placeholder="" (onChange)="onSelectionRoleChange($event)" styleClass="p-inputtext-sm mb-2 md:mb-0 " />

                                <p-dropdown [options]="filterBy" placeholder="" (onChange)="onSelectionStatusChange($event)" styleClass="p-inputtext-sm mb-2 md:mb-0 " />
                            </div>
                        </div>

                        <div class="">
                          <span class="p-input-icon-left ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="p-inputtext-sm" #textInput
                              (input)="dt.filterGlobal(textInput.value, 'contains')"
                              placeholder="Search keyword" />
                          </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th [pSortableColumn]="'firstName'">Name
                            <p-sortIcon [field]="'firstName'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'mobile'">Mobile</th>
                        <th [pSortableColumn]="'email'">Email</th>
                        <th [pSortableColumn]="'country'">Country</th>
                        <th [pSortableColumn]="'rolename'">Role Name</th>

                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" (dblclick)="editRecord(rowData.id)">
                        <td><span class="p-column-firstName"></span>{{rowData?.firstName + ' ' + rowData?.lastName}}</td>
                        <td><span class="p-column-phone"></span>{{rowData?.phone}}</td>
                        <td><span class="p-column-email"></span>{{rowData?.email}}</td>
                        <td><span class="p-column-country"></span>{{rowData?.country}}</td>
                        <td><span class="p-column-roleName"></span>{{rowData?.roleName}}</td>

                        <td class="">
                          <!-- 1) show details button only when rowData.isEmailverfied is false.
                          2) show approve, reject, details button when rowData.isEmail is true and rowData.isApproved is false.
                          3) show details, approved button when rowData rowData.isEmail is true and rowData.isApproved is false.
                          4) show details, rejected button when rowData rowData.isEmail is true and rowData.isApproved is false and rowData.updatedBy has any integer value -->

                          <!-- Rejected Status -->
                          <div class="" *ngIf="rowData.isEmailVerified == true && !rowData.isApproved && rowData.updatedBy">
                            <span class="flex gap-3">
                              <a href="javascript:void();" (click)="editRecord(rowData?.userId)">Details</a>
                              <span>Rejected</span>
                            </span>
                          </div>

                          <!-- <button (click)="logRowData(rowData)">Log Row Data</button> -->

                          <!-- Approved Status -->
                          <div class="" *ngIf="rowData.isEmailVerified && rowData.isApproved">
                            <span class="flex gap-3">
                              <a href="javascript:void();" (click)="editRecord(rowData?.userId)">Details</a>
                              <span>Approved</span>
                            </span>
                          </div>

                          <!-- Email Not Verified -->
                          <div class="" *ngIf="!rowData.isEmailVerified && !rowData.isApproved">
                            <span class="flex gap-3">
                              <a href="javascript:void();" (click)="editRecord(rowData?.userId)">Details</a>
                            </span>
                          </div>

                          <!-- Email Verified -->
                          <div class="button-group-details" *ngIf="rowData.isEmailVerified && !rowData.isApproved && !rowData.updatedBy">
                            <app-approve-reject (approveReject)="catchReview($event,rowData)" [buttonVisible]="'both'"/>
                            <span class="flex gap-3 details">
                              <a href="javascript:void();" (click)="editRecord(rowData?.userId)">Details</a>
                            </span>
                          </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No Records found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
  <!-- </div> -->
</p-card>

<p-dialog header="Rejection Reason" [position]="'right'" [(visible)]="rejectionReasonDialog" [modal]="true" [style]="{ width: '35vw' }" [draggable]="true" [resizable]="true">
  <div class="m-0 p-fluid">
    <textarea rows="2" pInputTextarea [(ngModel)]="rejectionReasonMessage" placeholder="Specify rejection reason.."></textarea>
    <p class="" *ngIf="rejectionMessageEmpty">Please enter the rejection message.</p>
    <app-approve-reject [buttonVisible]="'reject'" (approveReject)="rejectUser()"></app-approve-reject>
  </div>
</p-dialog>
