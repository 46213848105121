import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, forkJoin, map, Subject, switchMap, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PostListService } from 'src/app/shared/services/post-list.service';
import { PostService } from 'src/app/shared/services/post.service';
import { PressReleaseService } from 'src/app/shared/services/press-release.service';

@Component({
  selector: 'app-press-release-list',
  templateUrl: './press-release-list.component.html',
  styleUrls: ['./press-release-list.component.scss']
})
export class PressReleaseListComponent {

  private unsubscribe$ = new Subject<void>();
  userType ='Admin'
  loggedInUser:any;
  filterBy = [
    { label: 'All', value: 'all' },
    { label: 'Draft', value: 'drafted' },
    { label: 'Approval Pending', value: 'pendingApproval' },
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },
];

  selectedFilter:'all'
  allPressRelease:any;
  pressReleaseList:any;
  totalRecords=100000;
  selectedPosts:any[] = [];

  rejectionReasonMessage: string = ''
  rejectionReasonDialog: boolean = false;
  userSelectedForReject: any;
  rejectionMessageEmpty: boolean = false;
  bulkReject=false;

  userList:any;
  constructor(private activeRoute : ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService,
    private pressService: PressReleaseService,
    private postService: PostService,
    private postListService: PostListService){}

  ngOnInit(){
    // this._checkActiveRoute();
    this.authService.loggedInUser.subscribe(res => {
      this.loggedInUser = res.user;
      this.userType=res.user.roleName;
    })
    this.getUsers();
    // this.getPressReleaseListData();
  }


  onSelectionChange(event :any){
    if(event.value == 'all'){
      this.pressReleaseList = this.allPressRelease
      return;
    }
    
    this.pressReleaseList=this.allPressRelease.filter((post:any) => post.status == event.value)
  }

  editRecord(id:string){
    console.log(id)
    this.router.navigate(['dashboard/press-release/edit/'+id])
  }

  deleteRecord(id:string){
    this.authService.hasPageLoaded.next(false)
    this.pressService.disablePost(id).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next:res => {
      console.log(res);
      this.authService.hasPageLoaded.next(true)
      this.notificationService.showSuccess('Post Deleted Successfully!')
      // setTimeout(() => {
      //   location.reload();
      // }, 1000);
      this.pressReleaseList=this.pressReleaseList.filter((post:any) => post.postId != id)
    },
    error:err=>{
      console.log(err);
      this.authService.hasPageLoaded.next(true)
      this.notificationService.showError('Something went Wrong!')
    }
  })
  }

  getUsers(){
    this.postListService.getAllUser().subscribe({
      next:res =>{
      this.userList = res
    },
    error:err =>{
      console.log(err);
    }
  })
  }
  
  getPressReleaseListData(event:any){
    this.authService.hasPageLoaded.next(false)
    this.pressService.getCategoryByName('Press').pipe(
      switchMap( (res:any) => {
        return forkJoin([this.pressService.getPressReleaseList(res.categoryId,this.loggedInUser,event,this.selectedFilter)])
        .pipe(
          map((res:any) => {
            console.log(res)
            return res[0].map((press:any) => {
              return {...press,user:{...this.userList.find((u:any) => u.userId == press.ownerId)}}
            })
          })
        )
      })
    )
    .pipe(takeUntil(this.unsubscribe$)).subscribe({
      next:res => {
        console.log(res)
        this.pressReleaseList=res
        this.allPressRelease=[...this.pressReleaseList]
        this.authService.hasPageLoaded.next(true)
    },
    error:err=>{
      console.log(err)
      this.authService.hasPageLoaded.next(true)
    }
  })
  }

  catchReview(event: any,postid:any){
    console.log(event,postid)
    // if(!this.selectedPosts.length) return;
    this.userSelectedForReject=postid;
    if(event.trigger == 'reject'){
      this.rejectionReasonDialog=true;
      return;
    }else{
      this.updatePostStatus('approved',postid);
    }
  }

  rejectPost(){
   if(!this.rejectionReasonMessage) {
        this.rejectionMessageEmpty = true
        return;
      } else {
        this.rejectionMessageEmpty = false
      }
  
      this.rejectionReasonDialog = false;
      if(this.bulkReject){
        this.multipleSelectionReview({trigger:'reject'},false)
      }else{
        this.updatePostStatus('rejected',this.userSelectedForReject,this.rejectionReasonMessage);
      }
      
  
  }
  
  updatePostStatus(status:any,postid:any, remarks=''){
    console.log(status)
    const query = {
      adminId: this.loggedInUser.userId,
      status: status,
      remarks: remarks
    }
  
    this.authService.hasPageLoaded.next(false)

      this.postListService.updatePostStatus(postid,query)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res =>{
          console.log(res)
          this.authService.hasPageLoaded.next(true)
          this.notificationService.showSuccess(`Post(s) ${status} successfully`)
          
        },
        error:err=>{
          this.notificationService.showError('Something went wrong')
          console.log(err)
          this.authService.hasPageLoaded.next(true)
        },
        complete:()=> {
          setTimeout(() => {
            // this.getPressReleaseListData();
            location.reload()
          }, 1000);
        }
      })
  
    
  }
  
  multipleSelectionReview(event: any,initial=true) {
    if(!this.selectedPosts.length)
    return;
    
    const query = {
      adminId: this.loggedInUser.userId,
      status: event.trigger == 'approve' ? 'approved' : 'rejected',
      remarks:  this.rejectionReasonMessage
    }

    if(query.status == 'rejected' && initial==true){
      this.bulkReject=true;
      this.rejectionReasonDialog=true;
      return;
    }
  
    this.rejectionReasonDialog=false;
    // this.selectedPosts.forEach(post => 
    const processPosts = (selectedPosts: any, index: any) => {
      this.authService.hasPageLoaded.next(false);
      if (index < selectedPosts.length) {
        this.postListService.updatePostStatus(selectedPosts[index].postId, query)
          .pipe(takeUntil(this.unsubscribe$),
            finalize(() => {
              processPosts(selectedPosts, index + 1);
            })
          )
          .subscribe({
            next: res => {
              console.log(res)
              this.authService.hasPageLoaded.next(true);
              //this.notificationService.showSuccess(`Post with id ${post.postId} ${query.status} successfully updated`);
            },
            error: err => {
              this.notificationService.showError('Something went wrong while updating posts');
              console.log(err);
              this.authService.hasPageLoaded.next(true);
            }
          })
      } else {
        this.authService.hasPageLoaded.next(true);
        this.notificationService.showSuccess(`${index} Post(s) ${query.status} successfully`);
        setTimeout(() => {
          location.reload();
        }, 2000);
      }
    }

    processPosts(this.selectedPosts, 0);
  }//function ends
 
  

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
