<header class="bg-light-nav">
  <div class="d-md-block d-lg-block">
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <a class="navbar-brand" href="/"><img
          src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/img/new-cbw.png" alt="CBW Logo" /></a>
      <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse"
        data-bs-target="#navbarNav" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse"
        data-bs-target="#navbarNav2" data-target="#navbarNav2" aria-controls="navbarNav" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse ml-55" id="navbarNav">
        <div class="navbar-nav mr-auto border-r" id="ulCurrencyTicker">
          <div class="nav-item" *ngFor="let tag of headerTags">
            <div class="nav-link" href="#">
              <div class="border-right">
                <span class="sharetext">{{ tag?.symbol }}</span>
                <!-- <span class="shareless">{{ tag?.time }}</span> <br /> -->
                <span class="shareless">24h</span> <br />
                <span [ngClass]="
                    +tag.quote?.USD?.percent_change_24h <= 0
                      ? 'share-red'
                      : 'share-green'
                  ">
                  ${{ tag.quote?.USD?.price | number : "1.2-2" }}
                  <span style="padding-left: 5px" class="greenles">
                    {{ tag.quote?.USD?.percent_change_24h | number : "1.2-2" }}%
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <ul class="navbar-nav" *ngIf="!loggedInUser">
          <li class="nav-item">
            <a class="nav-link login-btn" href="/login">Login</a>
          </li>
          <li class="nav-item">
            <a class="reg-btn" data-toggle="modal" data-target="#ExternalAnalyst" href="#"
              routerLink="register">Register</a>
          </li>
        </ul>

        <div class="loggedin-user flex gap-2 align-items-center mr-3" *ngIf="loggedInUser"
          (click)="loginOverlayPanel.toggle($event)">
          <!-- <img alt="cbw-img" src="https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/Profile/77/abcd.jpg" alt="loggedInUser"> -->
          <img alt="cbw-img" src="assets/images/profile-image.jpg" alt="loggedInUser" />
          <i class="pi pi-angle-down"></i>
        </div>
      </div>
    </nav>
  </div>

  <div class="mt-1" id="content-wrapper">
    <nav class="navbar navbar-expand-lg navbar-light nabvar2">
      <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse"
        data-bs-target="#navbarNav2" data-target="#navbarNav2" aria-controls="navbarNav" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> -->
      <div class="collapse navbar-collapse justify-content-center" id="navbarNav2">
        <!-- Web version -->
        <ul class="navbar-nav justify-content-center hide-mobile-login">
          <li class="nav-item">
            <a class="nav-link" routerLink="/crypto-business-world-press-release">
              Press Release
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown"
              (click)="marketOverlayPanel.toggle($event)">Market</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/crypto-business-world-video">
              Videos
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" (click)="newsOverlayPanel.toggle($event)">
              News
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/global-crypto-events"> Events </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/Crypto-Business-World-learn-categories">
              Learn
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown"
              (click)="externalAnalystOverlayPanel.toggle($event)">
              External Analyst
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/crypto-business-world-inspirations">
              Inspirations
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" (click)="indexOverlayPanel.toggle($event)">
              Index
            </a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link dropdown-toggle" href="https://cryptoassetratings.com/" target="_blank">
              CAR
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link dropdown-toggle" href="https://defiassetsllc.com/" target="_blank">
              Tokenization
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link dropdown-toggle" href="https://jobs.cryptobusinessworld.com/" target="_blank">
              Jobs
            </a>
          </li> -->
        </ul>

        <!-- Mobile version -->
        <ul class="navbar-nav justify-content-center show-mobile-login">
          <span class="border-bottom show-mobile-login margin-bottom"></span>
          <li class="nav-item">
            <a class="nav-link" routerLink="/crypto-business-world-press-release">
              <span data-bs-toggle="collapse" data-bs-target="#navbarNav2">Press Release</span>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown"
              (click)="marketOverlayPanel.toggle($event)">Market</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/crypto-business-world-video">
              <span data-bs-toggle="collapse" data-bs-target="#navbarNav2">Videos</span>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" (click)="newsOverlayPanel.toggle($event)">
              News
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/global-crypto-events" data-bs-toggle="collapse"
              data-bs-target="#navbarNav2"> Events </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/Crypto-Business-World-learn-categories" data-bs-toggle="collapse"
              data-bs-target="#navbarNav2">
              Learn
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown"
              (click)="externalAnalystOverlayPanel.toggle($event)">
              External Analyst
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/crypto-business-world-inspirations" data-bs-toggle="collapse"
              data-bs-target="#navbarNav2">
              Inspirations
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" (click)="indexOverlayPanel.toggle($event)">
              Index
            </a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link dropdown-toggle" href="https://cryptoassetratings.com/" target="_blank"><span
                data-bs-toggle="collapse" data-bs-target="#navbarNav2">CAR</span>

            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link dropdown-toggle" href="https://defiassetsllc.com/" target="_blank"><span
                data-bs-toggle="collapse" data-bs-target="#navbarNav2">Tokenization</span>

            </a>
          </li>
          <li class="nav-item margin-bottom">
            <a class="nav-link dropdown-toggle" href="https://jobs.cryptobusinessworld.com/" target="_blank"><span
                data-bs-toggle="collapse" data-bs-target="#navbarNav2">Jobs</span>

            </a>
          </li> -->
          <span class="border-bottom show-mobile-login margin-bottom"></span>
          <ul class="navbar-nav show-mobile-login" *ngIf="!loggedInUser">
            <li class="nav-item margin-bottom">
              <a class="login-btn mobile-login-option" href="#" data-bs-toggle="collapse" routerLink="login"
                data-bs-target="#navbarNav2">Login</a>
            </li>
            <li class="nav-item margin-bottom">
              <a class="reg-btn mobile-login-option" data-toggle="modal" data-target="#ExternalAnalyst" href="#"
                routerLink="register" data-bs-toggle="collapse" data-bs-target="#navbarNav2">Register</a>
            </li>
          </ul>

          <div class="loggedin-user flex gap-2 align-items-center mr-3 show-mobile-login" *ngIf="loggedInUser"
            (click)="loginOverlayPanel.toggle($event)">
            <img class="show-mobile-login" alt="cbw-img" src="assets/images/profile-image.jpg" alt="loggedInUser" />
            <i class="pi pi-angle-down show-mobile-login"></i>
          </div>
        </ul>
      </div>
    </nav>
  </div>
</header>

<p-overlayPanel #marketOverlayPanel>
  <div class="overlayPanel">
    <span class="overlayPanel__option" (click)="marketOverlayPanel.toggle(false)"
      routerLink="/crypto-business-world-market" data-bs-toggle="collapse" data-bs-target="#navbarNav2">All
      Articles</span>
    <span class="overlayPanel__option" (click)="marketOverlayPanel.toggle(false)"
      routerLink="/crypto-business-editors-choice" data-bs-toggle="collapse" data-bs-target="#navbarNav2">Editor's
      Choice</span>
    <span class="overlayPanel__option" (click)="marketOverlayPanel.toggle(false)"
      routerLink="/crypto-business-hot-story" data-bs-toggle="collapse" data-bs-target="#navbarNav2">Hot Stories</span>
    <span class="overlayPanel__option" (click)="marketOverlayPanel.toggle(false)" routerLink="/cbw-Article/Analysis"
      data-bs-toggle="collapse" data-bs-target="#navbarNav2">Featured Articles</span>
    <span class="overlayPanel__option" (click)="marketOverlayPanel.toggle(false)" routerLink="/cbw-Article/Trending"
      data-bs-toggle="collapse" data-bs-target="#navbarNav2">Trending Articles</span>
  </div>
</p-overlayPanel>
<p-overlayPanel #indexOverlayPanel>
  <div class="overlayPanel">
    <!-- <span
      class="overlayPanel__option"
      (click)="indexOverlayPanel.toggle(false)"
      routerLink="/crypto-business-world-car-composite-index"
      >Car Composite Index</span
    >
    <span
      class="overlayPanel__option"
      (click)="indexOverlayPanel.toggle(false)"
      routerLink="/crypto-business-world-car-issuers-basic-ratings"
      >Basic Rating</span
    > -->
    <span class="overlayPanel__option" (click)="indexOverlayPanel.toggle(false)"
      routerLink="/crypto-business-world-index-methodology" data-bs-toggle="collapse" data-bs-target="#navbarNav2">Index
      Methodology</span>
    <span class="overlayPanel__option" (click)="indexOverlayPanel.toggle(false)"
      routerLink="/crypto-business-world-index-governance" data-bs-toggle="collapse" data-bs-target="#navbarNav2">Index
      Governance</span>
  </div>
</p-overlayPanel>
<p-overlayPanel #newsOverlayPanel>
  <div class="overlayPanel">
    <span class="overlayPanel__option" (click)="newsOverlayPanel.toggle(false)"
      routerLink="/crypto-business-world-recommended-news" data-bs-toggle="collapse"
      data-bs-target="#navbarNav2">Recommended News</span>
    <span class="overlayPanel__option" (click)="newsOverlayPanel.toggle(false)" routerLink="/cbw-Article/BreakingNews"
      data-bs-toggle="collapse" data-bs-target="#navbarNav2">Breaking News</span>
    <span class="overlayPanel__option" (click)="newsOverlayPanel.toggle(false)" routerLink="/cbw-Article/DailyNews"
      data-bs-toggle="collapse" data-bs-target="#navbarNav2">Daily News</span>
  </div>
</p-overlayPanel>
<p-overlayPanel #externalAnalystOverlayPanel>
  <div class="overlayPanel">
    <span class="overlayPanel__option" (click)="externalAnalystOverlayPanel.toggle(false)"
      routerLink="/Crypto-Business-World-How-to-become-EA" data-bs-toggle="collapse" data-bs-target="#navbarNav2">How to
      become EA</span>
  </div>
</p-overlayPanel>
<p-overlayPanel #loginOverlayPanel>
  <div class="overlayPanel">
    <span *ngIf="loggedInUser?.roleName != 'Customer'" class="overlayPanel__option"
      (click)="loginOverlayPanel.toggle(false)" routerLink="/dashboard">Dashboard</span>
    <!-- <span
      class="overlayPanel__option"
      (click)="loginOverlayPanel.toggle(false)"
      routerLink="/profile"
      >Profile Page</span> -->
    <span class="overlayPanel__option" (click)="loginOverlayPanel.toggle(false)"
      routerLink="/forgot-password/change/{{ loggedInUser?.userId }}" data-bs-toggle="collapse"
      data-bs-target="#navbarNav2">Change Password</span>
    <span class="overlayPanel__option" (click)="loginOverlayPanel.toggle(false); logout()" data-bs-toggle="collapse"
      data-bs-target="#navbarNav2">Sign Out</span>
  </div>
</p-overlayPanel>
