import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  $post:BehaviorSubject<any> = new BehaviorSubject({});

  api= environment.apiURL;
  constructor(private http: HttpClient) { }

  getPosts(query: any):Observable<any>{
    console.log(query)
    const params = new HttpParams()
    .set('isEditorChoice', 'false')
    .set('isHotStory', 'false')
    .set('pageNo',1).set('limit',10)
    // .set('publisherId',query.publisherId);

    return this.http.get(`${this.api}/api/post/getPostByUserId/${query.publisherId}`)
  }

  getAllPosts(query: any):Observable<any>{
    console.log(query)
    const params = new HttpParams()
    .set('allStatus',false)
    // .set('isEditorChoice', 'false')
    // .set('isHotStory', 'false')
    // .set('pageNo',1).set('limit',10)
    
    return this.http.get(`${this.api}/api/post/getAllPosts`,{params})
  }


  getPostCount(forr:string,query:any):Observable<any>{
    console.log(query)
    let params = new HttpParams()

    if(query.loggedInUser.roleName == 'SuperAdmin' || query.loggedInUser.roleName =='ContentApprover'){
      params=params.set('allStatus',true)
    }else{  
      params=params.set('publisherId',query.loggedInUser.userId)
      
    }
    
    switch (forr) {
      case 'all':
        params=params.set('allStatus',true)
        break;
      case 'approved':
        params=params.set('allStatus',false)
        params=params.set('status','approved')
        break;
      case 'rejected':
        params=params.set('allStatus',false)
        params=params.set('status','rejected')
        break;
      case 'pending':
        params=params.set('allStatus',false)
        params=params.set('status','pendingApproval')
        break;
      case 'drafted':
        params=params.set('allStatus',false)
        params=params.set('status','drafted')
        break;
      case 'approvedvideos':
        params=params.set('allStatus',false)
        params=params.set('status','approved')
        params=params.set('categoryId',10)
        break;
      case 'rejectedvideos':
        params=params.set('allStatus',false)
        params=params.set('status','rejected')
        params=params.set('categoryId',10)
        break;
      case 'pendingvideos':
        params=params.set('allStatus',false)
        params=params.set('status','pendingApproval')
        params=params.set('categoryId',10)
        break;
    
      default:
        break;
    }
    
    return this.http.get(`${this.api}/api/post/getPostsCount`,{params})
  }

}
