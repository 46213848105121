import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FooterService } from 'src/app/shared/services/footer.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  private unsubscribe$ = new Subject<void>();
  subscribeDialog=false;
  constructor(private notificationService: NotificationService,
    private footerService: FooterService){}


  SubscribeWithUs(event:any){
    const patter = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!patter.test(event.value)) {
      this.notificationService.showError('Enter valid email address');
      return;
    }

    this.footerService.subscribeToCbw(event.value)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: res => {
        this.subscribeDialog=false;
        this.notificationService.showSuccess('Thanks! Successfully Subscribed to newsletter. Now you will get all notifications for all published posts in your email')
        setTimeout(() => {
          location.reload()
        }, 5000);
      },
      error: err => {
        this.notificationService.showError('Something went wrong! please try again later')
        console.log(err);
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  
}
