<div class="index-governance">
  <img class="index-governance--img" src="./assets/images/index-governance.png" />
  <div class="index-governance--body">
    <h4>Overview</h4>
    <p>
      At Crypto Asset Rating (CAR) we offer multiple indices which make it
      easier for investors to make decision on which tokens they should add in
      their own portfolio. We offer indices based on the types of crypto
      currencies/tokens which includes
    </p>
    <ul>
      <li>1. Currency Index</li>
      <li>2. Security Index</li>
      <li>3. Utility Index</li>
    </ul>
    <p>
      CAR's Indices' index calculation varies according to the categorization of
      the indices. At a broad level, indices are defined into categorization of
      Market Capitalization Weighted. Indices also offers capped versions of a
      market capitalization weighted index where single index constituents or
      defined groups of index constituents, such as sector, geography i.e.
      County of issuance, are confined to a weight.
    </p>
    <h4 class="border-bottom">Illustration</h4>
    <span class="day1">Day 1</span>
    <table class="table line-anywhere">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Number of Outstanding Tokens</th>
          <th scope="col">Price per Token (t - 1)</th>
          <th scope="col">Price per Token (t)</th>
          <th scope="col">Market Cap (t - 1)</th>
          <th scope="col">Market Cap (t)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Token A</td>
          <td>62,000,000</td>
          <td>75</td>
          <td>77</td>
          <td>4,650,000,000</td>
          <td>4,774,000,000</td>
        </tr>
        <tr>
          <td>Token B</td>
          <td>280,000,000</td>
          <td>0.9</td>
          <td>0.8</td>
          <td>252,000,000</td>
          <td>224,000,000</td>
        </tr>
        <tr>
          <td>Token C</td>
          <td>15,000,000</td>
          <td>15</td>
          <td>52</td>
          <td>825,000,000</td>
          <td>780,000,000</td>
        </tr>
        <tr>
          <td>Token D </td>
          <td>540,000,000</td>
          <td>3</td>
          <td>3.25</td>
          <td>1,620,000,000</td>
          <td>1,755,000,000</td>
        </tr>
      </tbody>
    </table>
    <br><br><br>
    <div class="day1-table-sum">
      <span>
        Token Market Cap (t - 1)</span>
      <span>7,347,000,000</span>
    </div>
    <hr>
    <div class="day1-table-sum">
      <span>
        Token Market Cap (t)</span>
      <span>7,533,000,000</span>
    </div>
    <hr>
    <div class="day1-table-sum">
      <span>
        Index Value (t - 1)</span>
      <span>10,000</span>
    </div>
    <hr>
    <div class="day1-table-sum">
      <span>
        Index Value (t)</span>
      <span>10,253.1646</span>
    </div>
    <br><br><br>
    <h4>Roles</h4>
    <p>
      Index is managed by Index Committee and Steering Committee. Members have
      rigor around the index calculations. After doing thorough research,
      Financial Analyst team has selected the best tokens which got included in
      the index. Members periodically review the Business actions that may
      affect the index constituents, Business/Corporate actions may includes
      Merger/Acquisition, spin off etc. Specified roles that includes the
      periodic review meetings, set the criteria for the selection/removal of
      the tokens, resolve the technical glitches, promptly communicate the
      changes in index constituents etc.
    </p>
    <p>
      Index Committee - CAR's Index Committees serve as the index governance
      body and oversee the design, maintenance of indices. Index Committees seek
      to ensure index is maintained in accordance with their respective
      methodology by reviewing all methodologies to ensure they continue to
      achieve their stated objectives and overseeing any changes to an index
      and/or methodology. All Index Committee comprised of Financial Analyst
      Team as well Corporate Advisors.
    </p>
    <p>
      Role of the Internal Committee is to systematically reassess the various
      dimensions of the Token Universe for all the indexes on a fixed quarterly
      timetable. A committee involves in a comprehensive review of the Indexes.
    </p>
    <h4>Responsibility</h4>
    <p>
      Indices are maintained by an Index Committee. Most committees are
      comprised of professional members of CAR. Members include the Financial
      Analyst team they are the member who primarily responsible to
      maintain/calculate the indices. Secondary responsibility of Steering
      committee, the committee members include the professionals from Corporate
      Advisors Team as well as Financial Analyst Team. At each meeting, the
      Index Committee review pending actions that may affect index constituents,
      statistics comparing the composition of the indices to the market,
      companies that are being considered as candidates for addition to an
      index, and any significant market events. In addition, the Index Committee
      may revise index policy covering rules for selecting token, treatment or
      other matters.
    </p>
    <p>
      CAR's Index Committees reserve the right to make exceptions in the
      treatment if the need arises. In any scenario where the treatment differs
      from the general rules stated in this document, clients will receive
      sufficient notice, whenever possible.
    </p>
    <span *ngIf="!isReadMore" (click)="isReadMore = true" class="read-more-less-center ">READ MORE</span>
    <h4 *ngIf="isReadMore">Market/Client communication Policies</h4>
    <p *ngIf="isReadMore">
      CAR communicates and consults with clients through various channels using,
      index announcements, emails and the distribution of data files. In
      addition, CAR has a designated client support team available to respond to
      inquiries. When a material change to an index methodology is considered,
      CAR will publish that on its website. A material change alters the index
      objective or changes the methodology in a way that affects the likelihood
      that the index will achieve its objective. All index constituents are
      evaluated daily for data needed to calculate index levels and returns. All
      events affecting the daily index calculation are typically announced via
      email to clients.
    </p>
    <p *ngIf="isReadMore">
      For any inquiry, comment, or complaint regarding the indices, a Client
      Services at (Need to add Email).
    </p>
    <h4 *ngIf="isReadMore">Continues Review Policy</h4>
    <p *ngIf="isReadMore">
      CAR maintains quality review processes for the calculation and maintenance
      of its indices that include a periodical quality checks on incidents or
      errors, if any, that triggered during any days and identify causes,
      determine repetitive issues and evaluate whether any long-term changes are
      necessary. Incidents and errors are tracked through internal system.
    </p>
    <h4 *ngIf="isReadMore">Policies to add/remove tokens</h4>
    <p *ngIf="isReadMore">
      Constructing and Maintaining the CAR's Indexes involves the following
      steps:
    </p>
    <p *ngIf="isReadMore">Each of these steps is described in Methodology document separately.</p>
    <ul *ngIf="isReadMore">
      <li>
        • Defining the Token Universe - CAR index consists of 50 tokens based on
        the different criteria which are listed in Methodology document.
      </li>
      <li>
        • Identifying Eligible Tokens to add/remove from Index - Each token gets
        selected based on the different criteria which are listed in Methodology
        document.
      </li>
      <li>
        • Classifying Tokens Utility/Security - Tokens get categorized based on
        Utility/Security, detail is explained in Methodology document.
      </li>
    </ul>
    <h4 *ngIf="isReadMore">Disclaimer</h4>
    <p *ngIf="isReadMore">
      This document has been prepared solely for informational purposes based
      upon information generally available to the public and from sources
      believed to be reliable. The Content shall not be used for any unlawful or
      unauthorized purposes. CAR do not guarantee the accuracy, completeness,
      timeliness or availability of the Content. CAR Parties/Members are not
      responsible for any errors or omissions, regardless of the cause, for the
      results obtained from the use of the Content.
    </p>
    <span *ngIf="isReadMore" (click)="isReadMore = false" class="read-more-less-center ">READ LESS</span>
  </div>
</div>