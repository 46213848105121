import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, RouteReuseStrategy } from '@angular/router';
import { NgxCaptchaService } from '@binssoft/ngx-captcha';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RegisterService } from '../../shared/services/register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  captchaStatus:any;

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.India];


  roleList=[
    {roleId: 2, roleName: "External Analyst"},
    {roleId: 6, roleName: "CBW Writer"},
    {roleId: 7, roleName: "CBW Reader"}
  ];

  genders= [
    {label:'Male',value:'male'},
    {label:'Female',value:'female'}
  ]
  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
    private captchaService:NgxCaptchaService,
    private router: Router,
    private registerService: RegisterService,
    private ns: NotificationService,
    private authService:AuthService) {

    this.registerForm = this.formBuilder.group({
      firstName: ['',Validators.required],
      lastName: '',
      gender:['male'],
      emailId: '',
      user: '',
      password: ['umar1234', [
        Validators.required,
        // Validators.minLength(7),
        // Validators.pattern(/^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d).*$/),
      ]],
      mobileNumber: ''
    });
  }

  ngOnInit() {
    this.captchaService.captchStatus.subscribe((status) => {
      this.captchaStatus = status;
    })

    this._getUserRoles()
    this.authService.hasPageLoaded.next(true)
  }

  register(){
    const formData ={
      "firstName": this.registerForm.get('firstName')?.value,
      "lastName": this.registerForm.get('lastName')?.value,
      "email": this.registerForm.get('emailId')?.value,
      "phone": this.registerForm.get('mobileNumber')?.value.e164Number,
      "gender": this.registerForm.get('gender')?.value,
      "password": this.registerForm.get('password')?.value,
      "roleId": this.registerForm.get('user')?.value
    }
   console.log(this.registerForm.value)
   let registerUser = this.registerForm.get('user')?.value;
   switch (registerUser) {
      case '2': //external-analyst
        this.registerUser(formData);
        break;
      case '6': //content-wirter
        this.registerUser(formData);
        break;
      case '7': //content-reader
        this.registerUser(formData);
        break;
    }
  }

registerUser(data:any):void{
  //save user
  console.log(data)
  this.authService.hasPageLoaded.next(false);
  this.registerService.registerUser(data).pipe(takeUntil(this.unsubscribe$)).subscribe({
    next:(res) => {
    console.log(res);
    if(res.userId){
      this.sendVerificationEmail('192122');
      this.authService.hasPageLoaded.next(true);
    }
    
  },
  error: (error) => {
    console.log(error)
    this.ns.showError(error.error?.detail)
    this.authService.hasPageLoaded.next(true);
  }
});


}

sendVerificationEmail(id: string):void{
  this.ns.showSuccess('Email verification sent')
  setTimeout(() => {
    this.router.navigate(['/login'])
  }, 2000);

}

_getUserRoles(){
  
}


ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}
}
