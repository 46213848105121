<div class="inspiration-details">
    <div class="col-md-4 inspiration-details--left">
        <img alt="cbw-img" class="inspiration-details--left--img" src="{{peopleDetails[0].profileImage}}" />
        <span class="inspiration-details--left--bottom">
            <span class="inspiration-details--left--bottom--title">Place</span>
            <span class="inspiration-details--left--bottom--value">{{peopleDetails[0].place}}</span>
            <span class="inspiration-details--left--bottom--title">Industry</span>
            <span class="inspiration-details--left--bottom--value">{{peopleDetails[0].industryId == 1 ? "Crypto" :
                peopleDetails[0].industryId == 2 ? "Payments" : peopleDetails[0].industryId == 3 ? "Technology" :
                peopleDetails[0].industryId == 4 ? "Law & Politics" : "Other" }}</span>
            <span class="inspiration-details--left--bottom--title">Known for</span>
            <span class="inspiration-details--left--bottom--value">{{peopleDetails[0].knownFor}}</span>
        </span>
    </div>
    <div class="col-md-8 inspiration-details--right">
        <span class="inspiration-details--right--name">{{peopleDetails[0].peopleName}}</span>
        <span class="inspiration-details--right--role">{{peopleDetails[0].peopleRole}}</span>
        <span class="inspiration-details--right--des">{{peopleDetails[0].shortDescription}}</span>
        <h2>Biography</h2>
        <span class="inspiration-details--right--bio">{{peopleDetails[0].biography}}</span>
        <span routerLink="/crypto-business-world-inspirations" class="view-all-btn">View All</span>

    </div>
</div>
