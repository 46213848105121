import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-user-email-verification',
  templateUrl: './user-email-verification.component.html',
  styleUrls: ['./user-email-verification.component.scss']
})
export class UserEmailVerificationComponent {

  userVerified:boolean=false;
  constructor(private activeRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private ns: NotificationService){}

    ngOnInit():void{
      this.checkModeInit()
    }

    checkModeInit(){
      this.activeRoute.params.subscribe(param => {
        if(param['userId']){
          console.log('this is the ',param)
          this.verifyUser(param['userId']);
        }
      })
    }

    verifyUser(id: string):void{
      // service to update the user email verificaion part
      this.authService.verifyEmail(id).subscribe({
        next:res => {
        if(res){
          this.userVerified=true
        }
      },
      error:err => {
        this.ns.showError('Something went wrong! please try again')
        this.userVerified=false
        this.router.navigate([''])
        console.log(err)
      }
      })
      
    }
}
