import { Component } from '@angular/core';

@Component({
  selector: 'app-how-to-become-ea',
  templateUrl: './how-to-become-ea.component.html',
  styleUrls: ['./how-to-become-ea.component.scss']
})
export class HowToBecomeEaComponent {
  openPdf() {
    window.open('assets/files/ExternalAnalyst.pdf');
  }
}
