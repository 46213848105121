import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-approve-reject',
  templateUrl: './approve-reject.component.html',
  styleUrls: ['./approve-reject.component.scss']
})
export class ApproveRejectComponent {
  @Input() buttonVisible:string ='both';
  @Output() approveReject : EventEmitter<any> = new EventEmitter()
  constructor(){}

  approve(){
    this.approveReject.emit({trigger:'approve'})
  }
  reject(){
    this.approveReject.emit({trigger:'reject'})
  }
}
