import { Component, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Location } from '@angular/common';
import { PostService } from '../../shared/services/post.service';
import { AuthService } from 'src/app/auth/auth.service';
import { forkJoin, map, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { peopleModel } from 'src/app/shared/models/people.model';
import { HttpParams } from '@angular/common/http';
import { PostListService } from 'src/app/shared/services/post-list.service';
@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss']
})
export class CreatePostComponent {

  private unsubscribe$ = new Subject<void>();

  createPost: FormGroup;
  eventDetailForm: FormGroup;
  peopleForm: FormGroup;
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.India];
  totalRecords=100000;
  // users: User[]=usersList;

  userProfileData:any=[]


  postType:string='';
  categoryList:any;

  peopleList:peopleModel[]=[];

  eventTypeList=[{label:'Physical Event',value:'Physical Event'},{label:'Virtual Event',value:'Virtual Event'}]
  eventsList:any;
  eventDataObj:any;
  eventid:any='';
  peopleId='';

  regionList=[];

  progressDetails=[
    {name:'Basic Details', status:true},
    {name:'Education Details', status:true},
    {name:'Professional Details', status:true},
    {name:'Resume Upload', status:true},
    {name:'Profile Image Upload', status:true},
    {name:'LinkedIn Connection', status:true},
    {name:'Facebook Connection', status:true},
    {name:'Twitter Connection', status:true},
    {name:'About Yourself', status:true}
  ]

  specializationList=[
    {label:'Legal',value:'Legal'},
    {label:'Technology',value:'Technology'},
    {label:'Finance',value:'Finance'},
    {label:'Management',value:'Management'},
    {label:'Other',value:'Other'},
  ]

  degreeList=[
    {label:'Bachelor of Agriculture (B.Sc (Agriculture))',value:'Bachelor of Agriculture (B.Sc (Agriculture))'},
    {label:'Bachelor of Architecture (B.Arch.)',value:'Bachelor of Architecture (B.Arch.)'},
    {label:'Bachelor of Arts (B.A)',value:'Bachelor of Arts (B.A)'},
    {label:'Bachelor of Commerce (B.com)',value:'Bachelor of Commerce (B.com)'},
    {label:'Bachelor of Computer Applications (BCA)',value:'Bachelor of Computer Applications (BCA)'},
  ]


  edulist=[
    {institute:'Institute 18',course:'Bachelor of Ayurvedic Medicine & Surgery (B.A.M.S.)',degree:'LOREM',year:'LOREM'}
  ]

  professionalDetailList=[
    { designation:'Desig',from:new Date(), to:new Date() ,organization:'Organization',desc: 'lorem ipsum'}
  ]
  industryList=[];


  filterBy = [
    { label: 'All', value: 'all' },
    { label: 'Draft', value: 'drafted' },
    { label: 'Approval Pending', value: 'pendingApproval' },
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },

];
  allPeopleList:any;

  fileUploadList=[{name:'randomimg.jpg'},{name:'randomimg.jpg'},{name:'randomimg.jpg'}]
  cryptoexp:any
  loggedInUser:any;
  postMediaURL:any;
  postMediaThumbURL:any ='';
  postStatus:string='drafted';
  media:any;
  mediaVid:any;
  id:any=''
  reqFields:any;
  mode:any;

  allEventList:any;
  selectedFilter='all';

  userList:any;
  countryList:any;
  constructor(private activeRoute : ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private location: Location,
    private postService: PostService,
    private postListService: PostListService,
    private authService: AuthService,
    private renderer: Renderer2){}

  ngOnInit(){
    this._initPostForm();
    this.loggedInUser=this.authService.getUser();
    // this._getCategoryList();
    this.checkPath();
    // setTimeout(() => {
      this._checkActiveRoute();
    // }, 2000);
  }

  _checkActiveRoute(){
    var path = this.activeRoute.snapshot.url[0].path
    console.log(path)
    this.activeRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe(param => {
      console.log(param)
      if(param['mode']=='edit' && param['userid']){
        this.mode='edit'
        this.getUserData(param['userid']);
      }
      if(param['mode']=='edit' && param['id']){
        this.mode='edit'
        //console.log('im in editiog mode',param['id'])
        this.id=param['id'];
        this.checkPath()
        // setTimeout(() => {
          this.getPostData(param['id']);
        // }, 3000);

        if(this.loggedInUser.roleName != 'SuperAdmin' || this.loggedInUser.roleName !='ContentApprover'){
          this.createPost.get('declaration')?.setValue(false)
        }

      }
    });


  }

  _initPostForm(){
    this.createPost=this.fb.group({
      title:[''],
      category:[''],
      tags:[''],
      desc:[''],
      content:[''],
      reference:[''],
      img:[''],
      posturl:['',[Validators.compose([Validators.required,Validators.pattern(/^[a-zA-Z0-9\-]+$/)])]],
      peoplename:[''],
      interviewDate:[new Date()],
      youtubeurl:[''],
      industry:[''],
      topic:[''],
      declaration:[''],
      postThumbnailURL:[''],
      postVideoURL:[''],
      postImageURL:[''],
      isHotStory:[false],
      isShownAsBanner:[false],
      status:[''],
      postHeading:[''],
      imageAlt:[''],
      remarks:['']
    })
  }

  checkPath(){
    var path = this.activeRoute.snapshot.url[0].path
    console.log(path)
    switch (path) {
      case 'create-video':
        this.postType = 'Video'
        this._getCategoryList().then(() => this.categoryList=this.categoryList.filter((c:any) => c.categoryName == 'Videos'))
        this._setValidators({ Title: 'title', Post_url: 'postUrl', Short_description: 'desc' });
        this.createPost.get('category')?.setValue(10)
        break;
      case 'press-release':
        this.postType = 'Press'
        this._getCategoryList().then(res => {
          console.log(this.categoryList)
          this.createPost.get('category')?.setValue(this.categoryList.find((c: any) => c.categoryName == 'Press').categoryId)
        })
        // this._setValidators();
        break;
      case 'people-list':
        this.postType = 'PeopleList'
        this._initPeopleForm();
        this._getIndustryList();
        // this.getPeopleList();
        break;
      case 'events-list':
        this.postType = 'EventsList'
        // this.getEventData()
        // this.getUsers();
        // this.getCountries();
        this._initEventsForm();
        break;
      case 'user-profile':
        this.postType = 'UserProfile'
        break;
      case 'create-interview':
        this.postType = 'Interview'
        console.log('in interview section', this.categoryList)
        if (!this.id)
          this._getCategoryList().then(res => {
            this.createPost.get('category')?.setValue(this.categoryList?.find((c: any) => c.categoryName == 'Interview').categoryId)
          });
        this._getIndustryList();
        break;
      default:
        this.postType = ''
        this._getCategoryList().then(() => this.categoryList=this.categoryList.filter((c:any) => c.categoryName != 'Videos' && c.categoryName != 'Interview' && c.categoryName != 'Press'));
        this._setValidators({ Title: 'title', Post_url: 'postUrl', Short_description: 'desc', Post_content: 'content' });
        break;
    }

  }


  _setValidators(reqArr:any){
    this.reqFields=reqArr;
    Object.values(this.reqFields).forEach((field:any) => {
      this.createPost.get(field)?.setValidators(Validators.required)
      this.createPost.get(field)?.updateValueAndValidity();
    })

  }

  _initEventsForm(){
    this.eventDetailForm=this.fb.group({
      eventtype:[''],
      region:[''],
      eventname:[''],
      eventdesc:[''],
      eventdate:[new Date()],
      eventvenue:[''],
      eventwebsite:[''],
      eventimageurl:[''],
      eventdetailurl:[''],
      active:[''],
    })
  }

  _initPeopleForm(){
    this.peopleForm=this.fb.group({
      ownerId:[''],
      peopleName:[''],
      status:['approved'],
      peopleRole:[''],
      profileImage:[''],
      birthDate:[new Date()],
      place:[''],
      industryId:[''],
      knownFor:[''],
      shortDescription:[''],
      webLink:[''],
      biography:[''],
      facebook:[''],
      twitter:[''],
      linkedIn:[''],
      instagram:[''],
      blog:[''],
      content:[''],
      remarks:['']
    })
  }

  editRecord(id:string){

  }

  deleteRecord(id:string){
    this.authService.hasPageLoaded.next(false)
    this.postService.disablePeople(id).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next:res => {
      console.log(res);
      this.authService.hasPageLoaded.next(true)
      this.notificationService.showSuccess('Post Deleted Successfully!')
      // setTimeout(() => {
      //   location.reload();
      // }, 1000);
      this.peopleList=this.peopleList.filter(people => people.peopleId != id)
    },
    error:err=>{
      console.log(err);
      this.authService.hasPageLoaded.next(true)
      this.notificationService.showError('Something went Wrong!')
    }
  })
  }

  getUserData(id:string){

  }

  _getIndustryList(){
    this.postService.getIndustries()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next:data =>{
      this.industryList=data
      this.authService.hasPageLoaded.next(true)
    },
    error:err =>{
      console.log(err);
      this.authService.hasPageLoaded.next(true)
    }
  })
  }

  getPostData(id:string|number){
    forkJoin([
      this.postService.getCategories(),
      this.postService.getPostById(id)
    ])
      .pipe(
        takeUntil(this.unsubscribe$)
      )
   .subscribe(post => {
    console.log('checking post data ', post)
        this.categoryList=post[0].filter((c:any) => c.categoryName != 'Videos' && c.categoryName != 'Interview' && c.categoryName != 'Press');
        this.createPost.patchValue({
          title: post[1]?.postTitle,
          category:post[1]?.categoryId,
          tags:post[1]?.postTags,
          desc:post[1]?.shortDescription,
          content:post[1]?.postText,
          reference:post[1]?.reference,
          img:post[1]?.postImageURL,
          posturl:post[1]?.postURL,
          peoplename:post[1]?.peopleName,
          interviewDate:new Date(post[1]?.interviewDate),
          youtubeurl:post[1]?.youtubeURL,
          industry:post[1]?.industryId,
          topic:post[1]?.topic,
          postThumbnailURL:post[1]?.postThumbnailURL,
          postVideoURL:post[1]?.postVideoURL,
          postImageURL:post[1]?.postImageURL,
          isShownAsBanner:post[1]?.isShownAsBanner,
          isHotStory:post[1]?.isHotStory,
          status:post[1]?.status,
          postHeading:post[1]?.postHeading,
          imageAlt:post[1]?.imageAlt,
          remarks:post[1]?.remarks,
        })
    })
  }

  fileUpload(event:any, instance:any,type:any){
    console.log(event)
    if (event.files[0]) {
      if (event.files[0].size > 500000000) {
        this.notificationService.showWarning('Fle size exceeds')
        return;
      }
      else if(type =='image') {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          const thumb = event.target.result;

          const img = new Image();
          img.onload = () => {
            // if((img.width >= 525 && img.height >=450) || this.postType == 'PeopleList'){

              if(this.postType=='Video' || this.postType=='Press'){
                this.createPost.get('postThumbnailURL')?.setValue(thumb)
              }else{
                this.createPost.get('img')?.setValue(thumb)
              }
            // }else{
            //   this.notificationService.showWarning('Image resolution must be equal or more than in the ratio of 525 * 450 (Width * Height)')
            // }

          };
          img.src = thumb;
        }
        reader.readAsDataURL(event.files[0]);
        this.media= event.files[0];

        instance.chooseIcon = 'pi pi-check'
        instance.chooseLabel = 'Done'
        instance.clear()
      }else if(type =='video'){
        this.mediaVid= event.files[0];
        console.log(this.mediaVid)
        instance.chooseIcon = 'pi pi-check'
        instance.chooseLabel = 'Done'
        instance.clear()
        console.log('video')
      }
    }
  }


  savePost(status: string){
    // console.log(this.createPost.value,this.media,this.mediaVid,this.id); return;
    if(this.loggedInUser.roleName != 'SuperAdmin' && this.createPost.invalid){
      Object.values(this.createPost.controls).forEach(control => {
        // control.markAsDirty()
        control.markAsTouched()
      })
      this.notificationService.showError(Object.keys(this.reqFields)?.toString().replaceAll('_',' ') + ' are required fields')
      return;
    }


    if(this.loggedInUser.roleName != 'SuperAdmin' && !this.media && this.postType=='' && !this.id){
      this.notificationService.showWarning('Please choose image first!');
      return
    }

    if(this.loggedInUser.roleName != 'SuperAdmin' && (this.postType=='Video' || this.postType=='Interview' || this.postType=='Press') && (!this.mediaVid || !this.media) && !this.id ){
      this.notificationService.showWarning('Video & thumbnail are required!');
      return
    }

    //if user is superadmin then keep the status as it is
    if(this.loggedInUser.roleName == 'SuperAdmin' && this.id){
      this.postStatus = this.createPost.get('status')?.value
    }else{
      this.postStatus =status
    }

    
    console.log(this.createPost.value);
    // return;
    console.warn('this is '+this.id+' and '+this.createPost.value)
    const postData =this.createPost.value
    const formData = new FormData();
    const formDataVid = new FormData();
    formData.append('file',this.media)
    formDataVid.append('file',this.mediaVid)

    const imageUploadObservable = this.media  ? this.postService.uploadMedia(formData): of(1)
    const videoUploadObservable = this.mediaVid || this.mediaVid ? this.postService.uploadMedia(formDataVid): of(1)

    this.authService.hasPageLoaded.next(false)
    if(this.postType == ''){
      imageUploadObservable.pipe(
        switchMap(res => {
            this.postMediaURL=res.url;
            //if image after update is missing then set original value
            if(this.id && res.url===undefined){
              this.postMediaURL = postData.postImageURL;
            }

            return !this.id ? this.postService.createPost(this.createDataObj(postData)):
            this.postService.updatePost(this.createDataObj(postData),this.id);
        })
      ).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next:(post) => {
          this.createPost.reset();
        this.notificationService.showSuccess(post.message);
          setTimeout(() => {
            location.reload();
          }, 1000);
      },
      error:(err) => {
        this.notificationService.showError(err.error.detail??'Something went wrong')
        this.authService.hasPageLoaded.next(true)
      },
      complete:()=>{
        this.authService.hasPageLoaded.next(true)
       }
    });

    }else if (this.postType=='Video' || this.postType=='Interview' || this.postType=='Press'){

      forkJoin([imageUploadObservable,videoUploadObservable]).pipe(
        switchMap(([imgRes,vidRes]) =>{
          this.postMediaURL=vidRes.url;
          this.postMediaThumbURL=imgRes.url;

          //if video or image after update is missing then set original value
          if(this.id && vidRes.url===undefined){
            this.postMediaURL = postData.postVideoURL
          }
          if(this.id && imgRes.url===undefined){
            console.log(imgRes,postData.postThumbnailURL)
            this.postMediaThumbURL=postData.postThumbnailURL
          }
          return !this.id ? this.postService.createPost(this.createDataObj(postData)):
          this.postService.updatePost(this.createDataObj(postData),this.id);
        })
      ).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next:(post) => {
        this.createPost.reset();
        this.notificationService.showSuccess(post.message);
        setTimeout(() => {
          location.reload()
        }, 1000);
      },
      error:(err) => {
        this.notificationService.showError(err.error.detail??'Something went wrong')
        this.authService.hasPageLoaded.next(true)
      },
      complete:()=>{
        this.authService.hasPageLoaded.next(true)
       }
      })
    }

  }


  _getCategoryList(){
    return new Promise((resolve,reject) =>{
    this.postService.getCategories().pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.categoryList=res
      console.log('loading categories', this.categoryList)
      this.authService.hasPageLoaded.next(true)
      resolve(true)
    })
  })
  }

  private createDataObj(data:any){
    return {
      postTitle: this.postType=='Interview'? data.peoplename :data.title ,
      ownerId: this.loggedInUser.userId,
      status: this.postType=='PeopleList' ? 'approved' : this.postStatus,
      categoryId: data.category,
      postText: data.content,
      postImageURL: this.postType==''? this.postMediaURL :"",
      postThumbnailURL: this.postMediaThumbURL,  //for video
      postVideoURL: this.postType=='Video' || this.postType=='Interview' || this.postType=='Press' ? this.postMediaURL :"",  //for video
      shortDescription: data.desc,
      reference: data.reference,
      postTags: data.tags,
      postURL: data.posturl,
      peopleName: data.peoplename,
      interviewDate: !isNaN((new Date(data.interviewDate)).getTime()) ? new Date(data.interviewDate).toLocaleDateString('en-GB').split('/').reverse().join('-') : new Date().toLocaleDateString('en-GB').split('/').reverse().join('-'),
      youtubeURL: data.youtubeurl,
      topic: data.topic,
      industryId: data.industry==''?1:data.industry,
      isEditorChoice: false,
      isHotStory: data.isHotStory,
      isShownAsBanner: data.isShownAsBanner,
      postHeading: data.postHeading,
      imageAlt: data.imageAlt,
    }
  }

  getUsers(){
    this.postListService.getAllUser().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next:res => {
      this.userList = res
    },
    error:err => {
      console.log(err);
    }
  })
  }

  getCountries(){
    this.postService.getAllCountries().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next:res => {
      this.countryList = res
    },
    error:err => {
      console.log(err);
    }
  })
  }

  getEventData(event:any){
   this.selectedFilter='all'
    console.log(event)
    this.authService.hasPageLoaded.next(false)
    let params = new HttpParams()
    .set('pageNo',(event.first/10)+1)
    .set('limit', event.rows)
    .set('allStatus', true)

    if(event.filters.global && event.filters.global.value){
      params=params.set('eventName',event.filters.global?.value)
    }
    

    forkJoin({
      countryList:this.postService.getAllCountries(),
      eventList:this.postService.getEvents(params),
      userList:this.postListService.getAllUser()
    })
    .pipe(map(res =>{
      this.eventDataObj=res.eventList;
      this.regionList=res.countryList;
      return res.eventList.map((e:any) =>{
        return {
        id:e.eventId,
        countryId: e.countryId,
        region: res.countryList.find((country:any) => country.id ===e.countryId).name,
        eventname: e.eventName,
        description: e.eventDescription,
        eventdate: e.eventDate,
        eventvenue: e.eventVenue,
        eventwebsite:e.eventWebsite,
        status:e.status,
        user: res.userList.find((user:any) => user.userId == e.ownerId)
      }
      })
    }
      )).subscribe({
      next:res => {
        console.log(res)
        this.eventsList=res
        this.allEventList = [...this.eventsList]
        this.authService.hasPageLoaded.next(true)
      },
      error:err=> {
        console.log(err)
        this.authService.hasPageLoaded.next(true)
      }
    })
  }


  onSelectionEventFilterChange(event :any) {
    console.log(event)
    if(event.value == 'all'){
      this.eventsList = this.allEventList
      return;
    }
    this.eventsList=this.allEventList.filter((post:any) => post.status == event.value)
}

  saveEventDetail(status:any){
    this.authService.hasPageLoaded.next(false)
    let event =this.eventDetailForm
    let eventobj={
        "ownerId":this.loggedInUser.userId,
        "countryId": event.get('region')?.value,
        "eventName": event.get('eventname')?.value,
        "eventDescription": event.get('eventdesc')?.value,
        "eventDate": event.get('eventdate')?.value.toLocaleDateString('en-GB').split('/').reverse().join('-'),
        "eventVenue":event.get('eventvenue')?.value,
        "eventWebsite": event.get('eventwebsite')?.value,
        "active": event.get('active')?.value,
        "status": status,
        "imageUrl": event.get('eventdetailurl')?.value,
        "imageUrlEventDetails": event.get('eventdetailurl')?.value,
        "remarks": "string"
      }

      if(this.eventid){
        this.postService.updateEvent(this.eventid,eventobj)
        .pipe(takeUntil(this.unsubscribe$)).subscribe({
          next:res => {
           this.eventid=''
           this.eventDetailForm.reset();
           location.reload()
           this.notificationService.showSuccess(res?.message);
          },
          error:err => {
           console.log(err)
           this.authService.hasPageLoaded.next(true)
           this.notificationService.showError('Something went wrong')
          },
          complete:()=>{
            this.authService.hasPageLoaded.next(true)
           }
       })
      }else{
        this.postService.createEvent(eventobj).pipe(takeUntil(this.unsubscribe$)).subscribe({
           next:res => {
            this.eventDetailForm.reset();
            location.reload();
            this.notificationService.showSuccess(res?.message);
           },
           error:err => {
            console.log(err)
            this.authService.hasPageLoaded.next(true)
            this.notificationService.showError('Something went wrong')
           },
           complete:()=>{
            this.authService.hasPageLoaded.next(true)
           }
        })
      }
    }

    deactiveEvent(id: any){

    }
    editEvent(id: any){

      this.eventid=id;
      let event = this.eventDataObj.find((event:any) => event.eventId == id)
      console.log(id,event)
      this.eventDetailForm.patchValue({
        eventname:event.eventName,
        eventdesc:event.eventDescription,
        eventdate:new Date(event.eventDate),
        eventvenue:event.eventVenue,
        eventwebsite:event.eventWebsite,
        active:event.active,
        eventdetailurl:event.imageUrlEventDetails
      })
    }

    deleteEvent(id: any){

        this.authService.hasPageLoaded.next(false)
        this.postService.disableEvent(id).pipe(takeUntil(this.unsubscribe$)).subscribe({
          next:res => {
          console.log(res);
          this.authService.hasPageLoaded.next(true)
          this.notificationService.showSuccess('Event Deleted Successfully!')
          this.eventsList=this.eventsList.filter((event:any) => event.id != id)
        },
        error:err=>{
          console.log(err);
          this.authService.hasPageLoaded.next(true)
          this.notificationService.showError('Something went Wrong!')
        }
      })
    
    }


    savePeople(){
      let formData = new FormData();
      formData.append('file',this.media)
      this.authService.hasPageLoaded.next(false)
      const imageUploadObservable = this.media ? this.postService.uploadMedia(formData): of(1)
      this.peopleForm.get('ownerId')?.setValue(this.loggedInUser.userId);
      let birthDate = this.peopleForm.get('birthDate')?.value;
      this.peopleForm.get('birthDate')?.setValue(new Date(birthDate).toLocaleDateString('en-GB').split('/').reverse().join('-'))
      this.peopleForm.get('remarks')?.setValue(this.peopleForm.get('content')?.value)
      imageUploadObservable.pipe(
        switchMap(imgres => {
          this.peopleForm.get('profileImage')?.setValue(imgres.url);
          return this.peopleId ? this.postService.updatePeople(this.peopleId,this.peopleForm.value)
                                :this.postService.createPeople(this.peopleForm.value);
        })
      )
      .pipe(takeUntil(this.unsubscribe$)).subscribe({
        next:res => {
          console.log(res)
          this.authService.hasPageLoaded.next(true)
          this.peopleForm.reset();
          this.notificationService.showSuccess(res.message??`People ${this.peopleId ? `updated` : `saved`} successfully!`);
          setTimeout(() => {
            location.reload();
          }, 1000);
        },
        error:err =>{
          this.authService.hasPageLoaded.next(true)
          this.notificationService.showError('Something went wrong');
          console.log(err);
        }
      })
    }

    getPeopleList(event:any){
      this.authService.hasPageLoaded.next(false)
      let params = new HttpParams()
      .set('pageNo',(event.first/10)+1)
      .set('limit',event.rows);
      
      forkJoin([this.postService.getPeople(params),this.postListService.getAllUser()])
      .pipe(
        map(res => {
          return res[0].map((interview:any) => {
            return {...interview,...res[1].find((u:any) => u.userId == interview.ownerId)}
          }) 
        }),
        takeUntil(this.unsubscribe$)).subscribe({
        next:res =>{
        let listData=res;
        console.log(listData)
        if(this.loggedInUser.roleName == 'SuperAdmin' || this.loggedInUser.roleName =='ContentApprover'){
          this.peopleList = listData;
          this.allPeopleList =[...this.peopleList]
          this.authService.hasPageLoaded.next(true)
          return;
        }
        this.peopleList= listData.filter((person:any) => person.ownerId == this.loggedInUser.userId);
        this.allPeopleList =[...this.peopleList]
        this.authService.hasPageLoaded.next(true)
      },
    error:err =>{
      console.log(err)
      this.authService.hasPageLoaded.next(true)
    }
  })
    }

    editPeople(id:any){
      this.peopleId=id;
      let person:any=this.peopleList.find(person => person.peopleId == id)
      console.log(person)
      if(person && !isNaN(new Date(person.birthDate).getTime())){
        person.birthDate = new Date(person.birthDate)
      }else{
        person.birthDate = new Date(person.createdDateTime)
      }
      this.peopleForm.patchValue(person);
      this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
    }

  onSelectionPeopleListFilterChange(event :any) {
    if(event.value == 'all'){
      this.peopleList = this.allPeopleList
      return;
    }

    this.peopleList=this.allPeopleList.filter((post:any) => post.status == event.value)
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
