import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  
  @Input() navigationItems!: any[]; //this array will be the navigation-links for this component;
  
  constructor(){}

  selectedSublink:any;
  
  viewSublinks(selected:string){
    this.selectedSublink=selected
  }

}
