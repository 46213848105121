import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, take } from 'rxjs';
import { NotificationService } from '../shared/services/notification.service';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'

})
export class AuthGuard implements CanActivate {

  isUserAuthenticated=false;

  loggedInUser:any;
  constructor(private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private location:Location) {
      this.getUserData();
    }

    ngOnInit(){
      this.authService.hasPageLoaded.next(true)
    }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      // console.log(this.loggedInUser)
      // return false;
      // this.authService.isAuthenticated.pipe(take(1)).subscribe(res => this.isUserAuthenticated=res);
      // const {token, loggedInUser} = JSON.parse(localStorage.getItem('token')?? '')
      //console.log(this.loggedInUser,Object.keys(this.loggedInUser).length)
      if(!this.loggedInUser || Object.keys(this.loggedInUser).length === 0){
        this.router.navigate(['/login']);
        return false;
      }

      if(this.loggedInUser.roleName=='ExternalAnalyst' && !this.loggedInUser.isEmailVerified){
        this.notificationService.showWarning('Email verification is pending!')
        this.authService.removeToken();
        this.authService.hasPageLoaded.next(true)
        return false;
      }

      //if user is customer and has email verified redirect to the home
      if(this.loggedInUser.roleName =='CBWReader' && this.loggedInUser.isEmailVerified){
        this.router.navigate([''])
        this.notificationService.showSuccess('Logged In successfully')
        return false;
      }
      if(this.loggedInUser.roleName =='CBWReader' && !this.loggedInUser.isEmailVerified){
        this.notificationService.showWarning('Email verification is pending!')
        this.authService.removeToken();
        this.authService.hasPageLoaded.next(true)
        return false;
      }

      return true;


  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    this.authService.hasPageLoaded.next(false);
    // console.log(childRoute.data)
    // console.log(state)
    // console.log('***************CHILD AUTH GUARD***************');
    // console.log('data: ' + childRoute + 'root :' +state.root + ' - object : ' + state.toString() + ' - url : ' + state.url);

    if (childRoute.data['userType'].includes(this.loggedInUser.roleName)) {

      if (this.loggedInUser.roleName == 'ExternalAnalyst' && !this.loggedInUser.isApproved && (state.url !== '/dashboard' && state.url !== '/dashboard/user-profile')) {
        this.notificationService.showWarning('Profile Approval is pending!')
        this.location.back()
        return false;
      }

      if (this.loggedInUser.roleName == 'SuperAdmin' && this.loggedInUser.roleName == 'ContentApprover' && state.url == '/dashboard/user-list') {
        this.notificationService.showError('You are not Authorized!')
        this.location.back()
        return false;
      }

      return true;
    }

    
    this.notificationService.showError('You are not Authorized!')
    // this.router.navigate(['/dashboard'])
    this.authService.hasPageLoaded.next(true)
    return false;
  }

  getUserData(){
    this.authService.getUser();
      this.authService.loggedInUser.subscribe(res => {
        this.loggedInUser=res.user
    });
  }

}
