import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  api=environment.apiURL
  constructor(private http: HttpClient) { }

  subscribeToCbw(email:string){ 
    return this.http.post(`${this.api}/api/subscribe/subscribeByEmailAddress/${email}`,{})
  }
}
