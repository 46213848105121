import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { PostListService } from 'src/app/shared/services/post-list.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent {
  user:any
  userType:string='Writer'
  private unsubscribe$ = new Subject<void>();
  loggedInUser:any;
  postData:any;
  approvedPosts:any;
  rejectedPosts:any;
  pendingPosts:any;
  draftPosts:any;
  approvedVideos:any;
  rejectedVideos:any;
  pendingVideos:any;

  constructor(private authService:AuthService,
    private router: Router,
    private postService: PostListService,
    private dashboardService: DashboardService){}

  ngOnInit(){
    this.authService.loggedInUser.subscribe(res => {
      this.userType=res.user.roleName;
      this.loggedInUser=res.user
    })

    this.loadData()
  }
  
   loadData(){
    let query={loggedInUser:this.loggedInUser}
  
    forkJoin({
      postDataCount: this.dashboardService.getPostCount('all',query),
      approvedPosts: this.dashboardService.getPostCount('approved',query),
      rejectedPosts: this.dashboardService.getPostCount('rejected',query),
      pendingPosts: this.dashboardService.getPostCount('pending',query),
      draftPosts: this.dashboardService.getPostCount('drafted',query),
      approvedVideos: this.dashboardService.getPostCount('approvedvideos',query),
      rejectedVideos: this.dashboardService.getPostCount('rejectedvideos',query),
      pendingVideos: this.dashboardService.getPostCount('pendingvideos',query),
    })
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next:res => {
      console.log(res)
      this.postData = res;
  
      this.authService.hasPageLoaded.next(true);
    },
  error:err => {
    console.log(err)
    this.authService.hasPageLoaded.next(true);
  }})
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
