<div class="videos">
  <div class="col-8 videos--left">
    <div class="bread-crumb">
      <p-breadcrumb class="max-w-full mb-4" [model]="items" [home]="home"></p-breadcrumb>
    </div>
    <div class="search">
      <p-dropdown class="select-publisher-dropdown" [options]="allUsersList" [(ngModel)]="selectedPublisher"
        optionLabel="name" placeholder="Select Publisher"></p-dropdown>
      <p-calendar class="p-calender-dropdown" [(ngModel)]="startDate" placeholder="Start Date"
        [showIcon]="true"></p-calendar>
      <p-calendar class="p-calender-dropdown" [(ngModel)]="endDate" placeholder="End Date"
        [showIcon]="true"></p-calendar>
    </div>
    <div class="videos--search">
      <input class="videos--search__input" [(ngModel)]="titleSearchValue" placeholder="Search Articles by Title" />
      <span class="videos--search__action">
        <span class="videos--search__search-btn" (click)="search(titleSearchValue, true)">Search</span>
        <span (click)="reset()" class="cursor-pointer ml-auto">
          <i class="fa fa-refresh"></i>
          <span>Reset</span>
        </span>
      </span>
    </div>
    <span class="videos--left--head">Videos</span>
    <div class="orange-line"></div>
    <div class="videos--left--body">
      <div *ngFor="
          let data of trendingVideosList
            | paginate
              : { itemsPerPage: 10, currentPage: page, totalItems: totalItems }
        ">
        <div *ngIf="data.postVideoURL != null" class="videos--left--body--boxes">
          <span>
            <video controls controlsList="nodownload" class="videos--left--body--boxes--video"
              src="{{ data.postVideoURL }}"></video>
          </span>
          <span class="videos--left--body--boxes--date">{{
            data.createdDateTime
            }}</span>
          <a routerLink="/articles-preview/{{ data.postTitle | urlPrettier }}"
            class="videos--left--body--boxes--description">{{ data.postTitle }}</a>
          <span class="videos--left--body--boxes__engagement">
            <span class="videos--left--body--boxes__engagement__likes">
              <img alt="cbw-img" src="assets/images/like.png" />
              <span class="videos--left--body--boxes__engagement__likes__count">
                {{ data.likeCount }}
              </span>
              <span class="videos--left--body--boxes__engagement__likes__text">
                Like(s) |
              </span>
            </span>
            <span class="videos--left--body--boxes__engagement__comments">
              <img alt="cbw-img" src="assets/images/comments.png" />
              <span class="videos--left--body--boxes__engagement__comments__count">
                {{ data.commentCount }}
              </span>
              <span class="videos--left--body--boxes__engagement__comments__text">
                Comment(s)</span>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="trendingVideosList.length != 0" class="pagination">
      <pagination-controls (pageChange)="pageChange($event)" [maxSize]="5" previousLabel=""
        nextLabel=""></pagination-controls>
    </div>
  </div>
  <div class="col-4 display">
    <app-latest-breaking-news-comp></app-latest-breaking-news-comp>
  </div>
</div>