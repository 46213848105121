<p-card class="post-card">
        <div class="wrapper">
            <ng-container [ngSwitch]="postType">
                <ng-template [ngSwitchCase]="''">
                  <ng-container *ngTemplateOutlet="PostVideo"></ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'Video'">
                    <ng-container *ngTemplateOutlet="PostVideo"></ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'Press'">
                    <ng-container *ngTemplateOutlet="Press"></ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'PeopleList'">
                    <ng-container *ngTemplateOutlet="PeopleList"></ng-container>
                    <ng-container *ngTemplateOutlet="PeopleListView"></ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'EventsList'">
                    <ng-container *ngTemplateOutlet="EventsList"></ng-container>
                    <ng-container *ngTemplateOutlet="EventsListView"></ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'UserProfile'">
                    <ng-container *ngTemplateOutlet="UserProfile"></ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'Interview'">
                    <ng-container *ngTemplateOutlet="Interview"></ng-container>
                </ng-template>
                <ng-template ngSwitchDefault>
                  <p>Rendered when condition does not match any case</p>
                </ng-template>
              </ng-container>
        </div>

</p-card>

<ng-template #PostVideo>
    <h3>{{mode == 'edit' ? 'Update' :'Create New'}} {{postType | titlecase }} Post</h3>
    <form [formGroup]="createPost">
        <div class="row">
            <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-2">
                <div class="flex flex-column ">
                    <label htmlFor="username">{{postType | titlecase }} Title</label>
                    <input pInputText class="p-inputtext-sm" formControlName="title" id="username" aria-describedby="username-help" />
                </div>
            </div>
            <div class="col-6  col-sm-12 col-lg-6 mb-2"></div>
            <!-- <div class="row align-items-end"> -->

            <div class="row">
                <div class="col-12 p-fluid mb-3">
                    <div class="flex flex-column gap-2">
                        <label htmlFor="username">Post Heading</label>
                        <input pInputText class="p-inputtext-sm" formControlName="postHeading" id="username" aria-describedby="username-help" />
                    </div>
                </div>
            </div>

            <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-3">
                <div class="flex flex-column gap-2">
                    <label htmlFor="username">Category</label>
                    <p-dropdown [options]="categoryList" optionLabel="categoryName" optionValue="categoryId" formControlName="category" styleClass="p-inputtext-sm"></p-dropdown>
                </div>
            </div>
            <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-3  align-items-end">
                <div class="flex flex-column gap-2">
                    <label htmlFor="username">Tags (For multiple Tags use semicolon as seperator. Eq.Tag1;Tag2)</label>
                    <input pInputText class="p-inputtext-sm" formControlName="tags" id="username" aria-describedby="username-help" />
                </div>
            </div>
            <div class="row">
                <div class="col-12 p-fluid mb-3">
                    <div class="flex flex-column gap-2">
                        <label htmlFor="username">Post Url</label>
                        <input pInputText class="p-inputtext-sm" formControlName="posturl" id="username" aria-describedby="username-help" />
                    </div>
                </div>
            </div>
            <!-- </div> -->
            <div class="col-12 col-sm-12 col-lg-12 p-fluid mb-2">
                <label htmlFor="username">Short Descirption</label>
                <textarea rows="3" cols="30" formControlName="desc" pInputTextarea></textarea>
            </div>
        </div>
        <div class="row" *ngIf="postType==''">
            <div class="col-12 mb-3">
                <ng-container *ngTemplateOutlet="editor; context: { label: 'Post Content' }" formControlName="content"></ng-container>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-fluid mb-3">
                <div class="flex flex-column gap-2">
                    <label htmlFor="username">Reference</label>
                    <input pInputText class="p-inputtext-sm" formControlName="reference" id="username" aria-describedby="username-help" />
                </div>
            </div>
        </div>

        <div class="row mt-3 mb-3">
            <div class="col-4">
                <div class="flex align-items-center gap-1">
                    <p-checkbox name="group1" formControlName="isHotStory" [binary]="true"  inputId="hot"></p-checkbox>
                    <label class="mb-0 ml-3" for="hot">Is hot story</label>
                </div>
            </div>
            <div class="col-4">
                <div class="flex align-items-center gap-1">
                    <p-checkbox name="group1" formControlName="isShownAsBanner" [binary]="true"  inputId="banner"></p-checkbox>
                    <label class="mb-0 ml-3" for="banner">Show as banner</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-8 p-fluid mb-3">
                <div class="flex flex-column gap-2">
                    <label htmlFor="username">Image Alt</label>
                    <input pInputText class="p-inputtext-sm" formControlName="imageAlt" id="username" aria-describedby="username-help" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-8" *ngIf="postType=='' || postType=='Interview' || postType=='Video'">
                <ng-container *ngTemplateOutlet="image"></ng-container>

                <div class="imgpreview">

                    <img alt="cbw-img" *ngIf="postType==''" width="450px" src="{{createPost.get('img')?.value}}" alt="">
                    <img alt="cbw-img" *ngIf="postType=='Video'" width="450px" src="{{createPost.get('postThumbnailURL')?.value}}" alt="">
                </div>

            </div>



            <div class="col-8" *ngIf="postType=='Video'|| postType=='Interview' ">
                <ng-container *ngTemplateOutlet="video"></ng-container>
                <div class="videopreview" *ngIf="createPost.get('postVideoURL')?.value">
                    <video
                        controls
                        controlsList="nodownload"
                        class="trending-videos--body--boxes--video"
                        src="{{createPost.get('postVideoURL')?.value}}"
                    ></video>
                </div>
            </div>

            <div class="col-12 mt-4">
                <div class="flex align-items-center gap-1" *ngIf="mode !='edit' || 
                    loggedInUser.roleName == 'SuperAdmin' || 
                    loggedInUser.roleName =='ContentApprover' ||
                    createPost.get('status')?.value =='drafted' ||
                    createPost.get('status')?.value =='rejected'
                    ">
                    <p-checkbox name="group1" formControlName="declaration" [binary]="true"  inputId="ny"></p-checkbox>
                    <label class="mb-0 ml-3" for="ny">I declare the content is created by me and I have mentioned all
                        referred external sources.</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 d-flex justify-content-end align-items-end gap-3">
                <p-button styleClass="p-button-sm" [disabled]="createPost.get('declaration')?.value==false" (click)="createPost.get('declaration')?.value ? savePost('pendingApproval') : '' " label="Submit"></p-button>
                <p-button styleClass="p-button-sm" [disabled]="createPost.get('declaration')?.value==false" (click)="createPost.get('declaration')?.value ? savePost('drafted') : '' " label="Save" *ngIf="loggedInUser.roleName != 'SuperAdmin' && loggedInUser.roleName !='ContentApprover'"></p-button>
            </div>
        </div>
    </form>
</ng-template>
<ng-template #Interview>
    <h3>{{mode == 'edit' ? 'Update' :'Create New'}} {{postType | titlecase }} Post</h3>
    <form [formGroup]="createPost">

        <div class="row align-items-end">
            <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-2">
                <div class="flex flex-column gap-2">
                    <label htmlFor="username">People Name</label>
                    <input pInputText class="p-inputtext-sm" formControlName="peoplename" id="username" aria-describedby="username-help"/>
                </div>
            </div>
            <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-2">
                <label htmlFor="username">Interview Date</label>
                <p-calendar class="p-inputtext-sm" formControlName="interviewDate" [showIcon]="true" appendTo="body"  dateFormat="dd.mm.yy"></p-calendar>
            </div>

            <div class="col-12 col-sm-12 col-lg-12 p-fluid mb-2">
                <label htmlFor="username">Short Descirption</label>
                <textarea rows="3" cols="30" formControlName="desc" pInputTextarea ></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col-12 p-fluid mb-3">
                <div class="flex flex-column gap-2">
                    <label htmlFor="username">Interview Text</label>
                    <input pInputText class="p-inputtext-sm" formControlName="content" id="username" aria-describedby="username-help"/>
                </div>
            </div>


                <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-2">
                    <div class="flex flex-column gap-2">
                        <label htmlFor="username">YouTube Url</label>
                        <input pInputText class="p-inputtext-sm" formControlName="youtubeurl" id="username" aria-describedby="username-help"/>
                    </div>
                </div>
                <div class="col-6 col-sm-12 col-lg-6 mb-2 mt-1 p-fluid">
                    <label htmlFor="username">Industry Type</label>
                    <p-dropdown [options]="industryList" optionLabel="industryName" optionValue="industryId" formControlName="industry"   styleClass="p-inputtext-sm"></p-dropdown>
                </div>

                <div class="col-12 p-fluid mb-3">
                    <div class="flex flex-column ">
                        <label htmlFor="topic">Topic</label>
                        <input pInputText class="p-inputtext-sm" formControlName="topic" id="topic" aria-describedby="topic-help"/>
                    </div>
                </div>


                    <div class="col-12 p-fluid mb-3">
                        <div class="flex flex-column gap-2">
                            <label htmlFor="username">Post Url</label>
                            <input pInputText class="p-inputtext-sm" formControlName="posturl" id="username" aria-describedby="username-help" />
                        </div>
                    </div>

        </div>

        <div class="row">
            <div class="col-8" *ngIf="postType=='' || postType=='Interview' || postType=='Video'">
                <ng-container *ngTemplateOutlet="image"></ng-container>

                <div class="imgpreview">
                    <img alt="cbw-img" width="450px" src="{{createPost.get('img')?.value}}" alt="">
                </div>

            </div>



            <div class="col-8" *ngIf="postType=='Video'|| postType=='Interview' ">
                <ng-container *ngTemplateOutlet="video"></ng-container>
            </div>

            <div class="col-12 mt-4">
                <div class="flex align-items-center gap-1" *ngIf="mode !='edit' || 
                loggedInUser.roleName == 'SuperAdmin' || 
                loggedInUser.roleName =='ContentApprover' ||
                createPost.get('status')?.value =='drafted'||
                createPost.get('status')?.value =='rejected'
                ">
                    <p-checkbox name="group1" formControlName="declaration"  [binary]="true" inputId="ny"></p-checkbox>
                    <label class="mb-0 ml-3" for="ny">I declare the content is created by me and I have mentioned all
                        referred external sources.</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 d-flex justify-content-end align-items-end gap-3">
                <p-button styleClass="p-button-sm" [disabled]="createPost.get('declaration')?.value==false" (click)="createPost.get('declaration')?.value ? savePost('pendingApproval') :'' " label="Submit"></p-button>
                <p-button styleClass="p-button-sm" [disabled]="createPost.get('declaration')?.value==false" (click)="createPost.get('declaration')?.value ? savePost('drafted') :'' " label="Save" *ngIf="loggedInUser.roleName != 'SuperAdmin' && loggedInUser.roleName !='ContentApprover'"></p-button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #Press>
    <div [formGroup]="createPost">
    <h3>{{mode == 'edit' ? 'Update Press Release' : 'Create New Press Release'}}</h3>
    <div class="row">
        <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Press Release Title</label>
                <input pInputText class="p-inputtext-sm" formControlName="title" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6  col-sm-12 col-lg-6 mb-2"></div>

        <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-3 align-items-end">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Tags (For multiple Tags use semicolon as seperator. Eq.Tag1;Tag2)</label>
                <input pInputText class="p-inputtext-sm" formControlName="tags" aria-describedby="username-help" />
            </div>
        </div>

        <div class="col-12 p-fluid mb-3">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Post Heading</label>
                <input pInputText class="p-inputtext-sm" formControlName="postHeading" id="username" aria-describedby="username-help" />
            </div>
        </div>

        <div class="col-12 col-sm-12 col-lg-12 p-fluid mb-2">
            <label htmlFor="username">Short Descirption</label>
            <textarea rows="3" cols="30" formControlName="desc"  pInputTextarea></textarea>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mb-3">
            <ng-container *ngTemplateOutlet="editor; context: { label: 'Press Release Content' }"></ng-container>
        </div>
    </div>

    <div class="row">
        <div class="col-12 p-fluid mb-3">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Post Url</label>
                <input pInputText class="p-inputtext-sm" formControlName="posturl" id="username" aria-describedby="username-help" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 p-fluid mb-3">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Reference</label>
                <input pInputText class="p-inputtext-sm" formControlName="reference" aria-describedby="username-help" />
            </div>
        </div>
    </div>

    <div class="row mt-3 mb-3">
        <div class="col-4">
            <div class="flex align-items-center gap-1">
                <p-checkbox name="group1" formControlName="isHotStory" [binary]="true"  inputId="hot"></p-checkbox>
                <label class="mb-0 ml-3" for="hot">Is hot story</label>
            </div>
        </div>
        <div class="col-4">
            <div class="flex align-items-center gap-1">
                <p-checkbox name="group1" formControlName="isShownAsBanner" [binary]="true"  inputId="banner"></p-checkbox>
                <label class="mb-0 ml-3" for="banner">Show as banner</label>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-8 p-fluid mb-3">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Image Alt</label>
                <input pInputText class="p-inputtext-sm" formControlName="imageAlt" id="username" aria-describedby="username-help" />
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-8">
            <ng-container *ngTemplateOutlet="image"></ng-container>
            <img alt="cbw-img" width="450px" src="{{createPost.get('postThumbnailURL')?.value}}" alt="">
        </div>
        <div class="col-8">
            <ng-container *ngTemplateOutlet="video"></ng-container>

            <div class="videopreview" *ngIf="createPost.get('postVideoURL')?.value">
                <video
                    controls
                    controlsList="nodownload"
                    class="trending-videos--body--boxes--video"
                    src="{{createPost.get('postVideoURL')?.value}}"
                ></video>
            </div>
        </div>

        <div class="col-12 mt-4">
            <div class="flex align-items-center gap-1" *ngIf="mode !='edit' || 
            loggedInUser.roleName == 'SuperAdmin' || 
            loggedInUser.roleName =='ContentApprover' ||
            createPost.get('status')?.value =='drafted'||
            createPost.get('status')?.value =='rejected'
            ">
                <p-checkbox name="group1" formControlName="declaration" value="accept" inputId="ny"></p-checkbox>
                <label class="mb-0 ml-3" for="ny">I declare the content is created by me and I have mentioned all
                    referred external sources.</label>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 d-flex justify-content-end align-items-end gap-3">
            <p-button styleClass="p-button-sm" [disabled]="createPost.get('declaration')?.value==false" (click)="createPost.get('declaration')?.value ? savePost('pendingApproval') : '' " label="Submit"></p-button>
            <p-button styleClass="p-button-sm" [disabled]="createPost.get('declaration')?.value==false" (click)="createPost.get('declaration')?.value ? savePost('drafted') : '' " label="Save" *ngIf="loggedInUser.roleName != 'SuperAdmin' && loggedInUser.roleName !='ContentApprover'"></p-button>
        </div>
    </div>
</div>
</ng-template>

<ng-template #PeopleList>
    <h3>People List</h3>
    <div [formGroup]="peopleForm">
    <div class="row align-items-end">
        <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">People Name</label>
                <input pInputText class="p-inputtext-sm" formControlName="peopleName" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-6 p-fluid mb-2">
            <label htmlFor="username">People Role</label>
            <input pInputText class="p-inputtext-sm" formControlName="peopleRole" aria-describedby="username-help" />
        </div>

        <div class="col-12 col-sm-12 col-lg-12 p-fluid mb-2">
            <label htmlFor="username">Short Descirption</label>
            <textarea rows="3" cols="30" formControlName="shortDescription" pInputTextarea></textarea>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mb-3">
            <ng-container *ngTemplateOutlet="editor; context: { label: 'Biography' }"></ng-container>
        </div>
    </div>

    <div class="row">
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Place</label>
                <input pInputText class="p-inputtext-sm" formControlName="place" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Birth Date</label>
                <p-calendar class="p-inputtext-sm" [style]="{height:'44px'}" formControlName="birthDate" [showIcon]="true" appendTo="body"  dateFormat="dd.mm.yy"></p-calendar>
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Known For</label>
                <input pInputText class="p-inputtext-sm" formControlName="knownFor" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">WebLink</label>
                <input pInputText class="p-inputtext-sm" formControlName="webLink" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Industry Type</label>
                <p-dropdown [options]="industryList" optionLabel="industryName" optionValue="industryId" formControlName="industryId" styleClass="p-inputtext-sm"></p-dropdown>
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Profile Image</label>
                <ng-container *ngTemplateOutlet="image"></ng-container>
                <span class="font-italic">{{peopleForm.get('profileImage')?.value?.split('/').reverse()[0]}}</span>
                <!-- <div class="imgpreview">
                    <img alt="cbw-img" width="450px" src="{{createPost.get('img')?.value}}" alt="">
                </div> -->
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Facebook Link</label>
                <input pInputText class="p-inputtext-sm" formControlName="facebook" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Twitter Link</label>
                <input pInputText class="p-inputtext-sm" formControlName="twitter" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Linked-In Link</label>
                <input pInputText class="p-inputtext-sm" formControlName="linkedIn" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Instagram Link</label>
                <input pInputText class="p-inputtext-sm" formControlName="instagram" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Blog Link</label>
                <input pInputText class="p-inputtext-sm" formControlName="blog" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2"></div>
    </div>
    </div>
    <div class="row">
        <div class="col-12 d-flex justify-content-end align-items-end gap-3">

            <p-button styleClass="p-button-sm" (click)="savePeople()" label="Save" *ngIf="loggedInUser.roleName != 'SuperAdmin' && loggedInUser.roleName !='ContentApprover'"></p-button>
        </div>
    </div>
</ng-template>

<ng-template  #PeopleListView>
    <hr>
    <!-- <div class="container-fluid"> -->
        <div class="row">
            <div class="col">

                <p-table #dt [value]="peopleList" scrollDirection="horizontal" [style]="{ 'width': 'calc(100vw - 310px)' }"
                    [responsive]="true" responsiveLayout="stack" [breakpoint]="'960px'" [rows]="10"
                    [paginator]="true" [rowsPerPageOptions]="[10, 20, 50, 100, 200, 300, 500]"[lazy]="true"
                    (onLazyLoad)="getPeopleList($event)" [totalRecords]="totalRecords"
                    [globalFilterFields]="['peopleName','peopleRole','birthDate','place','industryName','knownFor','webLink','facebook']"
                    styleClass=" p-datatable-gridlines p-datatable-striped p-datatable-sm" selectionMode="single"
                    [rowHover]="true">
                    <ng-template pTemplate="caption">
                        <div class="flex p-fluid justify-content-between">
                            <div class="col-2">
                                <div class="flex flex-column md:flex-row md:justify-content-between">
                                    <p-dropdown [options]="filterBy" placeholder="" (onChange)="onSelectionPeopleListFilterChange($event)"
                                        styleClass="p-inputtext-sm mb-2 md:mb-0 "></p-dropdown>
                                </div>
                            </div>
                            <div class="col-2">
                                <h3>List</h3>
                            </div>
                            <div class="col-2">
                                <!-- <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" class="p-inputtext-sm" #textInput
                                        (input)="dt.filterGlobal(textInput.value, 'contains')"
                                        placeholder="Search keyword" />
                                </span> -->
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th></th>
                            <th [pSortableColumn]="'name'">Name
                                <p-sortIcon [field]="'name'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'role'">Role
                            </th>
                            <th [pSortableColumn]="'birthdate'">Birth Date
                            </th>
                            <th [pSortableColumn]="'Place'">Place
                            </th>
                            <th [pSortableColumn]="'indstrytype'">Indstry Type
                            </th>
                            <th [pSortableColumn]="'knowfor'">Known For
                            </th>
                            <th [pSortableColumn]="'weblink'">WebLink
                            </th>
                            <th *ngIf="loggedInUser.roleName=='SuperAdmin'">Created By</th>
                            <th [pSortableColumn]="'social'">Social Media Link
                            </th>
                            <th></th>
                        </tr>
                        <!-- {{peopleList |json}} -->
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" (dblclick)="editPeople(rowData.peopleId)">
                            <td class="text-center">
                                <a (click)="deleteRecord(rowData.peopleId)" pTooltip="Delete">
                                    <i class="pi pi-trash"></i>
                                </a>
                            </td>

                            <td><span class="p-column-peopleName"></span>{{rowData?.peopleName}}</td>
                            <td><span class="p-column-peopleRole"></span>{{rowData?.peopleRole}}</td>
                            <td><span class="p-column-birthDate"></span>{{rowData?.birthDate | date: 'dd/MM/yyyy'}}</td>
                            <td><span class="p-column-place"></span>{{rowData?.place}}</td>
                            <td><span class="p-column-industryName"></span>{{rowData?.industryName??rowData?.industryId}}</td>
                            <td><span class="p-column-knownFor"></span>{{rowData?.knownFor}}</td>
                            <td><span class="p-column-webLink"></span>{{rowData?.webLink}}</td>
                            <td *ngIf="loggedInUser.roleName=='SuperAdmin'"><span class="p-column-createdBy"></span>{{rowData?.firstName}} {{rowData?.lastName}}</td>
                            <td><span class="p-column-facebook"></span>{{rowData?.facebook}}</td>
                            <!-- <td>Preview</td> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="9">No Records found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    <!-- </div> -->

    <!-- <p-confirmDialog header="Confirmation"  icon="pi pi-exclamation-triangle" message="Are you sure you want to proceed?"
    [style]="{width: '350px'}" acceptButtonStyleClass="p-button-text" rejectButtonStyleClass="p-button-text"></p-confirmDialog> -->
</ng-template>

<ng-template #video>
    <label htmlFor="video">Upload Video</label>
    <br>
    <span class="font-italic mb-2"> {{mediaVid?mediaVid.name:''}}</span>

    <p-fileUpload #img mode="basic" [customUpload]="true" [auto]="true" [style]="{width: '130px'}"  accept=""
    [maxFileSize]="50000000" chooseLabel="Browse"  (uploadHandler)="fileUpload($event,img,'video')"></p-fileUpload>
    <p class="mt-3">Note: Video files having format .mp4,.avi,.3gp or .mov are allowed to be uploaded with file
        size limit of 50 MB max.</p>
</ng-template>

<ng-template #EventsList>
    <h3>Events Details</h3>
    <div [formGroup]="eventDetailForm">
    <div class="row">
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Event Type</label>
                <p-dropdown class="p-inputtext-sm" formControlName="eventtype" [options]="eventTypeList"></p-dropdown>
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Region</label>
                <p-dropdown class="p-inputtext-sm" formControlName="region" [filter]="true" optionLabel="name" optionValue="id"  [options]="regionList"></p-dropdown>
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Event Name</label>
                <input pInputText class="p-inputtext-sm" formControlName="eventname" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Event Description</label>
                <textarea id="float-input" formControlName="eventdesc" rows="1"  pInputTextarea></textarea>
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Event Date</label>
                <p-calendar class="p-inputtext-sm" [style]="{height:'44px'}" placeholder="Event date" formControlName="eventdate" [showIcon]="true" appendTo="body" dateFormat="dd.mm.yy"></p-calendar>
                <!-- <input pInputText class="p-inputtext-sm" formControlName="eventdate" aria-describedby="username-help" /> -->
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Event Venue</label>
                <input pInputText class="p-inputtext-sm" formControlName="eventvenue" aria-describedby="username-help" />
            </div>
        </div>

        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Event Website</label>
                <input pInputText class="p-inputtext-sm" formControlName="eventwebsite" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">Event Image Url</label>
                <input pInputText class="p-inputtext-sm" formControlName="eventimageurl" aria-describedby="username-help" />
            </div>
        </div>
        <div class="col-6 col-sm-12 col-lg-4 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="username">View Event Details Url</label>
                <input pInputText class="p-inputtext-sm" formControlName="eventdetailurl" aria-describedby="username-help" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 d-flex justify-content-between align-items-end gap-3">
            <div class="active">
                <label class="mb-0" for="active">Active</label><br>
                <p-checkbox name="group1" formControlName="active" [binary]="true" inputId="active"></p-checkbox>
            </div>
            <p-button styleClass="p-button-sm" (click)="saveEventDetail('approved')" label="Save" ></p-button>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <a href="#" target="_blank">Preview Created/Updated Events</a>
        </div>
    </div>
</div>
</ng-template>

<ng-template #EventsListView>
    <hr>
    <div class="container-fluid">
        <div class="row">
            <div class="col">

                <p-table #dt [value]="eventsList" responsiveLayout="stack" [breakpoint]="'960px'" [rows]="10"
                    [paginator]="true" [rowsPerPageOptions]="[10, 20, 50, 100, 200, 300, 500]"
                    [globalFilterFields]="['region','eventname eventvenue','description']"
                    styleClass=" p-datatable-gridlines p-datatable-striped p-datatable-sm" selectionMode="single"
                    [rowHover]="true"  [lazy]="true"
                    (onLazyLoad)="getEventData($event)" [totalRecords]="totalRecords">
                    <ng-template pTemplate="caption">
                        <div class="flex p-fluid align-items-center justify-content-between">
                            <div class="col-2">
								<div class="flex flex-column md:flex-row md:justify-content-between">
									<p-dropdown [options]="filterBy" placeholder="" [(ngModel)]="selectedFilter" (onChange)="onSelectionEventFilterChange($event)"
										styleClass="p-inputtext-sm mb-2 md:mb-0 "></p-dropdown>
								</div>
							</div>
                            <div class="col-2">
                                <h3>Events</h3>
                            </div>
                            <div class="col-2">
                                <span class="p-input-icon-left ml-auto">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" class="p-inputtext-sm" #textInput
                                        (input)="dt.filterGlobal(textInput.value, 'contains')"
                                        placeholder="Search keyword" />
                                </span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th></th>
                            <th [pSortableColumn]="'region'">Region
                                <p-sortIcon [field]="'region'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'eventname'">Event Name
                                <p-sortIcon [field]="'eventname'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'description'">Event Description
                            </th>
                            <th [pSortableColumn]="'eventdate'">Event Date
                                <p-sortIcon [field]="'eventdate'"></p-sortIcon>
                            </th>
                            <th *ngIf="loggedInUser.roleName=='SuperAdmin'">Created By</th>
                            <th [pSortableColumn]="'eventvenue'">Event Venue
                            </th>
                            <th [pSortableColumn]="'eventwebsite'">Event Website
                            </th>
                            <!-- <th></th> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" (dblclick)="editEvent(rowData.id)">
                            <td class="text-center">
                                <a (click)="deleteEvent(rowData.id)" pTooltip="Delete">
                                    <i class="pi pi-trash"></i>
                                </a>
                            </td>
                            <td><span class="p-column-region"></span>{{rowData?.region}}</td>
                            <td><span class="p-column-eventName"></span>{{rowData?.eventname}}</td>
                            <td><span class="p-column-description"></span>{{rowData?.description?.length >= 50 ? rowData?.description.substring(0,45).concat('...'): rowData?.description}}</td>
                            <td><span class="p-column-eventdate"></span>{{rowData?.eventdate | date: 'dd/MM/yyyy'}}</td>
                            <td *ngIf="loggedInUser.roleName=='SuperAdmin'"><span class="p-column-createdBy"></span>{{rowData?.user?.firstName}} {{rowData?.user?.lastName}}</td>
                            <td><span class="p-column-eventvenue"></span>{{rowData?.eventvenue}}</td>
                            <td><span class="p-column-eventwebsite"></span>{{rowData?.eventwebsite}}</td>

                            <!-- <td>
                                <span (click)="deactiveEvent(rowData?.id)">De Active</span>
                            </td> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6">No Records found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #UserProfile>
    <!-- <div class="container-fluid"> -->
        <div class="row">
            <div class="col-12 col-sm-12 col-md-4">
                <div class="row user-header bg-warning align-items-center">
                    <div class="col-3 col-md-3 user-hearder-image">
                        <img alt="cbw-img" height="70px" class="border-circle" src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80" alt="">
                    </div>
                    <div class="col-9 col-md-9 user-header-email text-white font-bold text-lg">
                        <span>{{userProfileData?.username}}</span>
                    </div>
                </div>
                <div class="row user-progress-details border-bottom-1">
                    <ng-container *ngFor="let progressdetail of progressDetails;">
                        <div class="col-10 col-md-10">{{progressdetail.name}}</div>
                        <div class="col-2 col-md-2 text-right font-bold"><i class="pi pi-check"></i></div>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-8"></div>
        </div>

        <div class="row tab-view">
            <div class="col-12 col-md-12">
                <p-tabView [scrollable]="true" [style]="{'position':'relative'}">
                    <p-button styleClass="p-button-sm" class="absolute" [ngStyle]="{'right':'20px','top':'70px'}" label="Submit"></p-button>
                    <p-tabPanel header="Basic Info">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">First Name</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Last Name</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Email</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column " id="mobile">
                                    <label htmlFor="username">Mobile</label>
                                    <ngx-intl-tel-input
                                        [cssClass]="'form-control'"
                                        [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[
                                        SearchCountryField.Iso2,
                                        SearchCountryField.Name
                                        ]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="CountryISO.India"
                                        [maxLength]="15"
                                        [phoneValidation]="true"
                                        [separateDialCode]="false"
                                        [numberFormat]="PhoneNumberFormat.National"
                                        name="phone"

                                ></ngx-intl-tel-input>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column p-fluid ">
                                    <label htmlFor="username">Specialization</label>
                                    <p-dropdown class="p-inputtext-sm" [options]="specializationList"></p-dropdown>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Blog Link</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">LinkedIn Link</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Facebook Link</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Twitter Link</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <!-- <div class="flex flex-column ">
                                    <label htmlFor="username">Facebook Link</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div> -->
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Education Details">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Institute</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Course Type</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label htmlFor="username">Year Of Completion</label>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="flex flex-column  p-fluid">
                                            <p-calendar class="p-inputtext-sm" placeholder="Start Year" [showIcon]="true" appendTo="body" dateFormat="dd.mm.yy"></p-calendar>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="flex flex-column  p-fluid  ">
                                            <p-calendar class="p-inputtext-sm" placeholder="End Year" [showIcon]="true" appendTo="body" dateFormat="dd.mm.yy"></p-calendar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column  p-fluid">
                                    <label htmlFor="username">Degree</label>
                                    <p-dropdown [options]="degreeList"></p-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="row edu-list">
                            <ng-container *ngFor="let edu of edulist; index as i">
                                <div class="col-12 col-md-10 detail">
                                    <span class="institute font-bold block">{{edu.institute}}</span>
                                    <span class="course block">{{edu.course}}</span>
                                    <span class="degree block">{{edu.degree}}</span>
                                    <span class="year block">{{edu.year}}</span>
                                </div>
                                <div class="col-12 col-md-2 delete">
                                    <a href="#">Delete</a>
                                </div>
                            </ng-container>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Professional Details">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Designation</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Organization</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label htmlFor="username">From</label>
                                <div class="flex flex-column  p-fluid">
                                    <p-calendar class="p-inputtext-sm" placeholder="From" [showIcon]="true" appendTo="body" dateFormat="dd.mm.yy"></p-calendar>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column  p-fluid">
                                    <label htmlFor="username">To</label>
                                    <p-calendar class="p-inputtext-sm" placeholder="To" [showIcon]="true" appendTo="body" dateFormat="dd.mm.yy"></p-calendar>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Which domain this experience is relevant for?</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Is this experience relevant for crypto asset?</label>
                                    <div class="flex flex-wrap gap-3">
                                        <div class="flex align-items-center">
                                            <p-radioButton name="cryptoexp" [(ngModel)]="cryptoexp" value="Yes"  inputId="cryptoexpyes"></p-radioButton>
                                            <label for="cryptoexpyes" class="ml-2">Yes</label>
                                        </div>

                                        <div class="flex align-items-center">
                                            <p-radioButton name="cryptoexp" [(ngModel)]="cryptoexp" value="No"  inputId="cryptoexpno"></p-radioButton>
                                            <label for="cryptoexpno" class="ml-2">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-md-12">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Provide brief details about your experience</label>
                                    <textarea rows="2"  pInputTextarea ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row professional-detail-list">
                            <ng-container *ngFor="let pd of professionalDetailList; index as i">
                                <div class="col-12 col-md-10 detail">
                                    <span class="designation font-bold block">{{pd.designation}}</span>
                                    <span class="fromto block">{{pd.from | date: 'dd/MM/yyyy'}} - {{pd.to | date: 'dd/MM/yyyy'}}</span>
                                    <span class="organization block">{{pd.organization}}</span>
                                    <span class="desc block">{{pd.desc}}</span>
                                </div>
                                <div class="col-12 col-md-2 delete">
                                    <a href="#">Delete</a>
                                </div>
                            </ng-container>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="File Upload">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Resume</label>
                                    <p-fileUpload mode="basic" name="demo[]" accept="image/png,image/jpg,image/jpeg,image/svg,image/webp"
                                        [maxFileSize]="1000000" chooseLabel="Browse"></p-fileUpload>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Profile Image</label>
                                    <p-fileUpload mode="basic" name="demo[]" accept="image/png,image/jpg,image/jpeg,image/svg,image/webp"
                                    [maxFileSize]="1000000" chooseLabel="Browse"></p-fileUpload>
                                </div>
                            </div>
                        </div>

                        <div class="row fileupload-list">
                            <ng-container *ngFor="let fu of fileUploadList; index as i">
                                <div class="col-12 col-md-10 detail">
                                    <span class="filename font-bold block">{{fu.name}}</span>
                                </div>
                                <div class="col-12 col-md-2 delete">
                                    <!-- <a href="#">Delete</a> -->
                                </div>
                            </ng-container>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Other Details">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Date of Birth</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Gender</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Country</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">State</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">City</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Experience in which industry</label>
                                    <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">Why do you think you are a suitable candidate for this role?</label>
                                    <textarea rows="2" pInputTextarea></textarea>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">What specific experience do you have in the domain of crypto assets?</label>
                                    <textarea rows="2" pInputTextarea></textarea>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="About Yourself">
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <div class="flex flex-column ">
                                    <label htmlFor="username">About Yourself</label>
                                    <textarea rows="4" pInputTextarea></textarea>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    <!-- </div> -->
</ng-template>

<ng-template #image>
    <label htmlFor="image" *ngIf="postType !='PeopleList'">{{postType=='Video' || postType=='Press' ? 'Thumbnail Image' :'Post Featured Image'}}</label>
    <p-fileUpload #img mode="basic" [customUpload]="true" [auto]="true" [style]="{height:'44px',width: '130px'}"  accept="image/png,image/jpg,image/jpeg,image/svg,image/webp"
        [maxFileSize]="100000000" chooseLabel="Browse"  (uploadHandler)="fileUpload($event,img,'image')"></p-fileUpload>
    <p class="mt-3" *ngIf="postType !='PeopleList'">Note: Image resolution must be equal or more than in the ratio of 525 * 450 (Width * Height)
    </p>
</ng-template>

<ng-template #editor let-label="label" [formGroup]="postType == 'PeopleList' ? peopleForm :createPost">
    <label htmlFor="username">{{label}}</label>
    <p-editor [style]="{ height: '320px' }" [formControlName]="postType == 'PeopleList' ? 'biography' :'content'"></p-editor>
</ng-template>

