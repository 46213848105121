import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, forkJoin, lastValueFrom, map, Subject, switchMap, take, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PostListService } from '../../shared/services/post-list.service';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent {
  private unsubscribe$ = new Subject<void>();

  userType='';
  postType:any='';
  postList=[];
  selectedPosts:any[] = [];
  representatives:any
  statuses:any
  activityValues:any
  filterBy = [
    { label: 'All', value: 'all' },
    { label: 'Draft', value: 'drafted' },
    { label: 'Approval Pending', value: 'pendingApproval' },
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },

];
allPosts:any;
loggedInUser:any;
totalRecords=100000
selectedFilter:'all'
rejectionReasonMessage: string = ''
rejectionReasonDialog: boolean = false;
userSelectedForReject: any;
rejectionMessageEmpty: boolean = false;
bulkReject=false;

userList:any;

@ViewChild('textInput') textInput:ElementRef;
@ViewChild('dt') dt:any;
constructor(private activeRoute : ActivatedRoute,
  private authService: AuthService,
  private router: Router,
  private postService: PostListService,
  private notificationService: NotificationService){}

  ngOnInit(){
    this._checkActiveRoute();
    this.getUsers();
    this.authService.loggedInUser.subscribe(res => {
      this.loggedInUser = res.user;
      this.userType=res.user.roleName;
    })

    // this.getPostData();
  }

  _checkActiveRoute(){
    var path = this.activeRoute.snapshot.url[0].path
    switch (path) {
      case 'video-list':
        this.postType='Video'
        break;
      default:
        this.postType='Post'
        break;
    }

  }

  previewRecord(id:string,title:string){
    this.router.navigate(['preview',id,title.toLowerCase().split(' ').join('-')])
  }

  editRecord(id:string,post:any){
    console.log(id,post)
    if( this.postType=='Video'){
      this.router.navigate(['dashboard/create-video/edit/'+id])
      return;
    }

    if(post.categoryname == 'Press'){
      this.router.navigate(['dashboard/press-release/edit/'+id])
      return;
    }

    this.router.navigate(['edit',id], { relativeTo: this.activeRoute })
  }

  deleteRecord(id:string){
    this.authService.hasPageLoaded.next(false)
    this.postService.disablePost(id).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next:res => {
      console.log(res);
      this.authService.hasPageLoaded.next(true)
      this.notificationService.showSuccess('Post Deleted Successfully!')
      // setTimeout(() => {
      //   location.reload();
      // }, 1000);
      this.postList=this.postList.filter((post:any) => post.postId != id)
    },
    error:err=>{
      console.log(err);
      this.authService.hasPageLoaded.next(true)
      this.notificationService.showError('Something went Wrong!')
    }
  })
  }

  onSelectionChange(event :any) {
    // if(event.value != 'all'){
    //   this.authService.hasPageLoaded.next(false);
    //   this.postService.getPostByUserId(this.loggedInUser.userId,event.value)
    //   .pipe(take(1)).subscribe(res => {
    //     this.postList= res
    //     this.authService.hasPageLoaded.next(true)
    //   })
    //   return;
    // }
    console.log(this.textInput.nativeElement.value, event)
    // this.getPostData({
    //   first:0,rows:10,
    //   filters:{global:{value:this.textInput.nativeElement.value}},
    // })

    if(event.value == 'all'){
      this.postList = this.allPosts
      return;
    }

    this.postList=this.allPosts.filter((post:any) => post.status == event.value)
}

catchReview(event: any,postid:any){
  console.log(event,postid)
  // if(!this.selectedPosts.length) return;
  this.userSelectedForReject=postid;

  if(event.trigger == 'reject'){
    this.rejectionReasonDialog=true;
    return;
    
    return;
  }else{
    this.updatePostStatus('approved',postid);
  }
}

rejectPost(){
  console.log('rejectionReasonMessage is: ', this.rejectionReasonMessage);
 if(!this.rejectionReasonMessage) {
      this.rejectionMessageEmpty = true
      return;
    } else {
      this.rejectionMessageEmpty = false
    }

    this.rejectionReasonDialog = false;
    if(this.bulkReject){
      this.multipleSelectionReview({trigger:'reject'},false)
    }else{
      this.updatePostStatus('rejected',this.userSelectedForReject,this.rejectionReasonMessage);
    }
    

}


getUsers(){
  this.postService.getAllUser().pipe(takeUntil(this.unsubscribe$)).subscribe({
    next:res => {
    this.userList = res
  },
  error:err => {
    console.log(err);
  }
})
}

getPostData(event:any){
  //this.selectedFilter='all' //reset the filter to all after every new call
  // console.log(event)
  let catId;
  if(this.postType == 'Video'){
    catId=10;
  }

  let getPosts = this.postService.getPosts(
    {
      categoryId:catId,publisherId:this.loggedInUser.userId,
      page:event.first,row:event.rows,
      searchKey:event.filters.global?.value, 
      selectedfilter:this.selectedFilter
    }
  );
  if(this.loggedInUser.roleName == 'SuperAdmin' || this.loggedInUser.roleName =='ContentApprover'){
    getPosts = this.postService.getAllPosts(
      {
        categoryId:catId,publisherId:this.loggedInUser.userId,
        page:event.first,row:event.rows,
        searchKey:event.filters.global?.value,
        selectedfilter:this.selectedFilter
      });
  }
  this.authService.hasPageLoaded.next(false)

  forkJoin([getPosts] )
  .pipe(takeUntil(this.unsubscribe$),
    map(res => {
      console.log(res)
      return res[0].map((post:any) => {
        return {...post,user:{...this.userList.find((u:any) => u.userId == post.ownerId)}}
      })
    }),
    map( (res:any) => {
      console.log(res)
      return res.filter((post:any) => {

        if(this.postType != 'Video'){
          return post.categoryname != 'Videos' &&
          post.categoryname != 'Interview' &&
          post.categoryname != 'RecommendedNews'
        }
        return res

      })
    })
  )
  .subscribe({
    next:res => {
    console.log(res)
    this.postList = res
    this.allPosts =[...this.postList]
    this.authService.hasPageLoaded.next(true)
  },
    error:err => {
      console.log(err)
        this.authService.hasPageLoaded.next(true)
    }})
}

updatePostStatus(status:any,postid:any, remarks=''){
  console.log(status,remarks)
  const query = {
    adminId: this.loggedInUser.userId,
    status: status,
    remarks: remarks
  }
  
  this.authService.hasPageLoaded.next(false)
  // this.selectedPosts.forEach(postid => {
    this.postService.updatePostStatus(postid,query)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: res =>{
        console.log(res)
        this.authService.hasPageLoaded.next(true)
        this.notificationService.showSuccess(`Post(s) ${status} successfully`)
        setTimeout(() => {
          // this.getPostData();
          location.reload()
        }, 1000);
      },
      error:err=>{
        this.notificationService.showError('Something went wrong')
        console.log(err)
        this.authService.hasPageLoaded.next(true)
      }
    })

  // })

}

multipleSelectionReview(event:any,initial=true){
  
  if(!this.selectedPosts.length)
    return;

    
  const query = {
    adminId: this.loggedInUser.userId,
    status: event.trigger == 'approve' ? 'approved' : 'rejected',
    remarks:  this.rejectionReasonMessage
  }

  if(query.status == 'rejected' && initial==true){
    this.bulkReject=true;
    this.rejectionReasonDialog=true;
    return;
  }

  this.rejectionReasonDialog=false;

  const processPosts = (selectedPosts: any, index: any) => {
    this.authService.hasPageLoaded.next(false);
    if (index < selectedPosts.length) {
      this.postService.updatePostStatus(selectedPosts[index].postId, query)
        .pipe(takeUntil(this.unsubscribe$),
          finalize(() => {
            processPosts(selectedPosts, index + 1);
          })
        )
        .subscribe({
          next: res => {
            console.log(res)
            this.authService.hasPageLoaded.next(true);
          },
          error: err => {
            this.notificationService.showError('Something went wrong while updating posts');
            console.log(err);
            this.authService.hasPageLoaded.next(true);
          }
        })
    } else {
      this.authService.hasPageLoaded.next(true);
      this.notificationService.showSuccess(`${index} Post(s) ${query.status} successfully`);
      setTimeout(() => {
        location.reload();
      }, 2000);
    }
  }

  processPosts(this.selectedPosts, 0);

}




ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}
}
