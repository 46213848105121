<p-card header="Youtube Videos">
    <div [formGroup]="youtubeLinkForm" class="row">
        <div class="col-6 col-sm-12 col-lg-12 p-fluid mb-2">
            <div class="flex flex-column gap-2">
                <label htmlFor="Title">Title</label>
                <input pInputText class="p-inputtext-sm" formControlName="title" id="username" aria-describedby="username-help" />
            </div>
        </div>

        <div class="col-12 col-sm-12 col-lg-12 p-fluid mb-2">
            <label htmlFor="username">Youtube Link</label>
            <textarea rows="2" cols="30" formControlName="youtubeLink" pInputTextarea></textarea>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12 d-flex justify-content-end align-items-end gap-3">
            <p-button styleClass="p-button-sm" (onClick)="save()" label="Submit"></p-button>
        </div>
    </div>


    <br><br>
    <div class="row">
        <p-table #dt [value]="youtubeVideoLinks" scrollDirection="horizontal" [style]="{ 'width': 'calc(100vw - 310px)' }" [responsive]="true" responsiveLayout="stack" [breakpoint]="'960px'" [rows]="10"
            [paginator]="true" [rowsPerPageOptions]="[10, 20, 50, 100, 200, 300, 500]"
            [globalFilterFields]="['title']" [lazy]="true"
            (onLazyLoad)="_getYoutubeLinkData($event)" [totalRecords]="totalRecords"
            styleClass=" p-datatable-gridlines p-datatable-striped p-datatable-sm" selectionMode="single"
            [rowHover]="true">
            <ng-template pTemplate="caption">
                <div class="flex p-fluid justify-content-between">
                    <div class="">
                        <span class="p-input-icon-left ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="p-inputtext-sm" #textInput
                                (input)="dt.filterGlobal(textInput.value, 'contains')" placeholder="Search keyword" />
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th></th>
                    <th [pSortableColumn]="'title'">Title
                        <p-sortIcon [field]="'title'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'youtubelink'">Youtube Video Link
                        <p-sortIcon [field]="'youtubelink'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'createdDateTime'">Date
                        <p-sortIcon [field]="'createdDateTime'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" (dblclick)="editRecord(rowData.youtubeVideoId)">
                    <td class="text-center">
                        <a (click)="deleteLink(rowData?.youtubeVideoId)" pTooltip="Delete">
                            <i class="pi pi-trash"></i>
                        </a>
                    </td>

                    <td><span class="p-column-title"></span>{{rowData?.title}}</td>
                    <td><span class="p-column-youtubelink"></span>{{rowData?.youtubeLink}}</td>
                    <td><span class="p-column-createdDateTime"></span>{{rowData?.createdDateTime | date: 'dd/MM/yyyy'}}</td>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">No Records found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>