import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/shared/models/user.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { usersList } from 'src/app/shared/users.list';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  passwordType: string = 'password';

  private unsubscribe$ = new Subject<void>();
  loginForm: FormGroup = new FormGroup({});
  users: User[] = [];
  invalidUser = false;
  api: string = environment.apiURL;

  forgotPassword: boolean = false;
  userip:any;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.createLoginForm();
    if (this.authService.getUser()) {
      this.router.navigate(['dashboard']);
      return;
    }
    this.authService.getUserIPV4()
    .pipe(takeUntil(this.unsubscribe$)).subscribe({
      next:res => {
        this.userip=res
        console.log(res)
        this.authService.hasPageLoaded.next(true);
    },
    error: err=> {
      console.log(err)
      this.authService.hasPageLoaded.next(true);
    }
  })

  }

  createLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
      ipAddress:['']
    });
  }

  login() {
    if (this.loginForm.invalid) {
      return;
    }

    this.authService.hasPageLoaded.next(false);

    this.loginForm.get('ipAddress')?.setValue(this.userip.ip)

    console.log(this.loginForm.value);

    this.authService
      .login(this.loginForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          console.log(res);
          if (res.userId) {
            this.authService.setToken(res);
            this.invalidUser = false;
            // this.authService.loggedInUser.next(res)
            this.router.navigate(['/dashboard']);
            location.reload();
            //this.notificationService.showSuccess('Logged-In Successfully!')
          } else {
            this.invalidUser = true;
            this.loginForm.reset();
            this.authService.hasPageLoaded.next(true);
          }
        },
        error: (error) => {
          this.invalidUser = true;
          this.loginForm.reset();
          this.authService.hasPageLoaded.next(true);
        },
        complete: () => {
          this.authService.hasPageLoaded.next(true);
        },
      });
  }

  sendEmailResetMail(event: any) {
    const patter = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!patter.test(event.value)) {
      this.notificationService.showError('Enter valid email address');
      return;
    }
    this.authService
      .passwordResetEmail(event.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          this.notificationService.showSuccess(res.message);
          this.forgotPassword = false;
        },
        error: (err) => {
          this.notificationService.showError(
            err?.error?.detail ?? 'Something went wrong! Please try again'
          );
          this.forgotPassword = false;
          console.log(err.error);
        },
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
