import { Location } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, of, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ArticleDetailService } from 'src/app/shared/services/article-detail.service';
import { articles } from 'src/app/shared/articles.data';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent {

  @ViewChild('commentField', {static:true}) commentField: ElementRef | undefined;
  isCommentFieldVisible:boolean=false;
  routePath:string='';
  articleList = articles;
  article:any;
  loggedInUser:any;
  postLiked=false;
  writerDetails:any;
  isFacebookUrl: boolean = false;
  isTwitterUrl: boolean = false;
  isLinkedinUrl: boolean = false;

  constructor(private activeRoute : ActivatedRoute,
    private authService: AuthService,
    private location: Location,
    private aritcleService: ArticleDetailService){
    this.commentField=undefined;
  }

  ngOnInit(){
    this.loggedInUser=this.authService.getUser()
    this._checkActiveRoute();
  }

  _checkActiveRoute(){
    this.routePath = this.activeRoute.snapshot.url[0].path
    this.activeRoute.params.subscribe(param => {
      console.log(param)
      if(param['id']){
        this.getArticle(param['id']);
      }
    })
  }

  showCommentField(){
    this.isCommentFieldVisible=true;
    if (this.commentField && this.commentField.nativeElement)
    this.commentField.nativeElement.scrollIntoView({ behavior: 'smooth' })

  }

  getWriter(id:any){
    this.aritcleService.getUserOfArticle(id).subscribe(res=>{
          this.writerDetails=res;
        })
  }

  getArticle(id:string | number){
    // let article = this.articleList.find(article => article.id == id)
    // if(!article){
    //   this.location.back()
    // }
    // this.article=article
    // this.postLiked = this.article.likes.includes(this.loggedInUser?.id)
    try {
      this.aritcleService.getArticle(id, '').pipe(
        switchMap(data => {
          const articleDataObj = this.getArticleDataObj(data);
          return forkJoin([
            of(articleDataObj),
            this.aritcleService.getUserOfArticle(articleDataObj.userId).pipe(
              catchError(userError => {
                console.error('Failed to fetch user data:', userError);
                window.location.href = "/";
                return of(null); // Return a fallback value or handle the error as needed.
              })
            )
          ]);
        }),
        switchMap(([articleDataObj, userData]) => {
          const [article, user] = [articleDataObj, userData];
          const newArticleObj ={
            ...article,
            writer: {
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              roleName: user.roleName,
              country: user.country,
              img: user.profileImageLink,
              facebookURL: user.facebookURL,
              twitterURL: user.twitterURL,
              linedinURL: user.linedinURL
            }
          }
          return of(newArticleObj);
        }),
        catchError(error => {
          console.error('Error processing article:', error);
          window.location.href = "/";
          return throwError(error);
        })
      ).subscribe(res => {
        //console.log(res);
        this.article = res;
        if (res.writer.facebookURL) {
          this.isFacebookUrl = true;
        }
        if (res.writer.twitterURL) {
          this.isTwitterUrl = true;
        }
        if (res.writer.linedinURL) {
          this.isLinkedinUrl = true;
        }
      });
    } catch (error) {
      console.error('error', error);
    }
  }

  likePost(id: number) {
    if(!this.loggedInUser || this.routePath=='preview')
      return;
    const article = this.articleList.find(article => article.id == id)
    if (this.postLiked && article) {
      const index = article.likes.indexOf(id)
      if (index !== -1)
        article.likes.splice(index, 1)
      this.postLiked = false

    } else {
      article?.likes.push(id)
      this.postLiked = true;
      //console.log(article)
    }
  }

  comment(id: number,event:any){
    if(!this.loggedInUser)
      return;
    const article = this.articleList.find(article => article.id == id)
    let cmnt = {name: this.loggedInUser.username, date: new Date(),img:'https://cryptobusinessworld-dev.s3.us-east-2.amazonaws.com/Profile/77/abcd.jpg',
                comment:event.value
  }
    article?.comments.push(cmnt)
  }

  getArticleDataObj(data :any){
    return {
      id:data.postId,
      title:data.postTitle,
      date: data.createdDateTime,
      userId:data.ownerId,
      thumbnail:data.postImageURL,
      content: data.postText,
      tags: data.postTags?.length > 0 ? '#' + data.postTags?.replaceAll(';', '#')?.replaceAll(',', '#') : '',
      likes:data.likeBy??[],
      comments:data.commentDetails??[],
      postHeading:data.postHeading? data.postHeading : '',
      imageAlt:data.imageAlt? data.imageAlt : ''
  }

  }

  openFacebookLink() {
    const currentUrl = window.location.href;
    window.open(
      `https://www.facebook.com/sharer/sharer.php?href=${currentUrl},'facebook-share-dialog','width=626,height=436'`,
      '_blank'
    );
  }

  openTwitterLink() {
    const currentUrl = window.location.href;
    window.open(
      `http://twitter.com/share?url=${currentUrl}&text=${this.article.title}`,
      '_blank'
    );
  }

  openLinkedinLink() {
    const currentUrl = window.location.href;
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`,
      '_blank'
    );
  }
}
