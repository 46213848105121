<div class="press-releases">
  <span class="press-releases--head">Press Releases</span>
  <div class="orange-line"></div>
  <div class="press-releases--body">
    <div *ngFor="let data of pressReleasesList; let index = index">
      <div *ngIf="index < 1" class="press-releases--body--boxes">
        <span>
          <img class="press-releases--body--boxes--img" src="{{ data.postImageURL }}" />
        </span>
        <span class="press-releases--body--boxes--content">
          <a routerLink="/articles-preview/{{
            data.postTitle  | urlPrettier
          }}" class="press-releases--body--boxes--content--title">{{
            data.postTitle
            }}</a>
          <span class="press-releases--body--boxes--content--description">{{
            data.postText
            }}</span>
          <span class="press-releases--body--boxes--content--date">{{
            data.createdDateTime | date : "dd-MM-yyyy"
            }}</span>
        </span>
      </div>
    </div>
  </div>
  <div class="press-releases--bottom">
    <span routerLink="/crypto-business-world-press-release" class="press-releases--bottom--btn">MORE FROM PRESS
      RELEASES</span>
  </div>
</div>
