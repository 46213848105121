import { Component } from '@angular/core';

@Component({
  selector: 'app-homepage-twitter-feeds',
  templateUrl: './homepage-twitter-feeds.component.html',
  styleUrls: ['./homepage-twitter-feeds.component.scss']
})
export class HomepageTwitterFeedsComponent {

}
